// Customizable Area Start
import React from "react";

import { Portal, Tab } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CustomTabs from "../../../components/src/CustomTabs";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { SaveAlt } from "@material-ui/icons";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomMenuItemWeb from "../../../components/src/CustomMenuItem.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import AnalyticsAdminController, {
  Props,
} from "./AnalyticsAdminController.web";

import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  TooltipProps,
} from "recharts";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const value = payload[0].value ?? 0;
    return (
      <div className="custom-tooltip">
        <CustomTypography>
          <strong>{`SAR ${value.toLocaleString()}`}</strong>
        </CustomTypography>
      </div>
    );
  }

  return null;
};

const formatter = Intl.NumberFormat("en", { notation: "compact" });

export default class AnalyticsAdmin extends AnalyticsAdminController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { saleChartData, snackbar, loading, currentHoldValue } = this.state;

    return (
      <Container>
        <CustomLoadingBackdrop open={loading} />

        <CustomTabs
          variant="scrollable"
          scrollButtons="auto"
          value={this.state.tabActive}
        >
          {this.state.tabs.map((tab, index) => {
            return (
              <Tab
                key={index}
                data-testid={"tab-" + index}
                label={tab.name}
                value={tab.value}
                onClick={() => this.changeActiveTab(tab.value)}
              />
            );
          })}
        </CustomTabs>
        <div className="reports__content">
          <div className="reports__header">
            <div>
              <CustomTypography className="reports__title">
                {this.getActiveTabName()}
              </CustomTypography>
              <CustomTypography className="reports__description">
                Breakdown of total sales and order volume per day or per month.
                Use this to see whether your business is trending upwards or
                downwards over time.
              </CustomTypography>
            </div>

            <CustomButton
              variant="contained"
              color="primary"
              className="reports__download"
              startIcon={<SaveAlt />}
            >
              Download Report
            </CustomButton>
          </div>

          <div className="reports__main">
            <div className="reports__chart">
              <div className="reports__chart__header">
                <div>
                  <h2 className="reports__chart__title">Orders</h2>
                  <div className="reports__chart__figures">{`SAR ${currentHoldValue.toLocaleString()}`}</div>
                </div>

                <CustomSelect
                  id="select-time"
                  data-test-id="select-time"
                  className="reports__chart__select"
                  variant="outlined"
                  value={this.state.filterTime}
                >
                  {this.state.filterTimeList.map((option) => (
                    <CustomMenuItemWeb
                      value={option.value}
                      key={option.id}
                      onClick={() => {
                        this.changeFilter(option.value);
                      }}
                    >
                      {option.name}
                    </CustomMenuItemWeb>
                  ))}
                </CustomSelect>
              </div>
              <div className="reports__chart__main">
                <ResponsiveContainer height={400}>
                  <AreaChart
                    data={saleChartData.data}
                    margin={{
                      top: 40,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                    onMouseMove={(e: any) => {
                      if (!e.isTooltipActive || !e.activePayload[0]) {
                        this.setCurrentHoldValue(0);

                        return;
                      }

                      const pointData = e.activePayload[0].payload;

                      this.setCurrentHoldValue(pointData.YValue);
                    }}
                    onMouseLeave={() => this.setCurrentHoldValue(0)}
                  >
                    <CartesianGrid vertical={false} strokeDasharray="4" />

                    <XAxis
                      dataKey={"XValue"}
                      fontFamily="Raleway"
                      fontSize={16.61}
                      fontWeight={400}
                      tick={{ fill: "#64748B", textAnchor: "left" }}
                      tickLine={false}
                      stroke={"#CBD5E1"}
                    />

                    <YAxis
                      dataKey="YValue"
                      type="number"
                      fontFamily="Inter"
                      fontSize={16.61}
                      fontWeight={400}
                      tick={{ fill: "#64748B" }}
                      domain={[
                        (dataMin: number) => dataMin * -0.1 + dataMin,
                        "auto",
                      ]}
                      axisLine={false}
                      tickLine={false}
                      tickFormatter={(v) => formatter.format(v)}
                    />

                    <Tooltip
                      data-testid="tooltip"
                      content={<CustomTooltip />}
                      cursor={{ strokeDasharray: 4, stroke: "#A2A2A2" }}
                    />

                    <Area
                      type="monotone"
                      dataKey="YValue"
                      stroke="#FF6666"
                      strokeWidth={3}
                      fill={`url(#grad)`}
                      activeDot={{
                        r: 8,
                        stroke: "#FFF",
                        strokeWidth: 5,
                        fill: "#F66",
                      }}
                    />
                    <defs>
                      <linearGradient
                        id={`grad`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                        gradientTransform="rotate(181.17)"
                      >
                        <stop
                          offset="14.93%"
                          stopColor="#F87171"
                          stopOpacity="10%"
                        />
                        <stop
                          offset="99%"
                          stopColor="#FFC8C8"
                          stopOpacity="10%"
                        />
                      </linearGradient>
                    </defs>
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <div className="reports__chart__footer">
                <div className="reports__chart__action">
                  <div className="reports__chart__legend">
                    <svg
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="8.84096"
                        cy="8.50002"
                        r="8.30458"
                        fill="#FF6666"
                      />
                    </svg>
                    <CustomTypography>Orders</CustomTypography>
                  </div>
                </div>
                <div className="reports__chart__comparison">
                  <CustomTypography>{`SAR ${saleChartData.totalRevenueByOnline}`}</CustomTypography>
                  <CustomTypography>Online</CustomTypography>

                  <CustomTypography>vs</CustomTypography>
                  <CustomTypography>{`SAR ${saleChartData.totalRevenueByOnline}`}</CustomTypography>
                  <CustomTypography>Cash</CustomTypography>

                  <div className="reports__chart__bars">
                    <svg
                      width="100%"
                      height="11"
                      viewBox="0 0 100% 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        y="5"
                        width="80%"
                        height="6"
                        rx="3"
                        fill="#FF6666"
                      />
                      <rect
                        x="calc(80% + 10px)"
                        y="5"
                        width="calc(100% - 80% - 10px)"
                        height="6"
                        rx="3"
                        fill="#FFCC00"
                      />
                    </svg>
                  </div>
                </div>

                <div className="reports__chart__growth">
                  <CustomTypography>
                    {`SAR ${saleChartData.totalRevenue}`}
                  </CustomTypography>
                  <CustomTypography>Order</CustomTypography>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
          />
        </Portal>
      </Container>
    );
  }
}

const Container = styled("div")(({ theme }) => {
  return {
    "& .reports__content": {
      padding: "24px 24px 48px",
      borderRadius: 8,
      border: "1px solid #EDEDED",
      borderTopLeftRadius: 0,
      minHeight: 705,
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    "& .reports__header": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    "& .reports__title": {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(22),
      lineHeight: 26 / 22,
    },
    "& .reports__description": {
      marginBlockStart: 8,
    },
    "& .reports__download": {
      flexShrink: 0,
    },
    "& .reports__main": {
      marginBlockStart: 42,
    },
    "& .reports__chart": {
      padding: "34px 48px 0 38px",
      "& .custom-tooltip strong": {
        color: "#000000",
        fontFamily: "Inter",
      },
      "& .recharts-active-dot circle": {
        boxShadow: "0px 0px 16.31px 0px #0000001F",
      },
    },
    "& .reports__chart__header": {
      display: "flex",
      alignItems: "flex-start",
      fontFamily: "Inter",
      "& > :last-child": {
        marginInlineStart: "auto",
      },
      "& .reports__chart__arrow_highlight": {
        backgroundColor: "#D1FAE5",
        padding: 3,
        borderRadius: "50%",
        verticalAlign: "bottom",
        marginBlockStart: 4,
      },
      "& .reports__chart__trend": {
        lineHeight: 1.625,
      },
    },
    "& .reports__chart__main": {
      "& svg.recharts-surface": {
        overflow: "visible",
      },
      "& .recharts-layer.recharts-cartesian-axis-tick:last-child": {
        transform: "translateX(-1.5ch)",
      },
    },
    "& .reports__chart__footer": {
      display: "flex",
      paddingInlineStart: "0.5ch",
      marginBlockStart: 46,
      "& .reports__chart__legend": {
        display: "flex",
        alignItems: "center",
        gap: 10,

        "& p": {
          fontSize: theme.typography.pxToRem(16),
          lineHeight: 23 / 16,
          fontWeight: 600,
        },
      },
      "& .reports__chart__action > * + *": {
        marginBlockStart: 13,
      },
      "& .reports__chart__comparison": {
        marginInlineStart: "auto",
        width: "100%",
        maxWidth: 276,
        display: "grid",
        gridTemplateColumns: "1fr 20px 1fr",
        gridTemplateRows: "1fr 1fr 1fr",
        gridTemplateAreas: `"online . cash"
                          "online-name vs cash-name"
                          "bar bar bar"`,
        "& :nth-child(1), & :nth-child(4)": {
          fontSize: theme.typography.pxToRem(18),
          lineHeight: 22 / 18,
          fontWeight: 700,
          fontFamily: "Inter",
        },
        "& :nth-child(2), & :nth-child(3), & :nth-child(5)": {
          fontSize: theme.typography.pxToRem(16),
          lineHeight: 22 / 16,
          fontWeight: 600,
        },
        "& :nth-child(4), & :nth-child(5)": {
          textAlign: "right",
        },
        "& :nth-child(1)": {
          gridArea: "online",
        },
        "& :nth-child(2)": {
          gridArea: "online-name",
        },
        "& :nth-child(3)": {
          gridArea: "vs",
        },
        "& :nth-child(4)": {
          gridArea: "cash",
        },
        "& :nth-child(5)": {
          gridArea: "cash-name",
        },
        "& .reports__chart__bars": {
          gridArea: "bar",
        },
      },
      "& .reports__chart__growth": {
        marginInlineStart: 47,
        "& > :first-child": {
          fontFamily: "Inter",
          fontSize: theme.typography.pxToRem(24),
          fontWeight: 700,
          lineHeight: 24 / 22,
        },
        "& > :last-child": {
          fontSize: theme.typography.pxToRem(16),
          lineHeight: 22 / 16,
          fontWeight: 600,
        },
      },
    },
    "& .reports__chart__title": {
      fontFamily: "Roboto",
      fontSize: theme.typography.pxToRem(19.66),
      fontWeight: 600,
      lineHeight: 30.9 / 19.66,
      marginBlockStart: 0,
      marginBlockEnd: 8,
    },
    "& .reports__chart__figures": {
      fontWeight: 700,
      fonFamily: "Roboto",
      fontSize: theme.typography.pxToRem(42.13),
      lineHeight: 56.17 / 42.13,
      letterSpacing: `${-0.21065576374530792 / 42.13}em`,
    },
    "& .reports__chart__select": {
      width: 110,
      backgroundColor: "#FFF",
      "& > #select-time": {
        fontWeight: 500,
        color: "#0F172A",
      },
      "& > svg": {
        color: "#0F172A",
      },
    },
    "& .reports__chart__trend": {
      fontFamily: "Inter",
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 19.36 / 16,
      letterSpacing: "0.04em",
      display: "inline-flex",
      alignItems: "center",
      paddingInlineStart: 10,
      "&.reports__chart__trend_down": {
        color: "#DC2626",
      },
      "&.reports__chart__trend_up": {
        color: "#059669",
      },
    },
  };
});

// Customizable Area End
