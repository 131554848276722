import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { KitchenAccountManagementDetailFormMode } from "../../../components/src/KitchenAccountManagementDetailForm.web";
import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  form: {
    loading: boolean;
    mode: KitchenAccountManagementDetailFormMode;
    values: {
      email: string;
      name: string;
      role: string;
      accessibleBranches: (string | number)[];
    };
    options: {
      id: string | number;
      name: string;
      value: number | string;
    }[];
    accordionName: string;
    accordionData: {
      id: string | number;
      name: string;
      branchName: string;
    }[];
  };
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class KitchenAccountManagementDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDetailId: string = "";
  getUserDetailApiId: string = "";
  fetchUserRoleOptionsApiId: string = "";
  updateUserDetailApiId: string = "";
  fetchAccordionApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.state = {
      loading: false,
      form: {
        loading: false,
        mode: KitchenAccountManagementDetailFormMode.View,
        values: {
          email: "",
          name: "",
          role: "",
          accessibleBranches: [],
        },
        options: [],
        accordionName: "",
        accordionData: [],
      },
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    };

    this.subScribedMessages = [
      getName(MessageEnum.NavigationEmailLogInMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const searchParams = new URLSearchParams(window.location.search);

    this.userDetailId = this.props.navigation.match.params.id;

    const mode = searchParams.get("mode");

    if (!mode || !this.userDetailId) {
      this.onCancel();

      return;
    }

    this.getUserDetail();
    this.fetchUserRoleOptions();
    this.fetchAccordion();
    this.setPageMode(mode as KitchenAccountManagementDetailFormMode);
    // Customizable Area End
  }

  // Customizable Area Start
  setPageMode = (mode: KitchenAccountManagementDetailFormMode) => {
    switch (mode) {
      case KitchenAccountManagementDetailFormMode.View:
        this.props.navigation.setCurrentPage({
          to: "/kitchen/owner/user-management/:id",
          name: "User’s details",
        });

        break;

      case KitchenAccountManagementDetailFormMode.Edit:
        this.props.navigation.setCurrentPage({
          to: "/kitchen/owner/user-management/:id",
          name: "Edit details",
        });

        break;

      default:
        break;
    }

    this.setState({
      form: {
        ...this.state.form,
        mode,
      },
    });
  };

  onCancel = () => {
    this.props.navigation.kitchenNavigate("/kitchen/owner/user-management");
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  getUserDetail = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDetailApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/${this.userDetailId}/staff_information`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  fetchUserRoleOptions = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchUserRoleOptionsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_roles_permissions/account_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFetchUserRoleOptions = (responseJson: any) => {
    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const options = responseJson.data.map((role: any) => ({
      id: role.id,
      name: role.attributes.name,
      value: role.id,
    }));

    this.setState({
      form: {
        ...this.state.form,
        options,
      },
    });
  };

  fetchAccordion = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchAccordionApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_location/stores`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFetchAccordion = (responseJson: any) => {
    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const accordionData = responseJson.stores.data.map((store: any) => {
      const { name, branch_name } = store.attributes;

      return {
        id: store.id,
        name,
        branchName: branch_name,
      };
    });

    this.setState({
      form: {
        ...this.state.form,
        accordionData,
        accordionName: responseJson.kitchen,
      },
    });
  };

  handleGetUserDetail = async (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const { attributes } = responseJson.data;

    this.setState({
      form: {
        ...this.state.form,
        values: {
          email: attributes.email,
          name: attributes.user_name,
          role: attributes.role_id.toString(),
          accessibleBranches: attributes.accessible_branches.map(
            (branch: any) => branch.id.toString()
          ),
        },
      },
    });
  };

  updateUserDetail = async (values: {
    email: string;
    role: string;
    name: string;
    accessibleBranches: (string | number)[];
  }) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
      "Content-Type": "application/json",
    };

    const formData = {
      user_name: values.name,
      email: values.email,
      role_id: values.role.toString(),
      branch_ids: values.accessibleBranches,
    };

    const httpBody = {
      data: formData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateUserDetailApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.userDetailId}/updating_staff_information`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleUpdateUserDetail = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors) {
      this.openSnackbar(CustomSnackbarType.Error, responseJson.errors[0]);

      return;
    }

    if (responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.openSnackbar(CustomSnackbarType.Success, "Updated successfully");

    this.setPageMode(KitchenAccountManagementDetailFormMode.View);
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchUserRoleOptionsApiId:
        this.handleFetchUserRoleOptions(responseJson);

        break;

      case this.getUserDetailApiId:
        this.handleGetUserDetail(responseJson);

        break;

      case this.updateUserDetailApiId:
        this.handleUpdateUserDetail(responseJson);

        break;

      case this.fetchAccordionApiId:
        this.handleFetchAccordion(responseJson);

        break;

      default:
        break;
    }
    // Customizable Area End
  }
}
