export const imgBanner = require("../assets/img_banner.png");
export const icFacebook = require("../assets/ic_facebook.svg");
export const icLinkedin = require("../assets/ic_linkedin.svg");
export const icTwitter = require("../assets/ic_twitter.svg");
export const icEmail = require("../assets/ic_email.svg");
export const icLocation = require("../assets/ic_location.svg");
export const icCopy = require("../assets/ic_copy.svg");
export const icArrowRight = require("../assets/ic_arrow_right.svg");
export const icCamera = require("../assets/ic_camera.svg");
export const icUpload = require("../assets/ic_upload.svg");
export const icTrash = require("../assets/ic_trash.svg");
