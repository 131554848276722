import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomButton from "./CustomButton.web";
import CustomDialog from "./CustomDialog.web";
import CustomTypography from "./CustomTypography.web";

export interface Props extends WithStyles {
  dialog: KitchenAccountDeleteDialogProps;

  onClose(): void;
  onDelete(): void;
}

export interface KitchenAccountDeleteDialogProps {
  open: boolean;
  user: {
    id: string | number;
    fullName: string;
  };
}

const styles = (theme: Theme) =>
  createStyles({
    message: {
      fontSize: "16px !important",
      lineHeight: "26px !important",
    },

    actions: {
      display: "flex",
      alignItems: "center",
      gap: 16,
    },
  });

export class KitchenAccountDeleteDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, dialog, onClose, onDelete } = this.props;
    const { open, user } = dialog;

    return (
      <CustomDialog
        width={600}
        open={open}
        title={`Are you sure you want to delete ${user.fullName}’s account?`}
        onClose={onClose}
        content={
          <CustomTypography className={classes.message}>
            This action can’t be undone and all the data stored in this account
            won’t be accessible anymore.
          </CustomTypography>
        }
        actions={
          <Box className={classes.actions}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              Cancel
            </CustomButton>

            <CustomButton
              data-test-id="delete-btn"
              color="primary"
              variant="contained"
              onClick={onDelete}
            >
              Done
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(KitchenAccountDeleteDialog);
