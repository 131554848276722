import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { DashboardAdminStatistics } from "../../../components/src/KitchenDashboardStatisticsCard.web";
import { order, restaurant, user, revenue, cancelation } from "./assets";
import { v4 as uuidv4 } from "uuid";
import { AdminRestaurantStatus } from "../../../components/src/AdminRestaurantStatusTag.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { WithTranslation } from "react-i18next";

export interface StatisticsDataProps {
  totalRestaurants: DashboardAdminStatistics;
  activeUsers: DashboardAdminStatistics;
  totalOrders: DashboardAdminStatistics;
  totalCancellation: DashboardAdminStatistics;
  totalRevenue: DashboardAdminStatistics;
}

export interface SalesDetailProps {
  name: string;
  value: number;
  color: string;
}

export interface SalesDetailDataProps {
  totalOrder: SalesDetailProps;
  runningOrders: SalesDetailProps;
  customerGrowth: SalesDetailProps;
  totalRevenue: SalesDetailProps;
}

export enum FetchStatisticsDataTask {
  TotalRestaurants = "TotalRestaurants",
  ActiveUsers = "ActiveUsers",
  TotalOrders = "TotalOrders",
  TotalRevenue = "TotalRevenue",
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  revenueChartData: { date: string; value: number }[];
  salesDetailData: SalesDetailDataProps;
  statisticsData: StatisticsDataProps;
  revenueFilter: number;
  salesFilter: number;
  restaurantData: {
    id: string | number;
    name: string;
    location: string;
    contactInfo: string;
    registrationDate: string | number;
    status: AdminRestaurantStatus;
  }[];
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchRestaurantDataApiId: string = "";
  fetchRevenueChartDataApiId: string = "";
  fetchSalesDetailChartDataApiId: string = "";

  fetchStatisticsDataApi: {
    [id: string]: {
      task: FetchStatisticsDataTask;
      message: Message;
    };
  } = {};

  timeOptions = [
    {
      id: uuidv4(),
      name: "Weekly",
      value: 7,
    },
    {
      id: uuidv4(),
      name: "Monthly",
      value: 30,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      loading: false,
      revenueChartData: [],
      salesDetailData: {
        totalOrder: {
          name: "Total Order",
          color: "#FC9B43",
          value: 0,
        },
        runningOrders: {
          name: "Running Orders",
          color: "#27A6EC",
          value: 0,
        },
        customerGrowth: {
          name: "Customer Growth",
          color: "#FFC11A",
          value: 0,
        },
        totalRevenue: {
          name: "Total Revenue",
          color: "#48CD49",
          value: 0,
        },
      },
      revenueFilter: 30,
      salesFilter: 7,
      statisticsData: {
        totalRestaurants: {
          name: "Total Restaurants",
          icon: restaurant,
          theme: "orange",
          number: 0,
        },

        activeUsers: {
          name: "Active Users",
          icon: user,
          theme: "blue",
          number: 0,
        },

        totalOrders: {
          name: "Total Orders",
          icon: order,
          theme: "yellow",
          number: 0,
        },

        totalCancellation: {
          name: "Total Cancellation",
          icon: cancelation,
          theme: "red",
          number: 0,
        },

        totalRevenue: {
          name: "Total Revenue",
          icon: revenue,
          theme: "green",
          number: 0,
        },
      },
      restaurantData: [],
      pagination: {
        page: 1,
        limit: 8,
        totalPages: 0,
        totalCount: 0,
      },
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchStatisticsData();
    this.fetchRestaurantData();
    this.fetchRevenueChartData();
    this.fetchSalesDetailChartData();
    // Customizable Area End
  }

  // Customizable Area Start
  createAdminDashboardMessage = async (data: {
    url: string;
    method: string;
  }): Promise<Message> => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const adminMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    adminMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data.url
    );

    adminMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    adminMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      data.method
    );

    return adminMessage;
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchRestaurantData();
      }
    );
  };

  fetchStatisticsData = async () => {
    const totalRestaurantsMessage = await this.createAdminDashboardMessage({
      method: "GET",
      url: "/account_block/accounts/kitchen_account_count",
    });

    const activeUsersMessage = await this.createAdminDashboardMessage({
      method: "GET",
      url: "/account_block/accounts/sms_account_count",
    });

    const totalOrdersMessage = await this.createAdminDashboardMessage({
      method: "GET",
      url: "/bx_block_order_management/orders/total_order_count",
    });

    const totalRevenueMessage = await this.createAdminDashboardMessage({
      method: "GET",
      url: "/bx_block_order_management/orders/total_revenue_count",
    });

    this.fetchStatisticsDataApi = {
      [totalRestaurantsMessage.messageId]: {
        task: FetchStatisticsDataTask.TotalRestaurants,
        message: totalRestaurantsMessage,
      },

      [activeUsersMessage.messageId]: {
        task: FetchStatisticsDataTask.ActiveUsers,
        message: activeUsersMessage,
      },

      [totalOrdersMessage.messageId]: {
        task: FetchStatisticsDataTask.TotalOrders,
        message: totalOrdersMessage,
      },

      [totalRevenueMessage.messageId]: {
        task: FetchStatisticsDataTask.TotalRevenue,
        message: totalRevenueMessage,
      },
    };

    Object.keys(this.fetchStatisticsDataApi).forEach((key) => {
      runEngine.sendMessage(key, this.fetchStatisticsDataApi[key].message);
    });

    this.setState({
      loading: true,
    });
  };

  handleFetchStatisticsData = (
    task: FetchStatisticsDataTask,
    responseJson: any
  ) => {
    if (responseJson.errors || responseJson.error) {
      this.fetchStatisticsDataApi = {};
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    switch (task) {
      case FetchStatisticsDataTask.TotalRestaurants:
        this.setState({
          statisticsData: {
            ...this.state.statisticsData,
            totalRestaurants: {
              ...this.state.statisticsData.totalRestaurants,
              number: responseJson.kitchen_account_count,
            },
          },
        });

        break;

      case FetchStatisticsDataTask.ActiveUsers:
        this.setState({
          statisticsData: {
            ...this.state.statisticsData,
            activeUsers: {
              ...this.state.statisticsData.activeUsers,
              number: responseJson.sms_account_count,
            },
          },
        });

        break;

      case FetchStatisticsDataTask.TotalOrders:
        this.setState({
          statisticsData: {
            ...this.state.statisticsData,
            totalOrders: {
              ...this.state.statisticsData.totalOrders,
              number: responseJson.orders_count,
            },
          },
        });

        break;

      case FetchStatisticsDataTask.TotalRevenue:
        this.setState({
          statisticsData: {
            ...this.state.statisticsData,
            totalRevenue: {
              ...this.state.statisticsData.totalRevenue,
              number: responseJson.revenue_count,
            },
          },
        });

        break;

      default:
        break;
    }

    if (Object.keys(this.fetchStatisticsDataApi).length) {
      return;
    }

    this.setState({
      loading: false,
    });
  };

  fetchRestaurantData = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchRestaurantDataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_location/stores/listing_of_branches?page=${this.state.pagination.page}&per_page=${this.state.pagination.limit}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleFetchRestaurantData = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const restaurantData = responseJson.branches.data.map((store: any) => ({
      id: store.id,
      name: store.attributes.name,
      location: store.attributes.location,
      contactInfo: store.attributes.contact_info,
      registrationDate: store.attributes.created_at,
      status: store.attributes.status,
    }));

    const pagination = {
      limit: 8,
      page: responseJson.pagination.current_page,
      totalCount: responseJson.pagination.total_count,
      totalPages: responseJson.pagination.total_pages,
    };

    this.setState({ restaurantData, pagination });
  };

  fetchRevenueChartData = () => {
    const revenueChartData = [
      {
        value: 1500,
        date: "Mon",
      },
      {
        value: 2450,
        date: "Tue",
      },
      {
        value: 1700,
        date: "Wed",
      },
      {
        value: 2000,
        date: "Thu",
      },
      {
        value: 1800,
        date: "Fri",
      },
      {
        value: 1300,
        date: "Sat",
      },
      {
        date: "Sun",
        value: 2500,
      },
    ];

    this.setState({
      revenueChartData,
    });
  };

  fetchSalesDetailChartData = () => {
    const salesDetailData = {
      totalOrder: {
        name: "Total Order",
        color: "#FC9B43",
        value: 35,
      },
      runningOrders: {
        name: "Running Orders",
        color: "#27A6EC",
        value: 22,
      },
      customerGrowth: {
        name: "Customer Growth",

        color: "#FFC11A",
        value: 22,
      },
      totalRevenue: {
        name: "Total Revenue",
        color: "#48CD49",
        value: 17,
      },
    };

    this.setState({
      salesDetailData,
    });
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      Object.keys(this.fetchStatisticsDataApi).length &&
      Object.keys(this.fetchStatisticsDataApi).includes(apiRequestCallId)
    ) {
      const task = this.fetchStatisticsDataApi[apiRequestCallId].task;

      delete this.fetchStatisticsDataApi[apiRequestCallId];

      this.handleFetchStatisticsData(task, responseJson);
    }

    switch (apiRequestCallId) {
      case this.fetchRestaurantDataApiId:
        this.handleFetchRestaurantData(responseJson);

        break;

      case this.fetchRevenueChartDataApiId:
        this.changePage(1);

        break;

      case this.fetchSalesDetailChartDataApiId:
        this.changePage(2);
        break;

      default:
        break;
    }
    // Customizable Area End
  }
}
