import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import {
  AccessTime,
  ExpandLess,
  ExpandMore,
  SpeakerNotes,
} from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import CustomButton from "./CustomButton.web";
import CustomTable from "./CustomTable";
import CustomTypography from "./CustomTypography.web";
import KitchenOrderAcceptStatusMenu, {
  KitchenOrderAcceptStatus,
  KitchenOrderAcceptStatusMenuOption,
} from "./KitchenOrderAcceptStatusMenu.web";
import KitchenOrderPaymentStatusTag, {
  KitchenOrderPaymentStatus,
} from "./KitchenOrderPaymentStatusTag.web";
import KitchenOrderStatusTag, {
  KitchenOrderStatus,
} from "./KitchenOrderStatusTag.web";
import { KitchenOrderSummaryStatus } from "./KitchenOrderSummaryDrawer.web";

interface S {
  expanded: boolean;
}

export enum KitchenOrderDishStatus {
  Accept = "accepted",
  Decline = "declined",
  ReadyForPickup = "ready_for_pickup",
}

export interface KitchenOrderDish {
  id: string;
  name: string;
  available: string;
  image: string;
  price: number;
  status: string;
  orderSummaryStatus: KitchenOrderSummaryStatus;
  quantity: number;
  subtotal: number;
  message: string;
}

export interface KitchenOrder {
  id: string;
  status: KitchenOrderStatus;
  orderId: string;
  time: Date;
  paymentStatus: KitchenOrderPaymentStatus;
  restaurantName: string;
  orderAcceptStatus: KitchenOrderAcceptStatus;
  orderDetailData: KitchenOrderDish[];
  orderType: string;
  scheduledTime: string;
  subtotal: number;
}

export interface Props extends WithStyles<any> {
  order: KitchenOrder;
  orderStatusOptions: KitchenOrderAcceptStatusMenuOption[];

  onChangeAcceptStatus(
    orderId: string | number,
    statusId: string | number
  ): void;
  openOrderSummaryDrawer(data: KitchenOrder): void;
  onAcceptOrder(orderId: string): void;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "&.is-expanded": {
        "& td": {
          borderBottom: "0 !important",
        },
      },
    },

    orderId: {
      fontFamily: "Inter !important",
      fontSize: "14px !important",
      lineHeight: "22px !important",
    },

    subTableCell: {
      border: 0,
      padding: "0 !important",
      borderRadius: 0,
    },

    accordion: {
      boxShadow: "initial !important",
    },

    summary: {
      height: 0,
      minHeight: "initial !important",
    },

    accordionDetail: {
      "&.is-expanded": {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
      },
    },

    accordionDetailInner: {
      backgroundColor: "#FFFBF4",
      width: "100%",
      padding: "19px 12px",
    },

    accordionDetailHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    orderType: {
      display: "flex",
      alignItems: "center",
      fontFamily: "Inter !important",
      gap: 5,
    },

    orderTypeIcon: {
      fontSize: 15,
      color: "#FF6666",
    },

    orderTypeTooltip: {
      "& .MuiTooltip-tooltipPlacementBottom": {
        maxWidth: 166,
        backgroundColor: "#333333",
        color: "#FFFFFF",
        fontFamily: "Raleway",
        fontSize: 12,
        lineHeight: "14.09px",
        borderRadius: 4,
        padding: "10px 8px",
      },
    },

    orderTable: {
      "& th": {
        borderBottom: "0 !important",
        color: "#FF6666",

        "&:not(:first-child)": {
          textAlign: "right",
        },
      },

      "& td": {
        borderBottom: "0 !important",

        "&:not(:first-child)": {
          textAlign: "right",
        },
      },
    },

    dish: {
      display: "flex",
      alignItems: "center",

      "& + & ": {
        marginTop: 15,
      },
    },

    dishHeading: {
      display: "flex",
      alignItems: "center",
    },

    dishStatus: {
      marginLeft: 5,
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
      minWidth: 58,
    },

    dishImage: {
      width: 50,
      height: 50,
      border: "1px solid #EDEDED",
      borderRadius: 8,
      objectFit: "cover",
    },

    dishAvailable: {
      fontSize: "12px !important",
      lineHeight: "24px !important",
      color: "#059669",
    },

    dishUnavailable: {
      fontSize: "12px !important",
      lineHeight: "24px !important",
      color: "#DC2626",
    },

    dishContent: {
      marginLeft: 10,
      width: "100%",
      flex: 1,
    },

    dishPrice: {
      lineHeight: "24px !important",
      color: "#333333 !important",
      fontWeight: "400 !important" as any,
      fontFamily: "Inter !important",
    },

    dishAcceptStatus: {
      display: "flex",
      alignItems: "center",
      fontFamily: "Raleway",
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "24px",
      justifyContent: "end",

      "&.accept": {
        color: "#059669",
      },

      "&.ready-for-pickup": {
        color: "#059669",
      },

      "&.decline": {
        color: "#DC2626",
        textDecoration: "underline",

        "& > span": {
          cursor: "pointer",
        },
      },
    },

    dishAcceptStatusIcon: {
      fontSize: 24,
      marginRight: 34,
      cursor: "pointer",
    },

    orderTableBottom: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 10,
    },

    orderTableButtonGroup: {
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        minWidth: 157,
      },
    },
    orderTableAmount: {
      display: "flex",
      alignItems: "center",
      gap: 16,
    },

    orderTableAmountTitle: {
      color: "#FF6666",
    },

    orderTableAmountNumber: {
      color: "#333333",
      fontFamily: "Inter !important",
    },

    subtotal: {
      fontFamily: "Inter !important",
    },

    dishQuantity: {
      fontFamily: "Inter !important",
      fontWeight: "600 !important" as any,
    },

    dishSubtotal: {
      lineHeight: "24px !important",
      color: "#333333 !important",
      fontWeight: "600 !important" as any,
      fontFamily: "Inter !important",
    },

    dishName: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
      maxWidth: 300,
    },
  });

export class KitchenOrderTableRow extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  closeExpanded = () => {
    this.setState({ expanded: false });
  };

  render(): React.ReactNode {
    const {
      classes,
      order,
      orderStatusOptions,

      onChangeAcceptStatus,
      openOrderSummaryDrawer,
      onAcceptOrder,
    } = this.props;

    const { expanded } = this.state;

    const {
      status,
      orderId,
      time,
      paymentStatus,
      restaurantName,
      orderAcceptStatus,
      subtotal,
      orderDetailData,
      orderType,
      scheduledTime,
    } = order;

    const panelHeaderId = uuidv4();
    const panelContentId = uuidv4();

    return (
      <>
        <TableRow
          className={clsx(classes.container, { ["is-expanded"]: expanded })}
        >
          <TableCell>
            <KitchenOrderStatusTag status={status} />
          </TableCell>
          <TableCell className={classes.orderId}>
            <Box>{orderId}</Box>
            <Box>{moment(time).format("DD MMM YYYY hh:mmA")}</Box>
          </TableCell>
          <TableCell>
            <KitchenOrderPaymentStatusTag status={paymentStatus} />
          </TableCell>
          <TableCell>{restaurantName}</TableCell>
          <TableCell>
            <KitchenOrderAcceptStatusMenu
              data-test-id="order-accept-status-menu"
              value={orderAcceptStatus}
              options={orderStatusOptions}
              onChange={(statusId: string | number) =>
                onChangeAcceptStatus(order.id, statusId)
              }
            />
          </TableCell>

          <TableCell>
            {orderType === "instant" ? (
              <span>Instant</span>
            ) : (
              <Box className={classes.orderType}>
                <Tooltip
                  title="This is the scheduled order so please keep it ready on time"
                  arrow
                  placement="bottom-start"
                >
                  <AccessTime className={classes.orderTypeIcon} />
                </Tooltip>

                <span>{scheduledTime}</span>
              </Box>
            )}
          </TableCell>

          <TableCell
            className={classes.subtotal}
          >{`SAR ${subtotal}`}</TableCell>

          <TableCell>
            <IconButton
              size="small"
              onClick={this.toggleExpanded}
              data-test-id={`expand-btn`}
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.subTableCell} colSpan={8}>
            <Accordion expanded={expanded} className={classes.accordion}>
              <AccordionSummary
                className={classes.summary}
                id={panelHeaderId}
                aria-controls={panelContentId}
              />
              <AccordionDetails
                className={clsx(classes.accordionDetail, {
                  ["is-expanded"]: expanded,
                })}
              >
                <Box className={classes.accordionDetailInner}>
                  <Box className={classes.accordionDetailHead}>
                    <CustomTypography variant="h6">
                      Order Details
                    </CustomTypography>
                  </Box>

                  <TableContainer>
                    <CustomTable className={classes.orderTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Items</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Price (SAR)</TableCell>
                          <TableCell>Accept/Decline</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {orderDetailData.map((dish) => (
                          <TableRow key={dish.id}>
                            <TableCell>
                              <Box className={classes.dish} key={dish.id}>
                                <img
                                  src={dish.image}
                                  alt={dish.name}
                                  className={classes.dishImage}
                                />

                                <Box className={classes.dishContent}>
                                  <Box className={classes.dishHeading}>
                                    <CustomTypography
                                      variant="subtitle2"
                                      className={classes.dishName}
                                    >
                                      {dish.name}
                                    </CustomTypography>

                                    <CustomTypography
                                      variant="subtitle2"
                                      className={clsx(classes.dishStatus, {
                                        [classes.dishAvailable]:
                                          dish.available === "Available",

                                        [classes.dishUnavailable]: [
                                          "Unavailable",
                                          "Unavailable_till_next_operations",
                                        ].includes(dish.available),
                                      })}
                                    >
                                      {dish.available}
                                    </CustomTypography>
                                  </Box>

                                  <CustomTypography
                                    className={classes.dishPrice}
                                    variant="caption"
                                  >
                                    {`${dish.price} SAR`}
                                  </CustomTypography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              className={classes.dishQuantity}
                            >{`X ${dish.quantity}`}</TableCell>
                            <TableCell className={classes.dishSubtotal}>
                              {dish.subtotal}
                            </TableCell>
                            <TableCell>
                              {(() => {
                                switch (dish.status) {
                                  case KitchenOrderDishStatus.Accept:
                                    return (
                                      <Box
                                        className={clsx(
                                          classes.dishAcceptStatus,
                                          "accept"
                                        )}
                                      >
                                        Accepted
                                      </Box>
                                    );

                                  case KitchenOrderDishStatus.ReadyForPickup:
                                    return (
                                      <Box
                                        className={clsx(
                                          classes.dishAcceptStatus,
                                          "ready-for-pickup"
                                        )}
                                      >
                                        Ready for pickup
                                      </Box>
                                    );

                                  case KitchenOrderDishStatus.Decline:
                                    return (
                                      <Box
                                        className={clsx(
                                          classes.dishAcceptStatus,
                                          "decline"
                                        )}
                                      >
                                        {dish.message && (
                                          <SpeakerNotes
                                            className={
                                              classes.dishAcceptStatusIcon
                                            }
                                          />
                                        )}
                                        <span
                                          data-test-id={`decline-${dish.id}`}
                                          onClick={() =>
                                            openOrderSummaryDrawer(order)
                                          }
                                        >
                                          Decline
                                        </span>
                                      </Box>
                                    );

                                  default:
                                    return <></>;
                                }
                              })()}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </CustomTable>
                  </TableContainer>

                  <Box className={classes.orderTableBottom}>
                    <Box className={classes.orderTableAmount}>
                      <CustomTypography
                        className={classes.orderTableAmountTitle}
                        variant="h4"
                      >
                        Total Ordered Amount:
                      </CustomTypography>

                      <CustomTypography
                        className={classes.orderTableAmountNumber}
                        variant="h4"
                      >
                        {`${order.subtotal} SAR`}
                      </CustomTypography>
                    </Box>

                    <Box className={classes.orderTableButtonGroup}>
                      <CustomButton
                        variant="contained"
                        color="default"
                        onClick={this.closeExpanded}
                      >
                        Cancel
                      </CustomButton>
                      <CustomButton
                        data-test-id="accept-orders-btn"
                        variant="contained"
                        color="primary"
                        onClick={() => onAcceptOrder(order.id)}
                      >
                        Accept Orders
                      </CustomButton>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow>
      </>
    );
  }
}

export default withStyles(styles)(KitchenOrderTableRow);
