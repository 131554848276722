import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography.web";
import { ExpandMore } from "@material-ui/icons";
import { toRem } from "../Utils.web";

interface Props extends WithStyles {
  title: string;
  description: string;
}

const styles = createStyles({
  row: {
    marginBlockStart: 10,
    borderRadius: 8,
    boxShadow: "initial",
    backgroundColor: "#FFFBF4",
    "&::before": {
      content: "''",
      opacity: 0,
    },
  },
  accordionSummary: {
    "& p": {
      fontSize: toRem(16),
    },
  },
  accordionDetails: {
    "& p": {
      padding: 12,
      backgroundColor: "#FFFFFF",
    },
  },
});

class CustomPromocodesAdvertisingCreateAccordion extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, title, description } = this.props;

    return (
      <Accordion className={classes.row}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMore />}
        >
          <CustomTypography>{title}</CustomTypography>
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDetails}>
          <CustomTypography>{description}</CustomTypography>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withStyles(styles)(CustomPromocodesAdvertisingCreateAccordion);
