import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { FormikProps } from "formik";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  resetPasswordSnackbar: boolean;
  resetPasswordResponse: {
    type?: CustomSnackbarType;
    message: string;
  };
  resetSuccess: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ResetPasswordKitchenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetToken: string | null = "";
  resetPasswordApiId: string = "";

  resetFormProps?: FormikProps<{
    confirmPassword: string;
    password: string;
  }> = undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      loading: false,
      resetPasswordSnackbar: false,
      resetPasswordResponse: {
        type: undefined,
        message: "",
      },
      resetSuccess: false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.NavigationEmailLogInMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const url = new URL(window.location.href);

    this.resetToken = url.searchParams.get("token");

    // Customizable Area End
  }

  // Customizable Area Start
  openResetPasswordSnackbar = () => {
    this.setState({
      resetPasswordSnackbar: true,
    });
  };

  closeResetPasswordSnackbar = () => {
    this.setState({
      resetPasswordSnackbar: false,
    });
  };

  handleResetPasswordSnackbar = () => {
    this.closeResetPasswordSnackbar();

    if (!this.state.resetSuccess) return;

    this.goBackToLogin();
  };

  goBackToLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginKitchen");
  };

  resetPassword = (values: { password: string; confirmPassword: string }) => {
    const header = {
      "Content-Type": "application/json",
    };

    const formData = {
      token: this.resetToken,
      new_password: values.confirmPassword,
    };

    const httpBody = {
      data: formData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordKitchenApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleResetPassword = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (
      responseJson.errors &&
      responseJson.errors[0]?.password &&
      this.resetFormProps
    ) {
      this.resetFormProps.setErrors({
        password: responseJson.errors[0]?.password,
      });

      return;
    }

    if (responseJson.error || responseJson.errors) {
      this.setState({
        resetPasswordResponse: {
          type: CustomSnackbarType.Error,
          message: "Error! Please try again",
        },
        resetSuccess: false,
      });

      this.openResetPasswordSnackbar();
      return;
    }

    this.setState({
      resetPasswordResponse: {
        type: CustomSnackbarType.Success,
        message: "Password Successfully Changed",
      },
      resetSuccess: true,
    });

    this.openResetPasswordSnackbar();
  };

  updateResetFormProps = (
    props: FormikProps<{
      confirmPassword: string;
      password: string;
    }>
  ) => {
    this.resetFormProps = props;
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.resetPasswordApiId) {
      this.handleResetPassword(responseJson);
    }
    // Customizable Area End
  }
}
