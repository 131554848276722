// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";

export const configJSON = require("./config");

export interface Props {}

type Schedule = {
  id: number;
  kitchen_account_id: number;
  start_time: string;
  end_time: string;
  day: string;
  schedule_type: "regular" | "special";
};

interface S {
  showDrawer: boolean;
  loading: boolean;
  schedules: Schedule[];
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
}

interface SS {
  id: any;
}

export default class Servicespecificsettingsadmin2OpeningTimesController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiFetchKitchenSchedulesCallId = "";
  apiUpdateKitchenScheduleCallId = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.receive = this.receive.bind(this);

    this.state = {
      showDrawer: false,
      loading: false,
      schedules: [],
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  fetchKitchenSchedules = () => {
    this.setState({ loading: true });
    const token = window.localStorage.getItem("token");

    const header = {
      token,
      "Content-Type": "application/json",
    };

    const fetchKitchenSchedulesMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    fetchKitchenSchedulesMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    fetchKitchenSchedulesMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    fetchKitchenSchedulesMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/schedules/fetch_kitchen_schedules"
    );

    this.apiFetchKitchenSchedulesCallId = fetchKitchenSchedulesMsg.messageId;

    runEngine.sendMessage(
      fetchKitchenSchedulesMsg.id,
      fetchKitchenSchedulesMsg
    );
  };

  updateKitchenSchedules = (values: any) => {
    this.setState({ loading: true });
    const token = window.localStorage.getItem("token");

    const header = {
      token,
      "Content-Type": "application/json",
    };

    const body = {
      schedule: {
        ...values,
        schedule_type: "special",
        start_time: values.days.length ? values.start_time : "",
        end_time: values.days.length ? values.end_time : "",
      },
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "put");

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/schedules/update_all"
    );

    this.apiUpdateKitchenScheduleCallId = message.messageId;

    runEngine.sendMessage(message.id, message);
  };

  async componentDidMount(): Promise<void> {
    this.fetchKitchenSchedules();
  }

  handleFetchKitchenSchedulesResponse = (responseJson: any) => {
    this.setState({ loading: false });
    if (responseJson && Array.isArray(responseJson)) {
      this.setState({
        schedules: responseJson,
      });
    }
  };

  handleUpdateKitchenScheduleResponse = (responseJson: any) => {
    this.setState({ loading: false });
    if (responseJson && Array.isArray(responseJson)) {
      this.hideDrawer();
      this.fetchKitchenSchedules();
      this.showSnackbar("Special schedules added", CustomSnackbarType.Success);
    }
    if (responseJson && responseJson.errors) {
      this.showSnackbar(responseJson.errors[0], CustomSnackbarType.Error);
    }
  };

  receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponseJson) {
        this.setState({ loading: false });
        this.showSnackbar("Error! Please try again", CustomSnackbarType.Error);
        return;
      }

      if (apiRequestCallId === this.apiFetchKitchenSchedulesCallId) {
        this.handleFetchKitchenSchedulesResponse(responseJson);
      }

      if (apiRequestCallId === this.apiUpdateKitchenScheduleCallId) {
        this.handleUpdateKitchenScheduleResponse(responseJson);
      }
    }
  }

  showDrawer = () => {
    this.setState({ showDrawer: true });
  };

  hideDrawer = () => {
    this.setState({ showDrawer: false });
  };

  handleFormSubmitSpecial = (values: any) => {
    this.updateKitchenSchedules(values);
  };

  showSnackbar = (message: string, type: CustomSnackbarType) => {
    this.setState({
      snackbar: {
        message,
        open: true,
        type,
      },
    });
  };

  hideSnackbar = () => {
    this.setState({
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
    });
  };
}

// Customizable Area End
