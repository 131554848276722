import {
  Box,
  Menu,
  MenuItem,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import CustomTypography from "./CustomTypography.web";

export enum SeeChangesDataStatus {
  Pending = "pending",
  Approved = "approved",
}

export interface SeeChangesDataStatusMenu {
  id: string;
  name: string;
  value: SeeChangesDataStatus;
}

export interface Props extends WithStyles<any> {
  value: SeeChangesDataStatus;

  onChange(value: SeeChangesDataStatus): void;
}

const styles = createStyles({
  seeChangesDataStatus: {
    maxWidth: 136,
  },

  status: {
    cursor: "pointer",
    fontFamily: "Raleway",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "24px",
    color: "#333333",
    padding: "3px 10px",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
  },

  statusApproved: {
    backgroundColor: "#059669",
    color: "#FFFFFF",
  },

  statusPending: {
    backgroundColor: "#F59E0B",
    color: "#FFFFFF",
  },

  statusExpand: {
    marginLeft: "auto",
  },

  seeChangesDataStatusItem: {
    display: "flex",
    alignItems: "center",
    padding: "9px 18px",

    "&.is-selected": {
      backgroundColor: "#FC9797",
      borderLeft: 3,
      borderLeftColor: "#FF6666",
    },
  },

  seeChangesDataStatusName: {
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    lineHeight: "22px !important",
    fontWeight: "700 !important" as any,
    display: "flex",
    alignItems: "center",

    "&::before": {
      content: "''",
      width: 5,
      height: 5,
      display: "block",
      borderRadius: "50%",
      marginRight: 8,
    },

    "&.approved": {
      color: "#059669",

      "&::before": {
        backgroundColor: "#059669",
      },
    },

    "&.pending": {
      color: "#F59E0B",

      "&::before": {
        backgroundColor: "#F59E0B",
      },
    },
  },
});

export class CustomSeeChangesDataStatus extends React.Component<
  Props,
  {
    value: SeeChangesDataStatus | null;
    statusMenu: HTMLElement | null;
  }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.value,
      statusMenu: null,
    };
  }

  menu = [
    {
      id: uuidv4(),
      name: "Approved",
      value: SeeChangesDataStatus.Approved,
    },
    {
      id: uuidv4(),
      name: "Pending",
      value: SeeChangesDataStatus.Pending,
    },
  ];

  openFilter = (event: any) => {
    this.setState({
      statusMenu: event.currentTarget,
    });
  };

  closeFilter = () => {
    this.setState({
      statusMenu: null,
    });
  };

  changeFilter = (value: SeeChangesDataStatus) => {
    this.setState(
      {
        value,
      },
      () => this.props.onChange(value)
    );

    this.closeFilter();
  };

  render(): React.ReactNode {
    const { classes } = this.props;
    const { statusMenu, value } = this.state;
    const id = uuidv4();

    const selected = this.menu.find((item) => item.value === value);

    return (
      <Box className={classes.seeChangesDataStatus}>
        <Box
          className={clsx(classes.status, {
            [classes.statusApproved]: value === SeeChangesDataStatus.Approved,
            [classes.statusPending]: value === SeeChangesDataStatus.Pending,
          })}
          id={`filter-button-${id}`}
          aria-controls={statusMenu ? `filter-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={statusMenu ? "true" : undefined}
          onClick={this.openFilter}
        >
          {selected?.name || "Not available"}

          <ExpandMore className={classes.statusExpand} />
        </Box>
        <Menu
          id={`filter-menu-${id}`}
          anchorEl={statusMenu}
          open={Boolean(statusMenu)}
          onClose={this.closeFilter}
          MenuListProps={{
            className: classes.seeChangesDataStatus,
            "aria-labelledby": `filter-button-${id}`,
          }}
        >
          {this.menu.map((filter) => {
            return (
              <MenuItem
                key={filter.id}
                data-test-id={filter.value}
                className={classes.seeChangesDataStatusItem}
                onClick={() => this.changeFilter(filter.value)}
              >
                <CustomTypography
                  className={clsx(classes.seeChangesDataStatusName, {
                    ["approved"]:
                      filter.value === SeeChangesDataStatus.Approved,
                    ["pending"]: filter.value === SeeChangesDataStatus.Pending,
                  })}
                >
                  {filter.name}
                </CustomTypography>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    );
  }
}

export default withStyles(styles)(CustomSeeChangesDataStatus);
