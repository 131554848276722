import { Box, createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ErrorMessage, Field } from "formik";
import React from "react";
import CustomCheckboxInput, {
  CustomCheckboxInputProps,
} from "./CustomCheckboxInput.web";

export interface Props extends WithStyles {
  error?: string;
}

export type CustomFormCheckboxFieldProps = CustomCheckboxInputProps & Props;

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    "& + &": {
      marginTop: 24,
    },

    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#DC2626",
      fontWeight: 400,
      lineHeight: "18px",
      fontSize: 12,
      fontFamily: "Inter",
    },
  },
});

export class CustomFormCheckboxField extends React.Component<CustomFormCheckboxFieldProps> {
  constructor(props: CustomFormCheckboxFieldProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { name, classes, error, checkboxLabel, ...rest } = this.props;

    return (
      <Box className={classes?.container}>
        <Field
          className={classes.input}
          as={CustomCheckboxInput}
          checkboxLabel={checkboxLabel}
          name={name}
          error={error}
          helperText={error && <ErrorMessage name={name} />}
          {...rest}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(CustomFormCheckboxField);
