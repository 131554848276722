import {
  WithStyles,
  createStyles,
  withStyles,
  Box,
  Theme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "./CustomTypography.web";
import { Edit, ExpandMore, Height } from "@material-ui/icons";
import clsx from "clsx";
import KitchenOrderCatalogueStatusMenu, {
  KitchenOrderCatalogueStatus,
} from "./KitchenOrderCatalogueStatusMenu.web";

export interface KitchenMenuCategory {
  id: string;
  name: string;
  image: string;
  catalogues: {
    id: string;
    name: string;
    available: string;
    image: string;
    price: number;
  }[];
}

export interface Props extends WithStyles<any> {
  category: KitchenMenuCategory;

  openEditCategoryDrawer(id: string): void;
  updateKitchenMenuCatalogueStatus(
    id: string,
    value: KitchenOrderCatalogueStatus
  ): void;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      boxShadow: "initial",
      borderRadius: "0 !important",

      "& .MuiAccordionSummary-content": {
        margin: 0,
      },

      "& .MuiAccordionSummary-expandIcon": {
        position: "absolute",
        right: 14,
        top: 14,
        color: "#334333",
      },
    },

    summary: {
      display: "flex",
      alignItems: "initial",
      padding: 0,
      position: "relative",
    },

    inner: {
      paddingRight: 48,
      display: "flex",
      alignItems: "center",
      padding: 12,
      border: "1px solid #EDEDED",
      borderRadius: 8,
      width: "100%",
    },

    image: {
      minWidth: 50,
      maxWidth: 50,
      height: 50,
      border: "1px solid #EDEDED",
      borderRadius: 8,
      objectFit: "cover",
      overflow: "hidden",
    },

    content: {
      marginLeft: 10,
    },

    name: {
      lineHeight: "24px",
      fontWeight: "600 !impotant" as any,
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
    },

    description: {
      color: "#FF6666 !important",
    },

    editButton: {
      marginLeft: 10,
      color: "#FF6666",
    },

    detailList: {
      backgroundColor: "#FFFBF4",
      maxHeight: 222,
      overflow: "auto",
      borderRadius: 8,
      padding: "10px !important",
      marginTop: 15,
      flexDirection: "column",
      minHeight: 72,
    },

    catalogue: {
      display: "flex",
      alignItems: "center",

      "& + & ": {
        marginTop: 15,
      },
    },

    catalogueHeading: {
      display: "flex",
      alignItems: "center",
    },

    catalogueStatus: {
      marginLeft: 5,
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
      minWidth: 58,
    },

    catalogueImage: {
      width: 50,
      height: 50,
      border: "1px solid #EDEDED",
      borderRadius: 8,
      objectFit: "cover",
    },

    catalogueAvailable: {
      fontSize: "12px !important",
      lineHeight: "24px !important",
      color: "#059669",
    },

    catalogueUnavailable: {
      fontSize: "12px !important",
      lineHeight: "24px !important",
      color: "#DC2626",
    },

    catalogueContent: {
      marginLeft: 10,
      width: "100%",
      flex: 1,
    },

    cataloguePrice: {
      lineHeight: "24px !important",
      color: "#333333 !important",
      fontWeight: "400 !important" as any,
    },

    catalogueHeadButton: {
      color: "#333333",
    },

    catalogueMenuButton: {
      color: "#333333",
    },

    detailListEmpty: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },

    catalogueName: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
    },
  });

export class KitchenMenuCategoryAccordion extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      classes,
      category,

      openEditCategoryDrawer,
      updateKitchenMenuCatalogueStatus,
    } = this.props;

    const { id, name, image, catalogues } = category;

    return (
      <Accordion className={classes.container}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMore />}
        >
          <Box className={classes.inner}>
            <img className={classes.image} src={image} alt={name} />

            <Box className={classes.content}>
              <CustomTypography variant="subtitle2" className={classes.name}>
                {name}
              </CustomTypography>
            </Box>

            <IconButton
              data-test-id="edit-btn"
              className={classes.editButton}
              onClick={(e) => {
                e.stopPropagation();
                openEditCategoryDrawer(id);
              }}
            >
              <Edit />
            </IconButton>
          </Box>
        </AccordionSummary>

        <AccordionDetails className={classes.detailList}>
          {!catalogues.length ? (
            <Box className={classes.detailListEmpty}>
              <CustomTypography variant="body1">
                There is no data
              </CustomTypography>
            </Box>
          ) : (
            catalogues.map((catalogue) => (
              <Box className={classes.catalogue} key={catalogue.id}>
                <IconButton className={classes.catalogueHeadButton}>
                  <Height />
                </IconButton>

                <img
                  src={catalogue.image}
                  alt={catalogue.name}
                  className={classes.catalogueImage}
                />

                <Box className={classes.catalogueContent}>
                  <Box className={classes.catalogueHeading}>
                    <CustomTypography
                      variant="subtitle2"
                      className={classes.catalogueName}
                    >
                      {catalogue.name}
                    </CustomTypography>

                    <CustomTypography
                      variant="subtitle2"
                      className={clsx(classes.catalogueStatus, {
                        [classes.catalogueAvailable]:
                          catalogue.available === "Available",

                        [classes.catalogueUnavailable]: [
                          "Unavailable",
                          "Unavailable_till_next_operations",
                        ].includes(catalogue.available),
                      })}
                    >
                      {catalogue.available}
                    </CustomTypography>
                  </Box>

                  <CustomTypography
                    className={classes.cataloguePrice}
                    variant="caption"
                  >
                    {`${catalogue.price} SAR`}
                  </CustomTypography>
                </Box>

                <KitchenOrderCatalogueStatusMenu
                  data-test-id="catalouge-status-menu"
                  onChange={(value) =>
                    updateKitchenMenuCatalogueStatus(catalogue.id, value)
                  }
                  value={catalogue.available}
                />
              </Box>
            ))
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withStyles(styles)(KitchenMenuCategoryAccordion);
