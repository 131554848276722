import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import React from "react";
import { Component, ReactNode } from "react";

import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface CustomDatePickerProps
  extends ReactDatePickerProps,
    WithStyles<any> {}

const styles = createStyles({
  calendar: {
    fontFamily: "Inter",
    fontSize: "0.875rem",
    lineHeight: 22 / 14,
    border: "1px solid #E2E8F0",
    borderRadius: 8,
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06)",

    "& .react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only":
      {
        display: "none",
      },

    "& .react-datepicker__current-month": {
      color: "#FF6666",
      fontWeight: 700,
      paddingBlock: 16,
    },
    "& .react-datepicker-time__header": {
      color: "#FF6666",
    },
    "& .react-datepicker__header--time": {
      paddingBlockStart: 24,
      paddingBlockEnd: 16,
    },
    "& .react-datepicker__time-container": {
      borderLeftColor: "#E2E8F0",
    },
    "& .react-datepicker__navigation": {
      top: 18,
    },
    "& .react-datepicker__navigation-icon::before": {
      borderColor: "#FF6666",
    },
    "& .react-datepicker__header": {
      backgroundColor: "#FFF",
      borderBottomColor: "#FFF",
      borderTopLeftRadius: 8,
      " &:not(&--has-time-select)": {
        borderTopRightRadius: 8,
      },
    },
    "& .react-datepicker__day, & .react-datepicker__time-name": {
      color: "#0F172A",
    },
    "& .react-datepicker__day-name": {
      color: "#333",
    },

    "& .react-datepicker__day--keyboard-selected, & .react-datepicker__day--selected:hover, & .react-datepicker__day--selected, & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover, & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected":
      {
        color: "#FFF",
        backgroundColor: "#FF6666",
      },
    "& .react-datepicker__day--disabled, & .react-datepicker__month-text--disabled,& .react-datepicker__quarter-text--disabled,& .react-datepicker__year-text--disabled":
      {
        color: "#ccc",
      },
    "& .react-datepicker__day--keyboard-selected, & .react-datepicker__month-text--keyboard-selected, & .react-datepicker__quarter-text--keyboard-selected, & .react-datepicker__year-text--keyboard-selected":
      {
        backgroundColor: "#FF6666",
      },
  },
});

class CustomDatePicker extends Component<CustomDatePickerProps> {
  constructor(props: CustomDatePickerProps) {
    super(props);
  }

  render(): ReactNode {
    const { classes, ...rest } = this.props;

    return (
      <DatePicker
        {...rest}
        calendarClassName={classes.calendar}
        showPopperArrow={false}
      />
    );
  }
}

export default withStyles(styles)(CustomDatePicker);
