import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { CustomFilterMenu } from "..//../../components/src/CustomFilter.web";
import { v4 as uuidv4 } from "uuid";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { KitchenAccountManagementMenuAction } from "../../../components/src/KitchenAccountManagementMenu.web";
import { KitchenAccountDeleteDialogProps } from "../../../components/src/KitchenAccountDeleteDialog.web";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  users: any[];
  search: string;
  filter: string;
  loading: boolean;
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };

  filterMenuList: CustomFilterMenu[];

  accountDrawer: boolean;
  loadingAddAccount: boolean;

  deleteUserDialog: KitchenAccountDeleteDialogProps;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class KitchenAccountManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputRef: React.RefObject<HTMLInputElement>;

  fetchAccountsApiId: string = "";
  fetchAllRolesApiId: string = "";
  addNewUserApiId: string = "";
  deteteUserApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      users: [],
      filter: "",
      search: "",
      loading: false,
      pagination: {
        page: 1,
        limit: 12,
        totalPages: 0,
        totalCount: 0,
      },
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
      filterMenuList: [{ id: uuidv4(), name: "All", value: "" }],
      accountDrawer: false,
      loadingAddAccount: false,
      deleteUserDialog: {
        open: false,
        user: {
          id: "",
          fullName: "",
        },
      },
    };

    this.subScribedMessages = [
      getName(MessageEnum.NavigationEmailLogInMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.inputRef = React.createRef();

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchAllRoles();
    this.fetchAccounts();
    // Customizable Area End
  }

  // Customizable Area Start
  fetchAccounts = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchAccountsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/all_staff_accounts?search=${this.state.search}&page=${this.state.pagination.page}&items=${this.state.pagination.limit}&role_name=${this.state.filter}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleFetchAccounts = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.setState({
      users: responseJson.accounts.data.map((user: any) => ({
        id: user.id,
        time: user.attributes.created_at,
        username: user.attributes.user_name,
        email: user.attributes.email,
        role: user.attributes.role_name,
      })),

      pagination: {
        limit: 12,
        page: responseJson.pagination.current_page,
        totalCount: responseJson.pagination.total_count,
        totalPages: responseJson.pagination.total_pages,
      },
    });
  };

  fetchAllRoles = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchAllRolesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_roles_permissions/account_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFetchAllRoles = (responseJson: any) => {
    if (responseJson.errors || responseJson.error || !responseJson.data) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const newData = responseJson.data.map((role: any) => ({
      id: role.id,
      name: role.attributes.name,
      value: role.attributes.name,
    }));

    this.setState({
      filterMenuList: [...this.state.filterMenuList, ...newData],
    });
  };

  addNewUser = async (values: {
    user_name: string;
    email: string;
    role_name: string;
  }) => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON,
      token,
    };

    const body = {
      data: {
        type: "staff_account",
        attributes: values,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addNewUserApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/create_staff_account`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ loadingAddAccount: true });
  };

  handleAddNewUser = (responseJson: any) => {
    this.setState({ loadingAddAccount: false });
    if (responseJson && responseJson.data) {
      this.openSnackbar(CustomSnackbarType.Success, "New user added");
      this.setState({ accountDrawer: false }, () => {
        this.fetchAccounts();
      });
    }

    if (responseJson && responseJson.errors && responseJson.errors[0].email) {
      this.openSnackbar(CustomSnackbarType.Error, responseJson.errors[0].email);
    }
  };

  changeFilter = (filter: string) => {
    this.setState(
      {
        filter,
      },
      () => {
        this.fetchAccounts();
      }
    );
  };

  handleSearch = (event: any) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    this.setState(
      {
        search: event.target.value,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAccounts();
      }
    );
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchAccounts();
      }
    );
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  openAccountDrawer = () => {
    this.setState({
      accountDrawer: true,
    });
  };

  closeAccountDrawer = () => {
    this.setState({
      accountDrawer: false,
    });
  };

  handleSelectAction = (
    id: string | number,
    action: KitchenAccountManagementMenuAction
  ) => {
    switch (action) {
      case KitchenAccountManagementMenuAction.Edit:
        this.props.navigation.kitchenNavigate(
          `/kitchen/owner/user-management/user/${id}?mode=${KitchenAccountManagementMenuAction.Edit}`
        );

        break;

      case KitchenAccountManagementMenuAction.View:
        this.props.navigation.kitchenNavigate(
          `/kitchen/owner/user-management/user/${id}?mode=${KitchenAccountManagementMenuAction.View}`
        );

        break;

      case KitchenAccountManagementMenuAction.Delete:
        const selectedUser = this.state.users.find((user) => user.id === id);

        if (!selectedUser) {
          return;
        }
        this.openDeleteUserDialog({
          id,
          fullName: selectedUser.username,
        });

        break;

      default:
        break;
    }
  };

  openDeleteUserDialog = (user: { id: string | number; fullName: string }) => {
    this.setState({
      deleteUserDialog: {
        open: true,
        user,
      },
    });
  };

  closeDeleteUserDialog = () => {
    this.setState({
      deleteUserDialog: {
        open: false,
        user: {
          id: "",
          fullName: "",
        },
      },
    });
  };

  deleteUser = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deteteUserApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/${this.state.deleteUserDialog.user.id}/delete_staff`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.closeDeleteUserDialog();

    this.setState({
      loading: true,
    });
  };

  handleDeleteUser = (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson && responseJson.errors && responseJson.errors) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.openSnackbar(
      CustomSnackbarType.Success,
      "Account deleted successfully"
    );

    this.fetchAccounts();
  };

  clearSearch = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }

    this.setState(
      {
        search: "",
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAccounts();
      }
    );
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchAccountsApiId:
        this.handleFetchAccounts(responseJson);
        break;

      case this.fetchAllRolesApiId:
        this.handleFetchAllRoles(responseJson);
        break;

      case this.addNewUserApiId:
        this.handleAddNewUser(responseJson);
        break;

      case this.deteteUserApiId:
        this.handleDeleteUser(responseJson);
        break;

      default:
        break;
    }

    // Customizable Area End
  }
}
