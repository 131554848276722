import {
  Box,
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomButton from "../CustomButton.web";
import CustomFormInputField from "../CustomFormInputField.web";
import KitchenDrawer from "../KitchenDrawer.web";

const schemaAccept = Yup.object({
  point: Yup.string()
    .required("Please enter message.")
    .transform((message) => message.trim()),
});

const schemaReject = Yup.object({
  message: Yup.string()
    .required("Please enter message.")
    .transform((message) => message.trim()),
});

interface Props extends WithStyles<any> {
  type: "accept" | "reject";
  open: boolean;
  loading: boolean;
  onClose(): void;
  onSubmit(values: { point: string } | { message: string }): void;
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      paddingBlockStart: "2.5rem",

      [theme.breakpoints.down("lg")]: {
        paddingBlockStart: "1.5rem",
      },
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },
  });

const getError = (
  props: FormikProps<
    | {
        point: string;
      }
    | {
        message: string;
      }
  >
) => {
  let error: boolean | undefined = undefined;

  if ("point" in props.touched && "point" in props.errors)
    error = props.touched.point && !!props.errors.point;

  if ("message" in props.touched && "message" in props.errors)
    error = props.touched.message && !!props.errors.message;

  return error;
};

export class CustomLoyaltySystemFormDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, open, loading, type, onClose, onSubmit } = this.props;

    return (
      <KitchenDrawer
        open={open}
        title={type === "accept" ? "Add Reward Point" : "Reject"}
        onClose={onClose}
        bottom={
          <Box className={classes.formBottom}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              Cancel
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              form="formCompensation"
            >
              {loading ? (
                <CircularProgress color="inherit" size={"1.5rem"} />
              ) : (
                "Submit"
              )}
            </CustomButton>
          </Box>
        }
        body={
          <Formik
            initialValues={
              type === "accept"
                ? schemaAccept.default()
                : schemaReject.default()
            }
            validationSchema={type === "accept" ? schemaAccept : schemaReject}
            onSubmit={onSubmit}
            data-test-id="formikFormCompensation"
          >
            {(props) => {
              return (
                <Form
                  id="formCompensation"
                  data-test-id="formCompensation"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <div className={classes.formInner}>
                    <CustomFormInputField
                      fullWidth
                      name={type === "accept" ? "point" : "message"}
                      multiline={type === "reject"}
                      maxRows={3}
                      minRows={3}
                      label={
                        type === "accept"
                          ? "Enter Loyalty Point"
                          : "Compensations Reject"
                      }
                      placeholder=""
                      variant="outlined"
                      error={getError(props)}
                      data-test-id="input"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}

export default withStyles(styles)(CustomLoyaltySystemFormDrawer);
