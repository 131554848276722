import React, { Component } from "react";

import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles";

import { Rating } from "@material-ui/lab";

import CustomTypography from "../CustomTypography.web";

interface Props extends WithStyles<any> {
  title: string;
  description: string;
  rating: number;
}

const styles = () =>
  createStyles({
    container: {
      "&  > * + *": {
        marginTop: 10,
      },
      "& .reviews-content__title": {
        fontWeight: 600,
      },
    },
  });

class ReviewsContent extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, title, description, rating } = this.props;

    return (
      <div className={classes.container}>
        <CustomTypography className="reviews-content__title">
          {title}
        </CustomTypography>
        <div className="reviews-content__rating">
          <Rating defaultValue={rating} readOnly />
        </div>
        <CustomTypography className="reviews-content__description">
          {description}
        </CustomTypography>
      </div>
    );
  }
}

export default withStyles(styles)(ReviewsContent);
