import { Box, WithStyles, createStyles, withStyles } from "@material-ui/core";
import React from "react";
import { TooltipProps } from "recharts";
import CustomTypography from "./CustomTypography.web";

export interface Props extends WithStyles {}

export type CustomRechartRevenueTooltipProps = Props &
  TooltipProps<number, string>;

const styles = () =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },

    number: {
      fontFamily: "Roboto !important",
      fontWeight: "700 !important" as any,
      lineHeight: "40.77px  !important",
    },
  });

export class CustomRechartRevenueTooltip extends React.Component<CustomRechartRevenueTooltipProps> {
  constructor(props: CustomRechartRevenueTooltipProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, active, payload } = this.props;

    if (!active || !payload || !payload.length) {
      return <></>;
    }

    const value = payload[0].value ?? 0;

    return (
      <Box className={classes.container}>
        <CustomTypography className={classes.number}>
          <strong>{`$${value.toLocaleString()}`}</strong>
        </CustomTypography>
      </Box>
    );
  }
}

export default withStyles(styles)(CustomRechartRevenueTooltip);
