// Customizable Area Start
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import { WithStyles } from "@material-ui/core";
import { CustomFilterMenu } from "../../../components/src/CustomFilter.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import * as Yup from "yup";

export const configJSON = require("./config");

type Order = {
  id: string;
  type: "reward";
  attributes: {
    order_id: number;
    pos_id: null | string;
    points: null | number;
    price: null | number;
    kitchen_account_id: number;
    status: null | string;
    notes: string;
    title: string;
    compensation_amount: number;
    compensation_reject: number | string;
    created_at: string;
    compensation_id: number;
    store_id: number;
    store_name: string;
    compensation_reason: string;
    compensation_category: string;
    order: {
      data: {
        attributes: {
          order_items: {
            id: string;
            type: "order_item";
            attributes: {
              quantity: 12;
              unit_price: string;
              total_price: string;
              catalogue: {
                id: string;
                type: "catalogue";
                attributes: {
                  id: 31;
                  name: string;
                  name_arabic: string;
                };
              };
            };
          }[];
        };
      };
    };
  };
};

type Category = {
  id: string;
  type: "compensation";
  attributes: {
    compensation_category: string;
    compensation_reason: string;
  };
};

type Branch = {
  id: string;
  type: "store";
  attributes: {
    name: string;
  };
};

type OrderNumber = {
  id: "93";
  type: "order";
  attributes: {
    id: 93;
    order_number: "OD00000075";
  };
};

export const schema = Yup.object({
  compensation_amount: Yup.number().typeError("Please enter number"),
  notes: Yup.string(),
  order_id: Yup.string().required("Please select order number"),
  store_id: Yup.string().required("Please select branch"),
  compensation_id: Yup.string().required("Please select compensation reason"),
});

export type Values = Yup.InferType<typeof schema>;

export interface Props extends WithStyles {
  navigation: any;
  id: string;
}

interface S {
  orders: Order[];
  filterStartDate: string;
  filterEndDate: string;

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  loading: boolean;

  loadingOrders: boolean;
  listOrderNumber: CustomFilterMenu[];

  loadingCategories: boolean;
  filterCategory: string;
  filterCategoryList: CustomFilterMenu[];
  listCompensationReason: CustomFilterMenu[];

  loadingBranches: boolean;
  listBranch: CustomFilterMenu[];

  openForm: boolean;
  openFormAdd: boolean;
  formType: "accept" | "reject";

  loadingUpdate: boolean;
  loadingCreate: boolean;
  updateId: string;

  loadingEmailReport: boolean;
}

interface SS {}

export default class LoyaltySystemCompensationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetOrdersCallId = "";
  apiGetCompensationCategoriesCallId = "";
  apiGetOrderNumberCallId = "";
  apigGetBranchesCallId = "";
  apiUpdateOrderStatusCallId = "";
  apiCreateOrderCallId = "";
  apiCreateEmailReportCallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      filterCategory: "-1",
      filterCategoryList: [],
      filterEndDate: "",
      filterStartDate: "",
      orders: [],
      pagination: { limit: 8, page: 1, totalCount: 1, totalPages: 1 },
      snackbar: { message: "", open: false, type: undefined },
      loading: false,
      loadingOrders: false,
      listOrderNumber: [],
      loadingCategories: false,
      listBranch: [],
      loadingBranches: false,
      listCompensationReason: [],
      openForm: false,
      openFormAdd: false,
      formType: "accept",

      loadingCreate: false,
      loadingUpdate: false,

      updateId: "",
      loadingEmailReport: false,
    };
  }

  getOrders = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetOrdersCallId = requestMessage.messageId;

    const queryParams = new URLSearchParams();

    queryParams.append("page", this.state.pagination.page.toString());
    queryParams.append("per_page", this.state.pagination.limit.toString());
    queryParams.append("start_date", this.state.filterStartDate);
    queryParams.append("end_date", this.state.filterEndDate);
    queryParams.append(
      "compensation_id",
      this.state.filterCategory === "-1" ? "" : this.state.filterCategory
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.compensationsEndPoint + "?" + queryParams.toString()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetOrders = (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson && responseJson.rewards && responseJson.rewards.data) {
      this.setState({
        orders: responseJson.rewards.data,
        pagination: {
          ...this.state.pagination,
          page: responseJson.pagination.current_page,
          totalCount: responseJson.pagination.total_entries,
          totalPages: responseJson.pagination.total_pages,
        },
      });
    }
  };

  getOrderOptions = () => {
    this.setState({ loadingOrders: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetOrderNumberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ordersEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetOrderNumber = (responseJson: any) => {
    this.setState({ loadingOrders: false });

    if (responseJson && responseJson.data) {
      const listOrderNumber = responseJson.data.map((order: OrderNumber) => {
        return {
          id: order.id,
          value: order.id,
          name: order.attributes.order_number,
        };
      });

      this.setState({ listOrderNumber });
    }
  };

  getCategories = () => {
    this.setState({ loadingOrders: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCompensationCategoriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetCategories = (responseJson: any) => {
    this.setState({ loadingCategories: false });

    if (responseJson && responseJson.data) {
      const categories = responseJson.data.map((category: Category) => {
        return {
          id: category.id,
          value: category.id,
          name: category.attributes.compensation_category,
        };
      });

      const reasons = responseJson.data.map((reason: Category) => {
        return {
          id: reason.id,
          value: reason.id,
          name: reason.attributes.compensation_reason,
        };
      });

      this.setState({
        filterCategoryList: categories,
        listCompensationReason: reasons,
      });
    }
  };

  getBranches = () => {
    this.setState({ loadingBranches: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigGetBranchesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.branchesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetBranches = (responseJson: any) => {
    this.setState({ loadingBranches: false });
    if (responseJson && responseJson.stores && responseJson.stores.data) {
      const listBranch = responseJson.stores.data.map((branch: Branch) => {
        return {
          id: branch.id,
          value: branch.id,
          name: branch.attributes.name,
        };
      });

      this.setState({ listBranch });
    }
  };

  updateOrder = (payload: object) => {
    this.setState({ loadingUpdate: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateOrderStatusCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.compensationsEndPoint + "/" + this.state.updateId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "patch"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUpdateOrder = (responseJson: any) => {
    this.setState({ loadingUpdate: false });

    if (
      responseJson &&
      responseJson.message === "Reward updated successfully"
    ) {
      this.closeForm();
      this.getOrders();
    }
  };

  createOrder = (values: Values) => {
    this.setState({ loadingCreate: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreateOrderCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.compensationsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "post"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCreateOrder = (responseJson: any) => {
    this.setState({ loadingCreate: false });

    if (responseJson && responseJson.data) {
      this.setState({ openFormAdd: false }, () => {
        this.getOrders();
      });
      this.openSnackbar(
        CustomSnackbarType.Success,
        "Success! New order was added."
      );
    }

    if (responseJson && responseJson.errors) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again.");
    }
  };

  createEmailReport = () => {
    this.setState({ loadingEmailReport: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreateEmailReportCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailReportEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCreateEmailReport = (responseJson: any) => {
    this.setState({ loadingEmailReport: false });
    if (responseJson && responseJson.message === "PDF sent to your email") {
      this.openSnackbar(
        CustomSnackbarType.Success,
        "Success! Report sent on registered email"
      );
    }
  };

  async componentDidMount() {
    this.getOrders();
    this.getBranches();
    this.getCategories();
    this.getOrderOptions();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.apiGetOrdersCallId:
          this.handleGetOrders(responseJson);
          break;

        case this.apiGetOrderNumberCallId:
          this.handleGetOrderNumber(responseJson);
          break;

        case this.apigGetBranchesCallId:
          this.handleGetBranches(responseJson);
          break;

        case this.apiGetCompensationCategoriesCallId:
          this.handleGetCategories(responseJson);
          break;

        case this.apiUpdateOrderStatusCallId:
          this.handleUpdateOrder(responseJson);
          break;

        case this.apiCreateOrderCallId:
          this.handleCreateOrder(responseJson);
          break;

        case this.apiCreateEmailReportCallId:
          this.handleCreateEmailReport(responseJson);
      }
    }
  }

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.getOrders();
      }
    );
  };

  changeFilterCategory = (category: string) => {
    this.setState({
      filterCategory: category,
    });
  };

  extractDate = (date: Date | null) => {
    return date
      ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      : "";
  };

  getSelectedDate = (date: string) => {
    return date ? new Date(date) : null;
  };

  changeStartDate = (date: Date | null) => {
    const startDate = this.extractDate(date);
    this.setState({ filterStartDate: startDate });
  };
  changeEndDate = (date: Date | null) => {
    const endDate = this.extractDate(date);
    this.setState({ filterEndDate: endDate });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: { message: "", open: false, type: undefined },
    });
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: { message, open: true, type },
    });
  };

  openForm = (formType: "accept" | "reject", orderId: string) => {
    this.setState({ openForm: true, formType, updateId: orderId });
  };

  closeForm = () => {
    this.setState({
      openForm: false,
      updateId: "",
    });
  };

  openFormAdd = () => {
    this.setState({ openFormAdd: true });
  };

  closeFormAdd = () => {
    this.setState({ openFormAdd: false });
  };

  resetFilter = () => {
    if (
      this.state.filterStartDate ||
      this.state.filterEndDate ||
      this.state.filterCategory !== "-1"
    ) {
      this.setState(
        {
          filterStartDate: "",
          filterEndDate: "",
          filterCategory: "-1",
        },
        () => {
          this.getOrders();
        }
      );
    }
  };

  applyFilter = () => {
    this.getOrders();
  };

  handleUpdateSubmit = (
    values:
      | {
          point: string;
        }
      | {
          message: string;
        }
  ) => {
    if ("point" in values) {
      this.updateOrder({ status: "accept", points: values.point });
    }
    if ("message" in values) {
      this.updateOrder({ status: "rejected", message: values.message });
    }
  };

  handleDropdownClick = (item: "accept" | "reject", orderId: string) => {
    this.openForm(item, orderId);
  };

  shouldLoadingBackdropShow = () => {
    return (
      this.state.loading ||
      this.state.loadingOrders ||
      this.state.loadingCategories ||
      this.state.loadingBranches ||
      this.state.loadingEmailReport
    );
  };
}

// Customizable Area End
