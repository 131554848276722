import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { WithTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

export enum BusinessTabValue {
  Today = "Today",
  Yesterday = "Yesterday",
  SevenDays = "7 Days",
  ThirtyDays = "30 Days",
}

export interface RestaurantBusiness {
  id: string | number;
  name: string;
  orderCount: number;
  sales: number;
}

export enum BusinessView {
  OutletView = "Outlet View",
  ChartView = "Chart View",
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

export interface LiveOpsMonitorDataProps {
  number: number;
  isGood: boolean;
}

export interface LiveOpsMonitorData {
  offlineOutlets: LiveOpsMonitorDataProps;
  cancelledOrders: LiveOpsMonitorDataProps;
  delayedOrders: LiveOpsMonitorDataProps;
  rating: LiveOpsMonitorDataProps;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  businessTab: BusinessTabValue;
  businessView: BusinessView;
  restaurantBusinessData: RestaurantBusiness[];
  liveOpsMonitor: LiveOpsMonitorData;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardKitchenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  businessSummaryTabs = [
    {
      id: uuidv4(),
      name: "Today",
      value: BusinessTabValue.Today,
    },

    {
      id: uuidv4(),
      name: "Yesterday",
      value: BusinessTabValue.Yesterday,
    },

    {
      id: uuidv4(),
      name: "7 Days",
      value: BusinessTabValue.SevenDays,
    },

    {
      id: uuidv4(),
      name: "30 Days",
      value: BusinessTabValue.ThirtyDays,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      businessTab: BusinessTabValue.Today,
      businessView: BusinessView.OutletView,

      restaurantBusinessData: [],

      liveOpsMonitor: {
        offlineOutlets: {
          number: 7,
          isGood: false,
        },

        cancelledOrders: {
          number: 0,
          isGood: true,
        },

        delayedOrders: {
          number: 0,
          isGood: true,
        },

        rating: {
          number: 0,
          isGood: true,
        },
      },
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchRestaurantData();
    // Customizable Area End
  }

  // Customizable Area Start
  changeBusinessTab = (businessTab: BusinessTabValue) => {
    this.setState({
      businessTab,
    });
  };

  changeBusinessView = (businessView: BusinessView) => {
    this.setState({
      businessView,
    });
  };

  fetchRestaurantData = () => {
    const restaurants = [
      "Almudayaf - Aalraskih ash shamaliyah",
      "Almudyaf Al Bahar",
      "Almudyaf Al Muhammadiyah",
      "Almudyal - Almidyaf Al Khuzama",
      "Almudyal Almidyaf An Nuzhah",
      "Almudyat Ar Rabwah",
    ];

    this.setState({
      restaurantBusinessData: restaurants.map((restaurant) => ({
        id: restaurant,
        name: restaurant,
        orderCount: 0,
        sales: 0,
      })),
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}
