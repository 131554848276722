import {
  Box,
  ListItemText,
  MenuItem,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomButton from "./CustomButton.web";
import CustomFormInputField from "./CustomFormInputField.web";
import CustomDialog from "./CustomDialog.web";
import CustomFormSelectField from "./CustomFormSelectField.web";
import CustomFormDatePickerField from "./CustomFormDatePickerField.web";

const validationSchema = Yup.object({
  restaurantName: Yup.string()
    .nullable()
    .required("Please enter Restaurant Name"),
  branch: Yup.string().nullable().required("Please enter Branch"),
  datePlaced: Yup.string().nullable().required("Please enter Date Placed"),
  totalAmount: Yup.number()
    .nullable()
    .required("Please enter Total Amount")
    .positive("Please enter valid Total Amount")
    .typeError("Please enter valid Total Amount"),
  status: Yup.string().nullable().required("Please enter Status"),
});

export interface Props extends WithStyles {
  dialog: AdminOrderDetailFormDialogProps;

  onClose(): void;
  editAdminOrder(values: any): void;
  changeOrderDetailDialogMode(mode: AdminOrderDetailFormDialogMode): void;
}

export enum AdminOrderDetailFormDialogMode {
  View = "view",
  Edit = "edit",
}

export interface AdminOrderDetailFormDialogProps {
  mode: AdminOrderDetailFormDialogMode;
  open: boolean;
  loading: boolean;
  form: {
    id: string | number;
    orderNumber: string;
    restaurantName: string;
    branch: string;
    datePlaced: Date | null;
    totalAmount: number;
    status: string;
  };
  branchOptions: {
    id: string;
    name: string;
    value: string | number;
  }[];

  statusOptions: {
    id: string;
    name: string;
    value: string | number;
  }[];
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginBottom: 0,
    },

    formNameGroup: {
      marginTop: 20,
      display: "flex",
      gap: 15,

      "& > div": {
        marginTop: "0 !important",
      },
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,
      width: "100%",
      justifyContent: "end",

      "& > button": {
        width: 120,
      },
    },

    formPriceInput: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    orderNumber: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    datePlaced: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    formInput: {
      marginTop: 20,
    },

    cancel: {
      color: "#FF6666",
      backgroundColor: "#FFEAEA",
    },
  });

export class AdminOrderDetailFormDialog extends React.Component<Props> {
  private formProps?: FormikProps<any> = undefined;

  constructor(props: Props) {
    super(props);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render(): React.ReactNode {
    const {
      classes,
      dialog,
      onClose,
      editAdminOrder,
      changeOrderDetailDialogMode,
    } = this.props;

    const { mode, open, form, branchOptions, statusOptions } = dialog;

    const disabledEdit = mode === AdminOrderDetailFormDialogMode.View;

    const tilte =
      mode === AdminOrderDetailFormDialogMode.Edit
        ? "Edit Order"
        : "View Order";

    return (
      <CustomDialog
        width={600}
        open={open}
        title={tilte}
        onClose={onClose}
        content={
          <Formik
            initialValues={{
              orderNumber: form.orderNumber,
              restaurantName: form.restaurantName,
              branch: form.branch,
              datePlaced: form.datePlaced,
              totalAmount: form.totalAmount,
              status: form.status,
            }}
            validationSchema={validationSchema}
            onSubmit={editAdminOrder}
            data-test-id="kitchen-item-formik"
          >
            {(props) => {
              this.formProps = props;

              return (
                <Form
                  data-test-id="kitchen-item-form"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <Box className={classes.formInner}>
                    <CustomFormInputField
                      className={classes.orderNumber}
                      fullWidth
                      name="orderNumber"
                      label="Order Id"
                      placeholder="Enter Order Id"
                      variant="outlined"
                      error={
                        props.touched.orderNumber && !!props.errors.orderNumber
                      }
                      data-test-id="order-number-input"
                      required
                      disabled
                    />

                    <CustomFormInputField
                      fullWidth
                      name="restaurantName"
                      label="Restaurant Name"
                      placeholder="Enter Restaurant Name"
                      variant="outlined"
                      error={
                        props.touched.restaurantName &&
                        !!props.errors.restaurantName
                      }
                      data-test-id="restaurant-name-input"
                      required
                      disabled={disabledEdit}
                    />

                    <Box className={classes.formInput}>
                      <CustomFormSelectField
                        fullWidth
                        name="branch"
                        label="Branch"
                        variant="outlined"
                        error={props.touched.branch && !!props.errors.branch}
                        data-test-id="branch-select"
                        displayEmpty
                        disabled={disabledEdit}
                        inputProps={{
                          renderValue: (selected: string) => {
                            if (!selected) {
                              return "Select Branch";
                            }

                            const selectedOption = branchOptions.find(
                              (option) => option.value === selected
                            );

                            if (!selectedOption) {
                              return "";
                            }

                            return selectedOption.name;
                          },
                        }}
                      >
                        {branchOptions.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </CustomFormSelectField>
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormInputField
                        className={classes.formPriceInput}
                        fullWidth
                        name="totalAmount"
                        label="Total Amount"
                        placeholder="Enter Total Amount"
                        variant="outlined"
                        error={
                          props.touched.totalAmount &&
                          !!props.errors.totalAmount
                        }
                        data-test-id="total-amount-input"
                        required
                        value={props.values.totalAmount || ""}
                        disabled={disabledEdit}
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormDatePickerField
                        className={classes.datePlaced}
                        dateFormat="yyyy-MM-dd"
                        name="datePlaced"
                        label="Date Placed"
                        placeholder="Enter Date Placed"
                        variant="outlined"
                        error={
                          props.touched.datePlaced && !!props.errors.datePlaced
                        }
                        data-test-id="date-placed-input"
                        required
                        fullWidth
                        value={props.values.datePlaced}
                        disabled={disabledEdit}
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormSelectField
                        fullWidth
                        name="status"
                        label="Status"
                        variant="outlined"
                        error={props.touched.status && !!props.errors.status}
                        data-test-id="status-select"
                        displayEmpty
                        disabled={disabledEdit}
                        inputProps={{
                          renderValue: (selected: string) => {
                            if (!selected) {
                              return "Select Branch";
                            }

                            const selectedOption = statusOptions.find(
                              (option) => option.value === selected
                            );

                            if (!selectedOption) {
                              return "";
                            }

                            return selectedOption.name;
                          },
                        }}
                      >
                        {statusOptions.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </CustomFormSelectField>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        }
        actions={
          <Box className={classes.formBottom}>
            <CustomButton
              className={classes.cancel}
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              Cancel
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              data-test-id="submit"
              type={
                mode === AdminOrderDetailFormDialogMode.Edit
                  ? "submit"
                  : "button"
              }
              onClick={() => {
                if (mode === AdminOrderDetailFormDialogMode.Edit) {
                  this.formProps?.handleSubmit();

                  return;
                }

                changeOrderDetailDialogMode(
                  AdminOrderDetailFormDialogMode.Edit
                );
              }}
            >
              {mode === AdminOrderDetailFormDialogMode.Edit ? "Save" : "Edit"}
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(AdminOrderDetailFormDialog);
