import React from "react";
// Customizable Area Start
import ResetPasswordKitchenController, {
  Props,
} from "./ResetPasswordKitchenController.web";
import { Box, createStyles, withStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AuthKitchenLayout from "../../../components/src/AuthKitchenLayout.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomFormPasswordField from "../../../components/src/CustomFormPasswordField.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import { kitchenLogo } from "./assets";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },

  logo: {
    width: 113,
    height: "auto",
  },

  heading: {
    marginTop: 32,
  },

  form: {
    width: "100%",
    maxWidth: 362,
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  formInner: {
    marginTop: 16,
  },

  resetPassword: {
    textAlign: "right",
    marginTop: 24,
  },

  submitBtn: {
    marginTop: 24,
  },

  goBackLogin: {
    marginTop: 24,
  },

  goBackLoginText: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "22px",
  },

  resetPasswordSnackbar: {
    position: "absolute",
    left: 0,
    bottom: 0,
  },
});

const validationSchema = Yup.object({
  password: Yup.string()
    .nullable()
    .required("Please enter your password")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /[A-Z]/,
      "Password must contain at least one uppercase letter, one lowercase letter and one number"
    )
    .matches(
      /[a-z]/,
      "Password must contain at least one uppercase letter, one lowercase letter and one number"
    )
    .matches(
      /\d+/,
      "Password must contain at least one uppercase letter, one lowercase letter and one number"
    ),
  confirmPassword: Yup.string()
    .nullable()
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm Password must match with Password"
    )
    .required("Please confirm password"),
});
// Customizable Area End

export class ResetPasswordKitchen extends ResetPasswordKitchenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;

    const { resetPasswordResponse, resetPasswordSnackbar, loading } =
      this.state;

    return (
      <AuthKitchenLayout>
        <Box className={classes.container}>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={this.resetPassword}
            data-test-id="reset-password-formik"
          >
            {(props) => {
              this.updateResetFormProps(props);

              return (
                <Form
                  data-test-id="reset-password-form"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <img className={classes.logo} src={kitchenLogo} alt="logo" />

                  <CustomTypography
                    className={classes.heading}
                    color="primary"
                    variant="h2"
                  >
                    Reset Password
                  </CustomTypography>

                  <Box className={classes.formInner}>
                    <CustomFormPasswordField
                      fullWidth
                      name="password"
                      label="Password"
                      placeholder="Enter password"
                      variant="outlined"
                      error={props.touched.password && !!props.errors.password}
                      data-test-id="password-input"
                      disabled={loading}
                    />

                    <CustomFormPasswordField
                      fullWidth
                      name="confirmPassword"
                      label="Confirm Password"
                      placeholder="Confirm password"
                      variant="outlined"
                      error={
                        props.touched.confirmPassword &&
                        !!props.errors.confirmPassword
                      }
                      data-test-id="confirm-password-input"
                      disabled={loading}
                    />
                  </Box>

                  <CustomButton
                    className={classes.submitBtn}
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-test-id="reset-password-btn"
                    fullWidth
                    disabled={loading}
                  >
                    Submit
                  </CustomButton>

                  <Box className={classes.goBackLogin}>
                    <CustomTypography className={classes.goBackLoginText}>
                      Go back{" "}
                      <CustomTypography
                        variant="button"
                        data-test-id="go-back-login-btn"
                        onClick={this.goBackToLogin}
                      >
                        Login
                      </CustomTypography>
                    </CustomTypography>
                  </Box>

                  <CustomSnackbar
                    className={classes.resetPasswordSnackbar}
                    autoHideDuration={3000}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    open={resetPasswordSnackbar}
                    type={resetPasswordResponse.type}
                    message={resetPasswordResponse.message}
                    onClose={this.handleResetPasswordSnackbar}
                  />
                </Form>
              );
            }}
          </Formik>
        </Box>
      </AuthKitchenLayout>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(ResetPasswordKitchen);
