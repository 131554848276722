import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "./CustomTypography.web";
import CustomInputField from "./CustomInputField.web";
import CustomButton from "./CustomButton.web";
import { KitchenOrderDish } from "./KitchenOrderTableRow.web";
import clsx from "clsx";
import { KitchenOrderSummaryStatus } from "./KitchenOrderSummaryDrawer.web";

interface S {
  message: string;
  showNote: boolean;
}

export interface Props extends WithStyles {
  dish: KitchenOrderDish;

  sendMessage(message: string): void;
  openOrderChangeDrawer(): void;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "& + &": {
        marginTop: 15,
      },
    },

    inner: {
      display: "flex",
      gap: 15,
    },

    image: {
      width: 80,
      height: 80,
      objectFit: "cover",
      border: "1px solid #EDEDED",
      borderRadius: 12,
    },

    content: {
      width: "100%",
      flex: 1,
    },

    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    name: {
      lineHeight: "24px !important",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
    },

    subtotal: {
      fontSize: "16px !important",
      lineHeight: "24px !important",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
      maxWidth: "50%",

      "&.accepted": {
        color: "#059669",
      },

      "&.declined": {
        color: "#DC2626",
      },
    },

    price: {
      fontFamily: "Inter !important",
      fontSize: "12px !important",
      lineHeight: "24px !important",
      wordBreak: "break-word",
    },

    quantity: {
      fontFamily: "Inter !important",
      fontSize: "16px !important",
      lineHeight: "24px !important",
    },

    note: {
      marginTop: 13,
      display: "flex",
      flexDirection: "column",
    },

    action: {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
    },

    actionTextButton: {
      textDecoration: "underline",
      fontSize: "12px !important",
      lineHeight: "24px !important",
    },

    actionButton: {
      marginTop: "auto",
      display: "flex",
      gap: 6,
      marginLeft: "auto",
    },

    sendButton: {
      marginTop: 10,
      marginLeft: "auto",
    },

    ready: {
      fontSize: "12px !important",
      fontWeight: "600 !important" as any,
      lineHeight: "24px !important",
      color: "#059669 !important",
      marginLeft: "auto",
    },
  });

export class KitchenOrderSummaryDish extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: props.dish.message,
      showNote: false,
    };
  }

  changeMessage = (message: string) => {
    this.setState({
      message,
    });
  };

  toggleShowNote = () => {
    this.setState({
      showNote: !this.state.showNote,
    });
  };

  submitMessage = (value: string) => {
    this.props.sendMessage(value);

    this.setState({
      showNote: false,
    });
  };

  render(): React.ReactNode {
    const { classes, dish, openOrderChangeDrawer } = this.props;

    const { message, showNote } = this.state;

    const { name, image, price, quantity, subtotal, orderSummaryStatus } = dish;

    return (
      <Box className={classes.container}>
        <Box className={classes.inner}>
          <img src={image} alt={name} className={classes.image} />

          <Box className={classes.content}>
            <Box className={classes.head}>
              <CustomTypography variant="subtitle2" className={classes.name}>
                {name}
              </CustomTypography>

              {orderSummaryStatus !== KitchenOrderSummaryStatus.Replace && (
                <CustomTypography
                  variant="subtitle1"
                  className={clsx(classes.subtotal, {
                    ["accepted"]: [
                      KitchenOrderSummaryStatus.Accept,
                      KitchenOrderSummaryStatus.ReadyForPickup,
                    ].includes(orderSummaryStatus),
                    ["declined"]:
                      orderSummaryStatus === KitchenOrderSummaryStatus.Decline,
                  })}
                >
                  {subtotal}
                </CustomTypography>
              )}
            </Box>

            <CustomTypography
              className={classes.price}
              variant="body1"
            >{`${price} SAR`}</CustomTypography>

            <Box className={classes.action}>
              {orderSummaryStatus !== KitchenOrderSummaryStatus.Replace && (
                <CustomTypography
                  className={classes.quantity}
                  variant="subtitle2"
                >{`X ${quantity}`}</CustomTypography>
              )}

              {orderSummaryStatus ===
                KitchenOrderSummaryStatus.ReadyForPickup && (
                <CustomTypography className={classes.ready} variant="body1">
                  Ready for Pickup
                </CustomTypography>
              )}

              {orderSummaryStatus === KitchenOrderSummaryStatus.Accept && (
                <CustomTypography className={classes.ready} variant="body1">
                  Accepted
                </CustomTypography>
              )}

              {[
                KitchenOrderSummaryStatus.Decline,
                KitchenOrderSummaryStatus.Replace,
              ].includes(orderSummaryStatus) && (
                <Box className={classes.actionButton}>
                  <CustomTypography
                    data-test-id="change-item-btn"
                    variant="button"
                    className={classes.actionTextButton}
                    onClick={openOrderChangeDrawer}
                  >
                    Change item
                  </CustomTypography>

                  <CustomTypography
                    variant="button"
                    data-test-id="toggle-note-btn"
                    className={classes.actionTextButton}
                    onClick={this.toggleShowNote}
                  >
                    {showNote ? "Hide Notes" : "Add Notes"}
                  </CustomTypography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <>
          {[
            KitchenOrderSummaryStatus.Decline,
            KitchenOrderSummaryStatus.Replace,
          ].includes(orderSummaryStatus) &&
            showNote && (
              <Box className={classes.note}>
                <CustomInputField
                  value={message}
                  variant="outlined"
                  multiline
                  minRows={3}
                  maxRows={3}
                  fullWidth
                  onChange={(e) => this.changeMessage(e.target.value)}
                />

                <CustomButton
                  disabled={!message}
                  className={classes.sendButton}
                  color="primary"
                  variant="contained"
                  data-test-id="send-message-btn"
                  onClick={() => this.submitMessage(message)}
                >
                  Send message
                </CustomButton>
              </Box>
            )}
        </>
      </Box>
    );
  }
}

export default withStyles(styles)(KitchenOrderSummaryDish);
