// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";

import { updateLocaleObject } from "../../../components/src/Utils.web";

import moment from "moment";
import "moment/locale/ar";

moment.updateLocale("ar", updateLocaleObject);

import { WithTranslation } from "react-i18next";

export const configJSON = require("./config");

type Notification = {
  id: string;
  type: "notification";
  attributes: {
    id: number;
    created_by: any;
    headings: string;
    contents: string;
    app_url: any;
    is_read: boolean;
    read_at: string;
    created_at: string;
    updated_at: string;
    super_admin_account_id: number;
    account: {
      data: {
        id: string;
        type: "account";
        attributes: {
          first_name: string | null;
          full_phone_number: string;
          email: string;
          image: {
            id: number;
            url: string;
          } | null;
        };
      };
    } | null;
  };
};

export interface Props extends WithTranslation {}

interface S {
  isNotificationCenter: boolean;
  notifications: Notification[];
  loading: boolean;
  loadingSendMessage: boolean;
  loadingGetEmailIDs: boolean;
  emailIDs: string[];
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  openModal: boolean;
}

interface SS {
  id: any;
}

export default class NotificationsAllController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetAllNotificationsCallId = "";
  apiUpdateAllNotificationsCallId = "";
  apiGetAllEmailIdsCallId = "";
  apiSendMessageCallId = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isNotificationCenter: false,

      notifications: [],
      loading: false,

      loadingSendMessage: false,

      loadingGetEmailIDs: false,
      emailIDs: [],

      openModal: false,

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getAllNotifications = () => {
    this.setState({ loading: true });

    const token = window.localStorage.getItem("token");
    const endPoint = configJSON.endPointGetAllNotifications;
    const header = {
      token,
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "get");
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    this.apiGetAllNotificationsCallId = message.messageId;

    runEngine.sendMessage(message.id, message);
  };

  updateAllNotificationReadStatus = () => {
    this.setState({ loading: true });

    const token = window.localStorage.getItem("token");
    const endPoint = configJSON.endPointUpdateAllNotificationReadStatus;
    const header = {
      "Content-Type": "application/json",
      token,
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "put");
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    this.apiUpdateAllNotificationsCallId = message.messageId;

    runEngine.sendMessage(message.id, message);
  };

  getAllEmailIds = () => {
    this.setState({ loadingGetEmailIDs: true });

    const token = window.localStorage.getItem("token");
    const endPoint = configJSON.endPointGetAllEmailIds;
    const header = {
      token,
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "get");
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    this.apiGetAllEmailIdsCallId = message.messageId;

    runEngine.sendMessage(message.id, message);
  };

  sendMessage = (values: any) => {
    this.setState({ loadingSendMessage: true });

    const token = window.localStorage.getItem("token");
    const urlParams = new URLSearchParams();
    urlParams.append("text", values.text);
    urlParams.append("email", values.email);

    const endPoint =
      configJSON.endPointSendMessage + "?" + urlParams.toString();

    const header = {
      token,
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "get");
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    this.apiSendMessageCallId = message.messageId;

    runEngine.sendMessage(message.id, message);
  };

  async componentDidMount() {
    this.getAllNotifications();

    if (window.location.pathname === "/kitchen/admin/notifications") {
      this.setState({ isNotificationCenter: true }, () => {
        this.getAllEmailIds();
      });
    }
  }

  handleGetAllNotifications = (responseJson: any) => {
    this.setState({ loading: false });

    if (
      responseJson &&
      responseJson.notifications &&
      responseJson.notifications.data
    ) {
      this.setState({ notifications: responseJson.notifications.data });
    }
  };

  handleUpdateAllNotificationReadStatus = (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson) {
      this.getAllNotifications();
    }
  };

  handleGetAllEmailIds = (responseJson: any) => {
    this.setState({ loadingGetEmailIDs: false });

    if (responseJson && Array.isArray(responseJson)) {
      responseJson.sort();
      this.setState({ emailIDs: responseJson });
    }
  };

  handleSendMessage = (responseJson: any) => {
    this.setState({ loadingSendMessage: false });

    if (responseJson && responseJson.message === "Email sent successfully") {
      this.showSnackbar(responseJson.message, CustomSnackbarType.Success);
      this.closeModal();
    }

    if (responseJson && responseJson.error) {
      this.showSnackbar(responseJson.error, CustomSnackbarType.Error);
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetAllNotificationsCallId) {
        this.handleGetAllNotifications(responseJson);
      }

      if (apiRequestCallId === this.apiUpdateAllNotificationsCallId) {
        this.handleUpdateAllNotificationReadStatus(responseJson);
      }

      if (apiRequestCallId === this.apiGetAllEmailIdsCallId) {
        this.handleGetAllEmailIds(responseJson);
      }

      if (apiRequestCallId === this.apiSendMessageCallId) {
        this.handleSendMessage(responseJson);
      }

      if (errorReponse) {
        this.setState({ loading: false }, () => {
          this.showSnackbar(
            "Error! Please try again!",
            CustomSnackbarType.Error
          );
        });
      }
    }
  }

  handleMarkAllAsRead = () => {
    this.updateAllNotificationReadStatus();
  };

  formatNotiticationCreatedTime = (time: string) => {
    moment.locale(this.props.i18n.language);
    return moment(time).fromNow(true);
  };

  showSnackbar = (message: string, type: CustomSnackbarType) => {
    this.setState({
      snackbar: {
        message,
        open: true,
        type,
      },
    });
  };

  hideSnackbar = () => {
    this.setState({
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
    });
  };

  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };
}

// Customizable Area End
