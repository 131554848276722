import {
  Checkbox,
  CheckboxProps,
  ThemeProvider,
  createTheme,
  withStyles,
  createStyles,
  WithStyles,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Box,
} from "@material-ui/core";
import React, { ReactNode } from "react";

interface Props extends WithStyles {
  checkboxLabel?: ReactNode;
  error?: string;
}

export type CustomCheckboxInputProps = Props & CheckboxProps;

const styles = createStyles({
  customCheckbox: {
    margin: "0 !important",
    alignItems: "flex-start !important",

    "& .MuiButtonBase-root": {
      padding: "0 !important",
    },
  },

  customCheckboxLabel: {
    marginLeft: 8,
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF6666",
    },

    secondary: {
      main: "#FFCC00",
    },
  },

  typography: {
    button: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "initial",
      fontFamily: "Inter",
    },
  },

  overrides: {
    MuiButton: {
      contained: {
        padding: "10px 16px",
        backgroundColor: "#333333",
        color: "#FFFFFF",
      },

      containedPrimary: {
        padding: "10px 16px",
        color: "#FFFFFF",
        borderRadius: 8,
      },

      outlined: {
        border: "1px solid #EDEDED",
        borderRadius: 8,
      },
    },
  },
});

export class CustomCheckboxInput extends React.Component<CustomCheckboxInputProps> {
  constructor(props: CustomCheckboxInputProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { checkboxLabel, name, error, classes, ...rest } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <FormControl error={!!error} component="fieldset">
          <FormControlLabel
            className={classes.customCheckbox}
            name={name}
            control={<Checkbox name={name} {...rest} />}
            label={
              <Box className={classes.customCheckboxLabel}>{checkboxLabel}</Box>
            }
          />
        </FormControl>

        {error && <FormHelperText>{error}</FormHelperText>}
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(CustomCheckboxInput);
