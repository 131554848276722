import React from "react";
// Customizable Area Start
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Theme,
  Toolbar,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core";
import { ExpandMore, Menu } from "@material-ui/icons";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  withRouter,
  matchPath,
} from "react-router-dom";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomTypography from "../../../components/src/CustomTypography.web";
import NavigationMenuKitchenController, {
  Props,
} from "./NavigationMenuKitchenController.web";
import clsx from "clsx";
import {
  logout,
  bgKitchenNav,
  notification,
  bgKitchenNavReverse,
  notificationActive,
  logoOnly,
} from "./assets";

import { WithTranslation, withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",

      "&.rtl": {
        flexDirection: "row-reverse",
      },
    },

    appBar: {
      width: "100%",

      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - 265px)`,
        marginLeft: 265,
      },

      "&.rtl": {
        [theme.breakpoints.up("sm")]: {
          marginLeft: 0,
          marginRight: 265,
        },
      },

      "&.MuiAppBar-colorDefault": {
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
      },
    },

    appBarHead: {
      position: "relative",
      height: 9,

      "&::before": {
        position: "absolute",
        content: '""',
        top: 0,
        left: 0,
        width: "50%",
        height: 9,
        backgroundColor: "#FF6666",
      },

      "&::after": {
        position: "absolute",
        content: '""',
        top: 0,
        right: 0,
        width: "50%",
        height: 9,
        backgroundColor: "#FFCC00",
      },
    },

    appBarHeadToolbar: {
      flexWrap: "wrap",
      gap: 8,

      [theme.breakpoints.up("lg")]: {
        paddingRight: 80,
      },

      [theme.breakpoints.down("xs")]: {
        paddingTop: 10,
        paddingBottom: 10,
      },

      "&.rtl": {
        direction: "rtl",

        [theme.breakpoints.up("lg")]: {
          paddingRight: 10,
          paddingLeft: 80,
        },
      },
    },

    pageName: {
      flex: 1,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },

    appBarTool: {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      gap: 15,

      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        order: 3,
        width: "100%",
        marginTop: 12,
      },
    },

    appBarInfo: {
      display: "flex",
      alignItems: "center",
      gap: 15,

      [theme.breakpoints.down("xs")]: {
        marginLeft: "auto",
        order: 2,
      },
    },

    appBarNotification: {
      backgroundColor: "#F8F8F8",
      border: "1px solid #EDEDED",
      width: 44,
      height: 44,

      "&[data-active='true']": {
        backgroundColor: "#F66",
        borderColor: "rgba(0, 0, 0, 0.08)",
      },
    },

    appBarNotificationBadge: {
      "& .MuiBadge-dot": {
        minWidth: 5,
        height: 5,
      },

      "& .MuiBadge-colorPrimary": {
        backgroundColor: "#2EBD6B",
      },
    },

    appBarAvatar: {
      backgroundColor: "#FF6666",
      width: 44,
      height: 44,
    },

    main: {
      flexGrow: 1,
      padding: theme.spacing(3),
      overflow: "hidden",

      [theme.breakpoints.up("sm")]: {
        width: "calc(100% - 265px)",
      },

      [theme.breakpoints.up("lg")]: {
        paddingRight: 80,
      },

      "&.rtl": {
        [theme.breakpoints.up("lg")]: {
          paddingRight: 10,
          paddingLeft: 80,
        },
      },
    },

    mainToolbar: {
      [theme.breakpoints.down("xs")]: {
        height: 125,
      },
    },

    outletsButton: {
      minWidth: 178,
      minHeight: 44,
    },

    languageSelect: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },

    outletsButtonContent: {
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
      width: "100%",
    },

    languageMenuItem: {
      display: "flex",
      alignItems: "center",

      "&.rtl": {
        flexDirection: "row-reverse",
      },
    },

    languageMenuItemFlag: {
      width: 24,
      height: 18,
      objectFit: "cover",
    },

    languageMenuItemName: {
      marginLeft: 8,
      fontSize: "12px !important",
      lineHeight: "18px !important",

      "&.rtl": {
        marginLeft: 0,
        marginRight: 8,
      },
    },

    drawerMobile: {
      [theme.breakpoints.up("sm")]: {
        width: 265,
        flexShrink: 0,
      },
    },

    drawerMobilePaper: {
      width: 265,
      backgroundColor: "#FFFBF4",
      minHeight: "100vh",

      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    drawerWeb: {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    drawerWebPaper: {
      boxSizing: "border-box",
      width: 265,
      backgroundColor: "#FFFBF4",
    },

    iconButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    nav: {
      [theme.breakpoints.up("sm")]: {
        width: 265,
        flexShrink: 0,
      },
    },

    navInner: {
      paddingTop: 20,

      "&.rtl": {
        direction: "rtl",
      },
    },

    restaurant: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      gap: 8,

      "&.rtl": {
        flexDirection: "row-reverse",
      },
    },

    restaurantLogo: {
      backgroundColor: "#D9D9D9",
      width: 50,
      height: 50,
      borderRadius: 8,
    },

    navTitle: {
      padding: "0 15px",
    },

    navItem: {
      padding: "8px 15px",
      display: "flex",
      alignItems: "center",
      gap: 8,

      "&.active": {
        color: "#FFFFFF",
        backgroundColor: "#FF6666",

        "& img": {
          filter: "grayscale(100%) brightness(500%)",
        },
      },

      "&.rtl": {
        textAlign: "right",
      },
    },

    navItemIcon: {
      width: 24,
      height: 24,
    },

    navItemName: {
      "& .MuiTypography-root": {
        fontFamily: "Raleway",
        fontSize: 14,
        lineHeight: "24px",
      },
    },

    navDivider: {
      height: 6,
      borderRadius: "initial",
    },

    navMenuBottom: {
      padding: 20,
      marginTop: 100,

      "&.rtl": {
        textAlign: "right",
      },
    },

    logoutBtn: {
      fontWeight: 600,
      fontFamily: "Raleway",
      fontSize: 14,
      lineHeight: "24px",

      "&.rtl": {
        direction: "rtl",
      },
    },

    logoutBtnLogo: {
      "&.rtl": {
        marginLeft: "8px !important",
        marginRight: "0 !important",
      },
    },

    navMenu: {
      background: `url(${bgKitchenNav})`,
      backgroundRepeat: "no-repeat",
      height: "100%",
      backgroundPositionY: "100%",
      backgroundSize: "contain",

      "&.rtl": {
        background: `url(${bgKitchenNavReverse})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "100%",
        backgroundSize: "contain",
        height: "100%",
      },
    },

    navMenuToolbar: {
      paddingLeft: 15,
      paddingRight: 15,
    },

    navLogo: {
      width: 40,
      height: 40,
    },

    navLogoHeading: {
      display: "flex",
      alignItems: "center",
    },
  });

const theme = createTheme({});

const ComingSoon = () => (
  <Box>
    <CustomTypography variant="h2">Coming soon !</CustomTypography>
  </Box>
);

export interface KitchenRoute {
  to: string;
  name: string;
  icon?: string;
  component?: React.ReactNode;
  child?: boolean;
}

class KitchenPageComponent extends React.PureComponent<any> {
  constructor(props: any) {
    super(props);
  }

  async componentWillMount() {
    await this.props.initKitchenPage();
  }

  async componentWillUnmount() {
    await this.props.setCurrentPage({
      to: "",
      name: "",
    });
  }

  kitchenNavigate = (to: string) => {
    const { routes } = this.props;

    const currentPage = routes.find((route: { to: string; name: string }) =>
      matchPath(to, {
        path: route.to,
        exact: true,
        strict: false,
      })
    );

    if (!currentPage) {
      return;
    }

    this.props.history.push(to);
  };

  render() {
    const { setCurrentPage, component: Component, id, navigation } = this.props;

    const newNavigation = {
      setCurrentPage,
      ...navigation,
      ...this.props,
      kitchenNavigate: this.kitchenNavigate,
    };

    return <Component navigation={newNavigation} id={id} />;
  }
}

const KitchenPage = withRouter(KitchenPageComponent);

interface NavMenuInnerComponentProps extends WithTranslation {
  title: string;
  classes: {
    [key: string]: string;
  };
  currentPage: { name: string; to: string };
  routes: KitchenRoute[];

  setCurrentPage(currentPage: { name: string; to: string }): void;
}

const NavMenuInnerComponent = (props: NavMenuInnerComponentProps) => {
  const rtl = props.i18n.language === "ar";

  return (
    <Box
      className={clsx(props.classes.navInner, {
        ["rtl"]: rtl,
      })}
    >
      <CustomTypography className={props.classes.navTitle} variant="h5">
        {props.title}
      </CustomTypography>

      <List>
        {props.routes.map((route) => {
          return (
            !route.child && (
              <ListItem
                className={clsx(props.classes.navItem, {
                  active: matchPath(props.currentPage.to, {
                    path: route.to,
                    exact: false,
                    strict: false,
                  }),
                  ["rtl"]: rtl,
                })}
                disableGutters
                button
                component={Link}
                to={route.to}
                key={route.to}
                onClick={() =>
                  props.setCurrentPage({
                    name: route.name,
                    to: route.to,
                  })
                }
                data-test-id={route.name}
              >
                <img
                  src={route.icon}
                  alt="image/svg+xml"
                  className={props.classes.navItemIcon}
                />

                <ListItemText
                  primary={props.t(route.name)}
                  className={props.classes.navItemName}
                />
              </ListItem>
            )
          );
        })}
      </List>
    </Box>
  );
};

const NavMenuInner = withTranslation()(NavMenuInnerComponent);

interface NavMenuComponentProps extends WithTranslation {
  classes: {
    [key: string]: string;
  };
  currentPage: { name: string; to: string };
  performanceMonitoringRoutes: KitchenRoute[];
  growYourBusinessRoutes: KitchenRoute[];
  manageYourBusinessRoutes: KitchenRoute[];
  userRoutes: KitchenRoute[];

  handleLogout(): void;
  setCurrentPage(currentPage: { name: string; to: string }): void;
}

const NavMenuComponent = (props: NavMenuComponentProps) => {
  const rtl = props.i18n.language === "ar";

  return (
    <Box className={clsx(props.classes.navMenu, { ["rtl"]: rtl })}>
      <Toolbar className={props.classes.navMenuToolbar}>
        <Box className={clsx(props.classes.restaurant, { ["rtl"]: rtl })}>
          <Box className={props.classes.navLogoHeading}>
            <img
              className={props.classes.navLogo}
              alt="pickup"
              src={logoOnly}
            />

            <CustomTypography variant="subtitle2">ZE PICKUP</CustomTypography>
          </Box>
        </Box>
      </Toolbar>

      <NavMenuInner
        data-test-id="performance-monitoring"
        classes={props.classes}
        title={props.t("Performance Monitoring")}
        routes={props.performanceMonitoringRoutes}
        setCurrentPage={props.setCurrentPage}
        currentPage={props.currentPage}
      />

      <Divider color="#EDEDED" className={props.classes.navDivider} />

      <NavMenuInner
        data-test-id="grow-your-business"
        classes={props.classes}
        title={props.t("Grow your business")}
        routes={props.growYourBusinessRoutes}
        setCurrentPage={props.setCurrentPage}
        currentPage={props.currentPage}
      />

      <Divider color="#EDEDED" className={props.classes.navDivider} />

      <NavMenuInner
        data-test-id="manage-your-business"
        classes={props.classes}
        title={props.t("Manage your business")}
        routes={props.manageYourBusinessRoutes}
        setCurrentPage={props.setCurrentPage}
        currentPage={props.currentPage}
      />

      <Box
        className={clsx(props.classes.navMenuBottom, {
          ["rtl"]: rtl,
        })}
      >
        <CustomButton
          className={clsx(props.classes.logoutBtn, { ["rtl"]: rtl })}
          color="secondary"
          variant="contained"
          startIcon={
            <img
              className={clsx(props.classes.logoutBtnLogo, { ["rtl"]: rtl })}
              src={logout}
              alt="logout"
            />
          }
          onClick={props.handleLogout}
        >
          {props.t("Logout")}
        </CustomButton>
      </Box>
    </Box>
  );
};

const NavMenu = withTranslation()(NavMenuComponent);
// Customizable Area End

export class NavigationMenuKitchen extends NavigationMenuKitchenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, navigation, t, i18n } = this.props;

    const { languages, selectedLanguage, mobileOpen, currentPage } = this.state;

    const rtl = i18n.language === "ar";

    return (
      <Router>
        <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, { ["rtl"]: rtl })}
            color="default"
          >
            <Box className={classes.appBarHead} />
            <Toolbar
              className={clsx(classes.appBarHeadToolbar, { ["rtl"]: rtl })}
            >
              <IconButton
                className={classes.iconButton}
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
              >
                <Menu />
              </IconButton>

              <CustomTypography className={classes.pageName} variant="h2">
                {t(currentPage.name)}
              </CustomTypography>

              <Box className={classes.appBarTool}>
                <CustomButton
                  dir="ltr"
                  variant="outlined"
                  className={classes.outletsButton}
                >
                  <Box className={classes.outletsButtonContent}>
                    <CustomTypography variant="body2">
                      {t("Outlets")}
                    </CustomTypography>
                    <CustomTypography variant="h5">{`${t(
                      "All"
                    )} (7/7)`}</CustomTypography>
                  </Box>

                  <ExpandMore />
                </CustomButton>

                <CustomSelect
                  data-test-id="language-select"
                  className={classes.languageSelect}
                  variant="outlined"
                  value={selectedLanguage}
                  onChange={this.changeLanguage}
                >
                  {languages.map((language) => (
                    <MenuItem
                      className={clsx(classes.languageMenuItem, {
                        ["rtl"]: rtl,
                      })}
                      value={language.value}
                      key={language.value}
                    >
                      <img
                        className={classes.languageMenuItemFlag}
                        src={language.flag}
                        alt={language.name}
                      />
                      <CustomTypography
                        variant="subtitle1"
                        className={clsx(classes.languageMenuItemName, {
                          ["rtl"]: rtl,
                        })}
                      >
                        {t(language.name)}
                      </CustomTypography>
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Box>

              <Box className={classes.appBarInfo}>
                <Link
                  to="/kitchen/admin/notifications-all"
                  style={{ textDecoration: "none" }}
                >
                  <IconButton
                    className={classes.appBarNotification}
                    data-active={this.checkIfNotificationPageActive()}
                    onClick={this.setCurrentPage.bind(this, {
                      name: "Notifications All",
                      to: "/kitchen/admin/notifications-all",
                    })}
                  >
                    <Badge
                      className={classes.appBarNotificationBadge}
                      color="primary"
                      variant="dot"
                      overlap="circular"
                    >
                      {this.checkIfNotificationPageActive() ? (
                        <img
                          src={notificationActive}
                          alt="notification active"
                        />
                      ) : (
                        <img src={notification} alt="notification" />
                      )}
                    </Badge>
                  </IconButton>
                </Link>
                <Link
                  to="/kitchen/owner/profile"
                  onClick={this.setCurrentPage.bind(this, {
                    name: "Profile",
                    to: "/kitchen/owner/profile",
                  })}
                  style={{ textDecoration: "none" }}
                >
                  <Avatar className={classes.appBarAvatar}>CS</Avatar>
                </Link>
              </Box>
            </Toolbar>
          </AppBar>

          <Box component="nav" className={classes.nav}>
            <Drawer
              anchor={rtl ? "right" : "left"}
              className={classes.drawerMobile}
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={this.handleDrawerTransitionEnd}
              ModalProps={{
                keepMounted: true,
              }}
              classes={{
                paper: classes.drawerMobilePaper,
              }}
              onClose={this.handleDrawerClose}
            >
              <NavMenu
                data-test-id="drawer-mobile"
                classes={classes}
                performanceMonitoringRoutes={this.performanceMonitoringRoutes}
                growYourBusinessRoutes={this.growYourBusinessRoutes}
                manageYourBusinessRoutes={this.manageYourBusinessRoutes}
                userRoutes={this.userRoutes}
                currentPage={currentPage}
                setCurrentPage={this.setCurrentPage}
                handleLogout={this.handleLogout}
              />
            </Drawer>

            <Drawer
              anchor={rtl ? "right" : "left"}
              variant="permanent"
              className={classes.drawerWeb}
              classes={{
                paper: classes.drawerWebPaper,
              }}
              open
            >
              <NavMenu
                data-test-id="drawer-web"
                classes={classes}
                performanceMonitoringRoutes={this.performanceMonitoringRoutes}
                growYourBusinessRoutes={this.growYourBusinessRoutes}
                manageYourBusinessRoutes={this.manageYourBusinessRoutes}
                userRoutes={this.userRoutes}
                currentPage={currentPage}
                setCurrentPage={this.setCurrentPage}
                handleLogout={this.handleLogout}
              />
            </Drawer>
          </Box>

          <Box
            className={clsx(classes.main, {
              ["rtl"]: rtl,
            })}
            component="main"
          >
            <Toolbar className={classes.mainToolbar} />
            <Switch>
              {this.allRoutes.map((route) => (
                <Route path={route.to} key={route.to} exact={route.exact}>
                  {route.component ? (
                    <KitchenPage
                      data-test-id={route.name}
                      id={route.name}
                      component={route.component}
                      setCurrentPage={this.setCurrentPage}
                      initKitchenPage={this.initKitchenPage}
                      navigation={navigation}
                      routes={this.allRoutes}
                    />
                  ) : (
                    <ComingSoon data-test-id={route.name} />
                  )}
                </Route>
              ))}

              <Redirect from="/kitchen/owner*" to="/kitchen/owner/dashboard" />
            </Switch>
          </Box>
        </Box>
      </Router>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(withTranslation()(NavigationMenuKitchen));
// Customizable Area End
