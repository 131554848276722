// Customizable Area Start
import React from "react";

import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

import ReviewsRatingAndReviewsController, {
  Props,
} from "./ReviewsRatingAndReviewsController.web";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import StarRoundedIcon from "@material-ui/icons/StarRounded";

import moment from "moment";

import CustomPagination from "../../../components/src/CustomPagination";
import CustomSnackbarWeb from "../../../components/src/CustomSnackbar.web";
import CustomTable from "../../../components/src/CustomTable";
import CustomLoadingBackdropWeb from "../../../components/src/CustomLoadingBackdrop.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomMenuItemWeb from "../../../components/src/CustomMenuItem.web";
import CustomSwitchWithTextWeb from "../../../components/src/CustomSwitchWithText.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomSwitchWeb from "../../../components/src/CustomSwitch.web";
import CustomInputFieldWeb from "../../../components/src/CustomInputField.web";
import CustomReviewsFormDrawerWeb from "../../../components/src/reviews/CustomReviewsFormDrawer.web";
import CustomReviewsContent from "../../../components/src/reviews/CustomReviewsContent";
import CustomDatePickerWeb from "../../../components/src/CustomDatePicker.web";

import { iconCalendar } from "./assets";

const styles = (theme: Theme) =>
  createStyles({
    data: {
      marginTop: 24,
    },

    actions: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      gap: 8,

      "& .actions__select-date": {
        maxWidth: 180,

        "& input::placeholder": {
          color: "#333333",
          opacity: 1,
        },
      },
    },

    ratingSummary: {
      marginRight: "auto",
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: "1.375rem",
        fontWeight: 700,
        lineHeight: 1.174,
        paddingInlineEnd: "0.27em",
      },
      "& .rating-summary__star": {
        fontSize: "2rem",
      },
      "& .rating-summary__value": {
        fontSize: "1.375rem",
        fontFamily: "Inter",
        fontWeight: 700,
        color: "#F59E0B",
        lineHeight: 1.21,
      },
    },

    filter: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      flexWrap: "wrap",
    },

    filterSelect: {
      width: 180,
    },

    downloadButton: {
      marginLeft: "auto",
    },

    tableContainer: {
      marginTop: 24,
    },

    table: {
      minWidth: 1280,
      "& td": {
        verticalAlign: "baseline",
      },
      "& .table__cell__review": {
        width: "47%",
      },
      "& .table__cell__status": {
        width: 160,
      },
      "& .table__cell__date": {
        whiteSpace: "pre",
        fontFamily: "Inter",
      },

      "& .table__cell_empty": {
        textAlign: "center",
        lineHeight: 4,

        [theme.breakpoints.down("md")]: {
          textAlign: "left",
        },
      },
    },

    pagination: {
      display: "flex",
      justifyContent: "end",
      marginTop: 24,
    },

    switch: {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    switchLabel: {
      fontWeight: 600,
    },
  });

export class ReviewsRatingAndReviews extends ReviewsRatingAndReviewsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {
      pagination,
      snackbar,
      reviews,
      loading,
      filterRating,
      filterRatingList,
      date,
      drawerData,
      loadingReply,
    } = this.state;

    return (
      <>
        <div className={classes.data}>
          <CustomLoadingBackdropWeb open={loading} />

          <div className={classes.actions}>
            <div className={classes.ratingSummary}>
              <CustomTypography>
                Average Customer&apos;s Ratings
              </CustomTypography>
              <StarRoundedIcon
                htmlColor="#F59E0B"
                className="rating-summary__star"
              />
              <span className="rating-summary__value">
                {this.state.ratingAvg.toFixed(1)}
              </span>
            </div>

            <div className="actions__select-date" id="selectDate">
              <CustomDatePickerWeb
                onChange={(date) => {
                  this.changeDate(date);
                }}
                portalId="selectDate"
                name="from"
                selected={date ? new Date(date) : null}
                dateFormat={"dd/MM/yyyy"}
                customInput={
                  <CustomInputFieldWeb
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      placeholder: "Select Date",
                      endAdornment: <img src={iconCalendar} />,
                    }}
                  />
                }
              />
            </div>

            <CustomSelect
              id="select-rating"
              data-test-id="select-rating"
              className={classes.filterSelect}
              variant="outlined"
              value={filterRating}
              placeholder="Rating level"
              onChange={(event) =>
                this.changeFilterRating(event.target.value as string)
              }
            >
              <CustomMenuItemWeb
                className={classes.languageMenuItem}
                value={"-1"}
                disabled
              >
                Rating Level
              </CustomMenuItemWeb>
              {filterRatingList.map((option) => (
                <CustomMenuItemWeb
                  className={classes.languageMenuItem}
                  value={option.value}
                  key={option.id}
                >
                  {option.name}
                </CustomMenuItemWeb>
              ))}
            </CustomSelect>

            <div className={classes.switch}>
              <CustomSwitchWeb
                checked={this.getShowAll()}
                onChange={this.toggleShowAll}
              />

              <CustomTypography className={classes.switchLabel}>
                Show All
              </CustomTypography>
            </div>
          </div>

          <TableContainer className={classes.tableContainer}>
            <CustomTable className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className="table__cell__review">
                    Reviews and Ratings
                  </TableCell>
                  <TableCell className="table__cell__status">Status</TableCell>
                  <TableCell className="table__cell__date">Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {reviews.length > 0 ? (
                  reviews.map((review) => (
                    <TableRow key={review.id}>
                      <TableCell className="table__cell__review">
                        <CustomReviewsContent
                          title={review.attributes.title}
                          description={review.attributes.comment}
                          rating={review.attributes.rating}
                        />
                      </TableCell>
                      <TableCell className="table__cell__status">
                        <CustomSwitchWithTextWeb
                          checkedText="Active"
                          uncheckedText="Inactive"
                          checked={review.attributes.status === "active"}
                          onChange={(event, checked) => {
                            this.updateReviewStatus(checked, review.id);
                          }}
                        />
                      </TableCell>
                      <TableCell className="table__cell__date">
                        {moment(review.attributes.created_at).format(
                          "DD/MM/YYYY[\n]hh:mma"
                        )}
                      </TableCell>
                      <TableCell>
                        <CustomButton
                          variant="text"
                          color="primary"
                          onClick={() => this.openDrawer(review)}
                        >
                          Reply User
                        </CustomButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} className="table__cell_empty">
                      No reviews found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <div className={classes.pagination}>
            <CustomPagination
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(event, page) => this.changePage(page)}
              showOffset
            />
          </div>
        </div>

        <CustomSnackbarWeb
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbar.open}
          type={snackbar.type}
          message={snackbar.message}
          onClose={this.closeSnackbar}
        />

        <CustomReviewsFormDrawerWeb
          onClose={this.closeDrawer}
          onSubmit={this.handleSubmit}
          open={drawerData !== null}
          loading={loadingReply}
          userReply={
            drawerData
              ? {
                  title: drawerData.attributes.title,
                  description: drawerData.attributes.comment,
                  rating: drawerData.attributes.rating,
                }
              : null
          }
        />
      </>
    );
  }
}

export default withStyles(styles)(ReviewsRatingAndReviews);
// Customizable Area End
