import {
  Box,
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core";
import { Pagination, PaginationProps } from "@material-ui/lab";
import clsx from "clsx";
import React from "react";
import CustomTypography from "./CustomTypography.web";
import { WithTranslation, withTranslation } from "react-i18next";

const theme = createTheme({});

const styles = createStyles({
  customPagination: {
    display: "flex",
    alignItems: "center",

    "&.full-width": {
      "& .custom-pagination": {
        marginLeft: "auto",
      },

      "& .custom-offset": {
        marginLeft: "auto",
      },
    },
  },

  customPaginationOffset: {
    display: "flex",
    alignItems: "center",
    marginLeft: 17,
  },

  customPaginationOffsetText: {
    fontSize: "12px !important",
    lineHeight: "18px !important",
  },

  container: {
    "& .MuiPaginationItem-root": {
      fontFamily: "Inter",
      fontSize: 12,
      lineHeight: "18px",
    },

    "&.rtl": {
      direction: "rtl",

      "& .MuiPaginationItem-icon": {
        transform: "scaleX(-1)",
      },
    },

    "&.is-text": {
      "& button": {
        minWidth: 16,
        textDecoration: "underline",

        "&:hover": {
          backgroundColor: "initial",
          color: "#FF6666",
        },
      },

      "& .Mui-selected": {
        backgroundColor: "initial",
        color: "#FF6666",
        fontWeight: 700,
        textDecoration: "initial",
      },
    },
  },
});

export interface Props extends WithStyles, WithTranslation {
  limit?: number;
  total?: number;
  showOffset?: boolean;
  fullWidth?: boolean;
}

export type CustomPaginationProps = PaginationProps & Props;

export class CustomPagination extends React.Component<CustomPaginationProps> {
  constructor(props: CustomPaginationProps) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      classes,
      variant,
      className,
      page = 1,
      limit = 0,
      total = 0,
      showOffset,
      fullWidth,
      dir,
      t,
      i18n,
      ...rest
    } = this.props;

    const startResult = (page - 1) * limit + 1;
    const endResult = Math.min(page * limit, total);
    const rtl = i18n.language === "ar";

    return (
      <ThemeProvider theme={theme}>
        <Box
          className={clsx(classes.customPagination, {
            "full-width": fullWidth,
          })}
        >
          <Pagination
            className={clsx(
              classes.container,
              className,
              {
                "is-text": variant === "text",
                ["rtl"]: rtl,
              },
              "custom-pagination"
            )}
            variant={variant}
            page={page}
            dir={dir}
            {...rest}
          />

          {showOffset && (
            <Box
              className={clsx(classes.customPaginationOffset, "custom-offset")}
            >
              <CustomTypography
                variant="body2"
                className={classes.customPaginationOffsetText}
              >
                {t("{{startResult}} - {{endResult}} of {{total}} results", {
                  startResult,
                  endResult,
                  total,
                })}
              </CustomTypography>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(withTranslation()(CustomPagination));
