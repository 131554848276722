// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import { CustomFilterMenu } from "../../../components/src/CustomFilter.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";

enum TABS {
  SALES = 0,
  ORDERS = 1,
  CUSTOMERS = 2,
}

export const configJSON = require("./config");

export interface Props {}

export interface ChartData {
  XValue: string;
  YValue: number;
}

interface S {
  currentHoldValue: number;
  loading: boolean;
  tabActive: TABS;
  tabs: { value: TABS; name: string }[];
  filterTime: string;
  filterTimeList: CustomFilterMenu[];
  saleChartData: {
    data: ChartData[];
    totalRevenue: number;
    totalRevenueByCash: number;
    totalRevenueByOnline: number;
  };

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
}

interface SS {
  id: any;
}

export default class AnalyticsAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchSaleChartDataApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      loading: false,

      tabActive: TABS.SALES,

      currentHoldValue: 0,

      tabs: [
        { value: TABS.SALES, name: "Sales" },
        { value: TABS.ORDERS, name: "Orders" },
        { value: TABS.CUSTOMERS, name: "Customers" },
      ],

      filterTime: "monthly",

      filterTimeList: [
        { id: "0", name: "Weekly", value: "weekly" },
        { id: "1", name: "Monthly", value: "monthly" },
        { id: "2", name: "Yearly", value: "yearly" },
      ],

      saleChartData: {
        data: [],
        totalRevenue: 0,
        totalRevenueByCash: 0,
        totalRevenueByOnline: 0,
      },

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.fetchSaleChartData();
  }

  createAdminReportMessage = async (data: {
    url: string;
    method: string;
  }): Promise<Message> => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const adminMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    adminMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data.url
    );

    adminMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    adminMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      data.method
    );

    return adminMessage;
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.fetchSaleChartDataApiId) {
      this.handleFetchSaleChartData(responseJson);
    }
  }

  changeActiveTab = (tab: TABS) => {
    this.setState({ tabActive: tab });
  };

  getActiveTabName = () => {
    switch (this.state.tabActive) {
      case TABS.ORDERS:
        return "Orders";

      case TABS.CUSTOMERS:
        return "Customers";

      case TABS.SALES:
        return "Sales";
    }
  };

  fetchSaleChartData = async () => {
    const message = await this.createAdminReportMessage({
      method: "GET",
      url: `/bx_block_statisticsreports2/super_admin_report?type=${this.state.filterTime}`,
    });

    this.fetchSaleChartDataApiId = message.messageId;

    runEngine.sendMessage(message.messageId, message);

    this.setState({
      loading: true,
    });
  };

  getXValue = () => {
    switch (this.state.filterTime) {
      case "weekly":
        return "day";

      case "monthly":
        return "month";

      case "yearly":
        return "year";

      default:
        return "";
    }
  };

  handleFetchSaleChartData = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const {
      report_data,
      total_revenue,
      total_revenue_by_cash,
      total_revenue_by_online,
    } = responseJson.report;

    const data = report_data.map((item: any) => ({
      XValue: item[this.getXValue()],
      YValue: Number(item.revenue),
    }));

    this.setState({
      saleChartData: {
        data,
        totalRevenue: total_revenue,
        totalRevenueByCash: total_revenue_by_cash,
        totalRevenueByOnline: total_revenue_by_online,
      },
    });
  };

  changeFilter = (filterTime: string) => {
    this.setState(
      {
        filterTime,
      },
      () => {
        this.fetchSaleChartData();
      }
    );
  };

  setCurrentHoldValue = (currentHoldValue: number) => {
    this.setState({
      currentHoldValue,
    });
  };
}

// Customizable Area End
