import {
  Box,
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import CustomButton from "./CustomButton.web";
import CustomTypography from "./CustomTypography.web";
import KitchenDrawer from "./KitchenDrawer.web";
import KitchenOrderChangeAccordion, {
  KitchenOrderCatalogue,
  KitchenOrderChangeCategory,
} from "./KitchenOrderChangeAccordion.web";
import { KitchenOrder } from "./KitchenOrderTableRow.web";

export interface Props extends WithStyles<any> {
  open: boolean;
  selectedOrder: KitchenOrder;
  categories: KitchenOrderChangeCategory[];
  loading: boolean;

  onClose(): void;
  onBack(): void;
  addCatalogue(catalogue: KitchenOrderCatalogue): void;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {},

    title: {
      fontFamily: "Inter !important",
      lineHeight: "22px",
    },

    content: {
      marginTop: 13,
    },

    group: {
      "& + &": {
        marginTop: 27,
      },
    },

    declinedTitle: {
      color: "#DC2626",
      fontSize: "12px !important",
      lineHeight: "24px !important",
    },

    acceptedTitle: {
      color: "#059669",
      fontSize: "12px !important",
      lineHeight: "24px !important",
    },

    list: {
      marginTop: 10,
    },

    drawerBottom: {
      marginTop: "auto",
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },

    loading: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
    },
  });

export class KitchenOrderChangeDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      classes,
      open,
      selectedOrder,
      categories,
      loading,

      addCatalogue,
      onClose,
      onBack,
    } = this.props;

    return (
      <KitchenDrawer
        open={open}
        title="Change Item"
        onClose={onClose}
        width={568}
        body={
          <Box className={classes.container}>
            <CustomTypography
              variant="body1"
              className={classes.title}
            >{`Order id: ${selectedOrder.orderId} on date of ${moment(
              selectedOrder.time
            ).format("DD/MM/yyyy")} at ${moment(selectedOrder.time).format(
              "h:mmA"
            )}`}</CustomTypography>

            <Box className={classes.content}>
              {loading ? (
                <Box className={classes.loading}>
                  <CircularProgress color="inherit" />
                </Box>
              ) : (
                categories.map((category) => (
                  <KitchenOrderChangeAccordion
                    data-test-id={`category-${category.id}`}
                    category={category}
                    key={category.id}
                    addCatalogue={addCatalogue}
                  />
                ))
              )}
            </Box>
          </Box>
        }
        bottom={
          <Box className={classes.drawerBottom}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onBack}
              data-test-id="back-btn"
            >
              Back to Order Summary
            </CustomButton>

            <CustomButton color="primary" variant="contained" type="submit">
              Notify User
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(KitchenOrderChangeDrawer);
