import {
  MenuItem,
  MenuItemProps,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";

export interface Props extends WithStyles<any> {}

export type CustomMenuItemProps = Props & MenuItemProps;

const styles = () =>
  createStyles({
    customMenuItem: {},
  });

export class CustomSelect extends React.Component<CustomMenuItemProps> {
  constructor(props: CustomMenuItemProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, children, ...rest } = this.props;

    return (
      <MenuItem {...(rest as any)} className={classes.customMenuItem}>
        {children}
      </MenuItem>
    );
  }
}

export default withStyles(styles)(CustomSelect);
