// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../../components/src/languages/locales/en.json';
import arTranslation from '../../components/src/languages/locales/ar.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    ar: {
      translation: arTranslation,
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  debug: true,

  interpolation: {
    escapeValue: false,
  },
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
