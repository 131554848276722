import { Box, InputLabel, createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ErrorMessage, Field } from "formik";
import React from "react";
import CustomTypography from "./CustomTypography.web";
import CustomDatePicker, {
  CustomDatePickerProps,
} from "./CustomDatePicker.web";
import CustomInputField, {
  CustomInputFieldProps,
} from "./CustomInputField.web";
import { DateRange } from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment";

export interface Props extends WithStyles {
  subLabel?: string;
  label?: string;
  error?: boolean;
  required: boolean;
  value: Date | null;
}

export type CustomFormDatePickerFieldProps = Partial<
  Omit<CustomDatePickerProps, "value">
> &
  Omit<CustomInputFieldProps, "value"> &
  Props;

const styles = createStyles({
  container: {
    "& + &": {
      marginTop: 24,
    },

    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#DC2626",
      fontWeight: 400,
      lineHeight: "18px",
      fontSize: 12,
      fontFamily: "Inter",
    },
  },

  label: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "22px",
    color: "#333333",
    fontFamily: "Raleway",
    position: "relative",
    display: "inline-block",

    "&.is-required": {
      "&::after": {
        content: "'*'",
        position: "absolute",
        top: 0,
        right: -8,
        color: "#DC2626",
      },
    },
  },

  subLabel: {
    display: "inline-block",
    fontSize: "12px !important",
    marginLeft: 8,
  },
});

const CustomDatePickerInput = (props: any) => {
  return (
    <CustomDatePicker
      dateFormat={props.dateFormat}
      onChange={(date, e) => {
        props.onChange({
          ...e,
          target: {
            ...e?.target,
            name: props.name,
            value: date,
          },
        });
      }}
      disabled={props.disabled}
      name={props.name}
      selected={props.selected}
      placeholderText={props.placeholder}
      customInput={
        <CustomInputField
          InputProps={{
            endAdornment: <DateRange />,
          }}
          {...props}
        />
      }
    />
  );
};

export class CustomFormDatePickerField extends React.Component<CustomFormDatePickerFieldProps> {
  constructor(props: CustomFormDatePickerFieldProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { name, label, classes, error, subLabel, value, required, ...rest } =
      this.props;

    return (
      <Box className={classes?.container}>
        <InputLabel
          className={clsx(classes.label, {
            ["is-required"]: required,
          })}
          htmlFor={name}
        >
          {label}

          {subLabel && (
            <CustomTypography className={classes.subLabel}>
              {subLabel}
            </CustomTypography>
          )}
        </InputLabel>

        <Field
          className={classes.input}
          as={CustomDatePickerInput}
          name={name}
          error={error}
          helperText={error && <ErrorMessage name={name} />}
          selected={value ? moment(value).toDate() : null}
          {...rest}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(CustomFormDatePickerField);
