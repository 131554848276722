Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"

exports.endPointSendMessage = "/bx_block_notifications/notifications/email_sending";
exports.endPointGetAllEmailIds = "/bx_block_notifications/notifications/kitchen_account_emails";

exports.endPointUpdateAllNotificationReadStatus = "/bx_block_notifications/update_all_as_read";

exports.endPointGetAllNotifications = "/bx_block_notifications/notifications?page=1&per_page=999";
// Customizable Area End