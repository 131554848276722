import {
  Box,
  CircularProgress,
  Paper,
  Theme,
  Tooltip,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import CustomButton from "../CustomButton.web";
import KitchenDrawer from "../KitchenDrawer.web";
import CustomTypographyWeb from "../CustomTypography.web";
import CustomFormCheckBox from "../../../components/src/CustomFormCheckBox.web";
import { AccessTimeRounded, InfoRounded } from "@material-ui/icons";
import { StringSchema, array, object, string } from "yup";

interface Props extends WithStyles<any> {
  open: boolean;
  loading: boolean;
  initialDays: string[];
  initialStartTime: string | undefined;
  initialEndTime: string | undefined;
  onClose(): void;
  onSubmit(values: any): void;
}

function FormInputTimeList({
  timeList,
  onSelect,
}: {
  timeList: string[];
  onSelect: (time: string) => void;
}) {
  return (
    <ul className="form__input__timeList">
      {timeList.map((time, index) => {
        return (
          <li key={index}>
            <span>{time}</span>
            <div>
              <span
                role="button"
                className="selected"
                onClick={onSelect.bind(undefined, time + " AM")}
              >
                AM
              </span>{" "}
              <span
                role="button"
                onClick={onSelect.bind(undefined, time + " PM")}
              >
                PM
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

function convertTime(time: string | undefined) {
  let result = "";

  if (!time) {
    return result;
  }

  const period = time.slice(-2);
  const hours = parseInt(time.slice(0, 2));

  switch (time) {
    case "12:00 AM":
      result = "00:00";
      break;
    case "12:00 PM":
      result = "12:00";
      break;
    case "12:30 AM":
      result = "00:30";
      break;
    case "12:30 PM":
      result = "12:30";
      break;
    default:
      result = `${period === "PM" ? hours + 12 : hours}:${time.slice(3, 5)}`;
  }

  return result;
}

const schema = object({
  days: array().of(string()).default([]),
  start_time: string().when("days", {
    is: (days) => days?.length > 0,
    then: (_schema: StringSchema) => _schema.required("Start time is required"),
    otherwise: (_schema: StringSchema) => _schema.notRequired(),
  }),
  end_time: string().when("days", {
    is: (days) => days?.length > 0,
    then: (_schema: StringSchema) => _schema.required("End time is required"),
    otherwise: (_schema: StringSchema) => _schema.notRequired(),
  }),
});

export class CustomServicespecificsettingsadmin2OpeningTimesDrawer extends React.Component<Props> {
  formCheckboxes = new Map([
    ["sunday", false],
    ["monday", false],
    ["tuesday", false],
    ["wednesday", false],
    ["thursday", false],
    ["friday", false],
    ["saturday", false],
  ]);

  constructor(props: Props) {
    super(props);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      JSON.stringify(prevProps.initialDays.sort()) !==
      JSON.stringify(this.props.initialDays.sort())
    ) {
      this.props.initialDays.forEach((day) =>
        this.formCheckboxes.set(day, true)
      );
    }
  }

  getTimeOfDayList = () => {
    let list = [];

    for (let i = 0; i <= 12; i++) {
      const date1 = `${i}:00`.padStart(4, "0");
      const date2 = `${i}:30`.padStart(4, "0");

      list.push(date1, date2);
    }

    return list;
  };

  handleSubmit = (values: any) => {
    const formattedValues = {
      days: values.days.filter(Boolean),
      start_time: convertTime(values.start_time),
      end_time: convertTime(values.end_time),
    };

    this.props.onSubmit(formattedValues);
  };

  render(): React.ReactNode {
    const {
      classes,
      open,
      loading,
      onClose,
      initialDays,
      initialEndTime,
      initialStartTime,
    } = this.props;

    return (
      <KitchenDrawer
        open={open}
        title="Special Schedule"
        onClose={onClose}
        bottom={
          <Box className={classes.formBottom}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-testId="cancel-btn"
            >
              Cancel
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              form="formAddSpecialSchedule"
            >
              {loading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                "Apply"
              )}
            </CustomButton>
          </Box>
        }
        body={
          <Formik
            initialValues={{
              ...schema.default(),
              days: initialDays,
              start_time: initialStartTime,
              end_time: initialEndTime,
            }}
            onSubmit={this.handleSubmit}
            data-testid="formikAddSpecialSchedule"
            validationSchema={schema}
          >
            {(props) => {
              return (
                <Form
                  id="formAddSpecialSchedule"
                  data-testid="formAddSpecialSchedule"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <div className={classes.formInner}>
                    <p className="form__input__title">
                      Select times
                      <LightTooltip
                        title={
                          <CustomTypographyWeb style={{ fontFamily: "Inter" }}>
                            12:00 AM is 00:00 in 24-hours format
                            <br />
                            12:30 AM is 00:30 in 24-hours format
                            <br />
                            12:00 PM is 12:00 in 24-hours format
                            <br />
                            12:30 PM is 12:30 in 24-hours format
                          </CustomTypographyWeb>
                        }
                      >
                        <InfoRounded fontSize="inherit" />
                      </LightTooltip>
                    </p>
                    <div className="form__input__times">
                      <div>
                        <div className="form__input__time">
                          <CustomTypographyWeb>
                            {props.values.start_time
                              ? props.values.start_time
                              : "Open time"}
                          </CustomTypographyWeb>
                          <AccessTimeRounded />
                          <FormInputTimeList
                            timeList={this.getTimeOfDayList()}
                            onSelect={(time: string) => {
                              props.setFieldValue("start_time", time);
                            }}
                          />
                        </div>
                        {props.touched.start_time &&
                          props.errors.start_time && (
                            <CustomTypographyWeb className="form__input__error">
                              {props.errors.start_time}
                            </CustomTypographyWeb>
                          )}
                      </div>

                      <div>
                        <div className="form__input__time">
                          <CustomTypographyWeb>
                            {props.values.end_time
                              ? props.values.end_time
                              : "Close time"}
                          </CustomTypographyWeb>
                          <AccessTimeRounded />
                          <FormInputTimeList
                            timeList={this.getTimeOfDayList()}
                            onSelect={(time: string) => {
                              props.setFieldValue("end_time", time);
                            }}
                          />
                        </div>
                        {props.touched.end_time && props.errors.end_time && (
                          <CustomTypographyWeb className="form__input__error">
                            {props.errors.end_time}
                          </CustomTypographyWeb>
                        )}
                      </div>
                    </div>
                    <div className="form__input__checkboxes">
                      <p className="form__input__title">
                        Select day
                        <LightTooltip
                          title={
                            <CustomTypographyWeb>
                              Selected day will become special schedule. If day
                              isn't selected, it will become regular schedule.
                            </CustomTypographyWeb>
                          }
                        >
                          <InfoRounded fontSize="inherit" />
                        </LightTooltip>
                      </p>

                      {props.touched.days && props.errors.days && (
                        <CustomTypographyWeb className="form__input__error">
                          {props.errors.days}
                        </CustomTypographyWeb>
                      )}
                      <div className="form__input__checkboxesList">
                        {Array.from(this.formCheckboxes.keys()).map((day) => {
                          return (
                            <CustomFormCheckBox
                              key={day}
                              value={day}
                              checked={props.values.days.includes(day)}
                              label={
                                <CustomTypographyWeb className="form__input__checkboxLabel">
                                  {day}
                                </CustomTypographyWeb>
                              }
                              onChange={(event, checked) => {
                                this.formCheckboxes.set(day, checked);
                                const updated = Array.from(
                                  this.formCheckboxes.keys()
                                ).filter((_key) =>
                                  this.formCheckboxes.get(_key)
                                );
                                props.setFieldValue("days", updated);
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
      marginBlock: -16,

      [theme.breakpoints.down("lg")]: {
        paddingBlockStart: "1.5rem",
      },

      "& .form__input__title": {
        fontFamily: "Raleway",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 18.78 / 16,
        fontWeight: 600,
        color: "#333",
        marginBlock: "18px 5px",
      },

      "& .form__input__checkboxLabel": {
        fontFamily: "Raleway",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 24 / 16,
        color: "#0F172A",
        textTransform: "capitalize",
      },

      "& .form__input__checkboxesList": {
        display: "flex",
        flexDirection: "column",
        gap: 10,
      },

      "& .form__input__times": {
        display: "flex",
        alignItems: "center",
        gap: 10,
        "& div:has(.form__input__time)": {
          flex: 1,
        },
        "& div:has(.form__input__error) .form__input__time": {
          borderColor: "#DC2626",
        },
        "& .form__input__time": {
          padding: "10px 8px",
          border: "1px solid #CBD5E1",
          borderRadius: 8,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          "&:hover .form__input__timeList": {
            display: "block",
          },
          "& p": {
            fontFamily: "Inter",
          },
        },
        "& .form__input__timeList": {
          display: "none",
          position: "absolute",
          backgroundColor: "#fff",
          top: "100%",
          left: 0,
          right: 0,
          height: 135,
          overflow: "auto",
          zIndex: 1,
          borderRadius: "0px 0px 8px 8px",
          border: "inherit",
          boderTopWidth: 0,
          marginBlock: 0,
          listStyle: "none",
          padding: 12,
          "& li": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "&:hover > span": {
              color: "#F66",
              fontWeight: 700,
            },
            "&  span": {
              fontFamily: "Inter",
              fontSize: theme.typography.pxToRem(16),
              lineHeight: 22 / 16,
              transition: "all 200ms ease",
            },
            "& > div": {
              display: "none",
              "& > [role='button']": {
                cursor: "pointer",
                transition: "all 200ms ease",
                "&.selected": {
                  color: "#F66",
                  fontWeight: 700,
                },
                "&:hover": {
                  color: "#FF6666d8",
                  fontWeight: 700,
                },
              },
            },
            "&:hover > div": {
              display: "block",
            },
          },
        },
      },
      "& .form__input__error": {
        marginBlockStart: 5,
        color: "#DC2626",
        fontSize: theme.typography.pxToRem(12),
        fontFamily: "Inter",
        lineHeight: 18 / 12,
      },
      "& .portal": {
        flex: 1,
        position: "relative",
      },
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },
  });

export default withStyles(styles)(
  CustomServicespecificsettingsadmin2OpeningTimesDrawer
);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#333",
    boxShadow: theme.shadows[1],
    fontFamily: "Raleway",
    padding: theme.spacing(1.5),
  },
}))(Tooltip);
