import {
  Backdrop,
  CircularProgress,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import * as React from "react";

const styles = () =>
  createStyles({
    container: {
      zIndex: 99999,
    },
  });

export interface CustomLoadingBackdropProps extends WithStyles<any> {
  open: boolean;
}

class CustomLoadingBackdrop extends React.Component<CustomLoadingBackdropProps> {
  constructor(props: CustomLoadingBackdropProps) {
    super(props);
  }

  render() {
    const { open, classes } = this.props;

    return (
      <Backdrop open={open} className={classes.container}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

export default withStyles(styles)(CustomLoadingBackdrop);
