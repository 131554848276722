// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ArrowUpward, MoreVert } from "@material-ui/icons";
import moment from "moment";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  Label,
  YAxis,
  Text,
  PieChart,
  Cell,
  Pie,
} from "recharts";
import CustomMenuItem from "../../../components/src/CustomMenuItem.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomRechartRevenueTooltip from "../../../components/src/CustomRechartRevenueTooltip.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomTable from "../../../components/src/CustomTable";
import CustomTypography from "../../../components/src/CustomTypography.web";
import KitchenDashboardStatisticsCard, {
  DashboardAdminStatistics,
} from "../../../components/src/KitchenDashboardStatisticsCard.web";
import AdminRestaurantStatusTag from "../../../components/src/AdminRestaurantStatusTag.web";
import DashboardAdminController, {
  Props,
  SalesDetailDataProps,
  SalesDetailProps,
  StatisticsDataProps,
} from "./DashboardAdminController.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "& *": {
        boxSizing: "border-box",
      },

      "&.rtl": {
        direction: "rtl",
      },
    },

    statistics: {
      display: "flex",
      gap: 24,
      flexWrap: "wrap",
    },

    revenueAndSales: {
      marginTop: 32,
    },

    heading: {
      fontFamily: "Roboto !important",
      fontSize: "14px !important",
      fontWeight: "600 !important" as any,
      lineHeight: "22.42px !important" as any,
    },

    filterSelect: {
      width: 100,
    },

    revenueAndSalesInner: {
      "&.rtl": {
        flexDirection: "row-reverse",
      },
    },

    revenue: {
      padding: "24px 28px",
      border: "1px solid #EDEDED",
      borderRadius: 8,
      height: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
    },

    revenueHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    revenueChart: {
      marginTop: "auto",
    },

    revenueChartLegend: {
      marginTop: 10,
      padding: 16,
    },

    revenueChartLegendLabel: {
      fontFamily: "Inter !important",
      fontSize: "12px !important",
      lineHeight: "16.61px !important",
      display: "inline-flex !important",
      alignItems: "center",
      gap: 8,

      "&::before": {
        width: 8,
        height: 8,
        display: "inline-block",
        borderRadius: "50%",
        content: '" "',
        boxSizing: "border-box",
        backgroundColor: "#FF6666",
      },
    },

    salesDetail: {
      padding: "24px 16px",
      border: "1px solid #EDEDED",
      borderRadius: 8,
      height: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
    },

    restaurant: {
      marginTop: 32,
    },

    restaurantTable: {
      [theme.breakpoints.down("lg")]: {
        whiteSpace: "nowrap",
      },

      "& thead": {
        backgroundColor: "#FFFBF4",
      },
    },

    contactInfo: {
      fontFamily: "Inter !important",
    },

    registrationDate: {
      fontFamily: "Inter !important",
    },

    pagination: {
      marginTop: 24,
      display: "flex",
      justifyContent: "end",
    },

    salesHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    salesDetailChart: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 24,
    },

    salesDetailChartLegend: {
      marginTop: 32,
      width: "100%",
    },

    salesDetailChartLegendItem: {
      display: "flex",
      alignItems: "center",
      gap: 8,

      "& + &": {
        marginTop: 12,
      },
    },

    salesDetailChartLegendItemDot: {
      width: 12,
      height: 12,
      borderRadius: "50%",
    },

    salesDetailChartLegendItemName: {
      fontFamily: "Inter !important",
      fontSize: "12px !important",
      color: "#0F172A !important",
    },

    salesDetailChartLegendItemNumber: {
      marginLeft: 8,
      fontWeight: "700 !important" as any,
      fontFamily: "Inter !important",
      fontSize: "12px !important",
      color: "#0F172A !important",
    },

    revenueHightlight: {
      fontFamily: "Roboto !important",
      fontSize: "32px !important",
      fontWeight: "700 !important" as any,
      lineHeight: "40.77px !important",
    },

    revenueHightlightUp: {
      display: "flex",
      alignItems: "center",
      marginTop: 4,
    },

    revenueHightlightUpNumber: {
      fontFamily: "Inter !important",
      fontSize: "14px !important",
      fontWeight: "700 !important" as any,
      lineHeight: "22.42px !important",
      color: "#059669 !important",
      marginLeft: 4,
    },

    revenueHightlightUpIcon: {
      width: 18,
      height: 18,
      borderRadius: "50%",
      backgroundColor: "#D1FAE5",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& svg": {
        color: "#059669",
        fontSize: 10,
      },
    },
  });

// Customizable Area End

export class DashboardAdmin extends DashboardAdminController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, t, i18n } = this.props;

    const {
      loading,
      statisticsData,
      salesDetailData,
      revenueChartData,
      revenueFilter,
      salesFilter,
      restaurantData,
      pagination,
    } = this.state;

    const rtl = i18n.language === "ar";

    const salesDetailChartData: SalesDetailProps[] = Object.keys(
      salesDetailData
    ).map((key) => {
      return {
        name: salesDetailData[key as keyof SalesDetailDataProps].name,
        value: salesDetailData[key as keyof SalesDetailDataProps].value,
        color: salesDetailData[key as keyof SalesDetailDataProps].color,
      };
    });

    const statisticsCardData: DashboardAdminStatistics[] = Object.keys(
      statisticsData
    ).map((key) => {
      return {
        name: t(statisticsData[key as keyof StatisticsDataProps].name),
        number: statisticsData[key as keyof StatisticsDataProps].number,
        theme: statisticsData[key as keyof StatisticsDataProps].theme,
        icon: statisticsData[key as keyof StatisticsDataProps].icon,
      };
    });

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <Box className={classes.statistics}>
          {statisticsCardData.map((data) => (
            <KitchenDashboardStatisticsCard statistics={data} key={data.name} />
          ))}
        </Box>

        <Box className={classes.revenueAndSales}>
          <Grid
            container
            spacing={2}
            className={clsx(classes.revenueAndSalesInner, { ["rtl"]: rtl })}
          >
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Box className={classes.revenue}>
                <Box className={classes.revenueHead}>
                  <CustomTypography className={classes.heading}>
                    {t("Revenue")}
                  </CustomTypography>

                  <CustomSelect
                    className={classes.filterSelect}
                    data-test-id="status-select"
                    placeholder="Order Status"
                    variant="outlined"
                    value={revenueFilter}
                    onChange={() => {}}
                  >
                    {this.timeOptions.map((option) => (
                      <CustomMenuItem value={option.value} key={option.id}>
                        {t(option.name)}
                      </CustomMenuItem>
                    ))}
                  </CustomSelect>
                </Box>

                <CustomTypography className={classes.revenueHightlight}>
                  $4,386
                </CustomTypography>

                <Box className={classes.revenueHightlightUp}>
                  <Box className={classes.revenueHightlightUpIcon}>
                    <ArrowUpward />
                  </Box>
                  <CustomTypography
                    className={classes.revenueHightlightUpNumber}
                  >
                    +11.32%
                  </CustomTypography>
                </Box>

                <Box className={classes.revenueChart} dir="ltr">
                  <ResponsiveContainer width="100%" height={148}>
                    <AreaChart data={revenueChartData}>
                      <CartesianGrid vertical={false} strokeDasharray={5} />

                      <XAxis
                        reversed={rtl}
                        dataKey="date"
                        fontFamily="Inter"
                        fontSize={12}
                        tickMargin={10}
                        fontWeight={400}
                        tick={({ x, y, payload, index }) => {
                          let textAnchor = "middle";

                          if (index === 0) {
                            textAnchor = "start";
                          }

                          if (index === revenueChartData.length - 1) {
                            textAnchor = "end";
                          }

                          return (
                            <Text
                              x={x}
                              y={y}
                              dx={0}
                              dy={16}
                              fontFamily="Inter"
                              fontSize={12}
                              textAnchor={textAnchor as any}
                            >
                              {t(payload.value)}
                            </Text>
                          );
                        }}
                        tickLine={false}
                        stroke="#CBD5E1"
                      />

                      <YAxis
                        orientation={rtl ? "right" : "left"}
                        dataKey="value"
                        type="number"
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={400}
                        tickMargin={8}
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={(value) =>
                          Intl.NumberFormat("en", {
                            notation: "compact",
                          }).format(value)
                        }
                        domain={[1250, "auto"]}
                      />
                      <Tooltip
                        data-testid="tooltip"
                        content={<CustomRechartRevenueTooltip />}
                        cursor={{ strokeDasharray: 4, stroke: "#A2A2A2" }}
                      />

                      <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#FF6666"
                        strokeWidth={3}
                        fill="#FFE3E3"
                        activeDot={{
                          r: 8,
                          stroke: "#FFF",
                          strokeWidth: 5,
                          fill: "#F66",
                        }}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Box>

                <Box className={classes.revenueChartLegend}>
                  <CustomTypography className={classes.revenueChartLegendLabel}>
                    {t("Revenue")}
                  </CustomTypography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Box className={classes.salesDetail}>
                <Box className={classes.salesHead}>
                  <CustomTypography className={classes.heading}>
                    {t("Sales Details")}
                  </CustomTypography>

                  <CustomSelect
                    className={classes.filterSelect}
                    data-test-id="status-select"
                    placeholder="Order Status"
                    variant="outlined"
                    value={salesFilter}
                    onChange={() => {}}
                  >
                    {this.timeOptions.map((option) => (
                      <CustomMenuItem value={option.value} key={option.id}>
                        {t(option.name)}
                      </CustomMenuItem>
                    ))}
                  </CustomSelect>
                </Box>

                <Box className={classes.salesDetailChart}>
                  <ResponsiveContainer width={171} height={171}>
                    <PieChart width={171} height={171} onMouseEnter={() => {}}>
                      <Pie
                        data={salesDetailChartData}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        width={171}
                        height={171}
                      >
                        {salesDetailChartData.map((data) => (
                          <Cell key={data.name} fill={data.color} />
                        ))}

                        <Label
                          value="100%"
                          position="center"
                          fontFamily="Roboto"
                          fontSize={30}
                          fontWeight="bold"
                          fill="#000000"
                        />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Box>

                <Box className={classes.salesDetailChartLegend}>
                  {salesDetailChartData.map((data) => (
                    <Box
                      className={classes.salesDetailChartLegendItem}
                      key={data.name}
                    >
                      <Box
                        className={classes.salesDetailChartLegendItemDot}
                        style={{ backgroundColor: data.color }}
                      />
                      <CustomTypography
                        className={classes.salesDetailChartLegendItemName}
                      >
                        {t(data.name)}
                      </CustomTypography>
                      <CustomTypography
                        className={classes.salesDetailChartLegendItemNumber}
                      >
                        {`${data.value}%`}
                      </CustomTypography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.restaurant}>
          <TableContainer>
            <CustomTable className={classes.restaurantTable}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Restaurant Name")}</TableCell>
                  <TableCell>{t("Location")}</TableCell>
                  <TableCell>{t("Contact Info")}</TableCell>
                  <TableCell>{t("Registration Date")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {restaurantData.length ? (
                  restaurantData.map((data) => (
                    <TableRow
                      key={data.id}
                      data-test-id={`table-row-${data.id}`}
                    >
                      <TableCell>{data.name}</TableCell>
                      <TableCell>{data.location}</TableCell>
                      <TableCell className={classes.contactInfo}>
                        {data.contactInfo}
                      </TableCell>
                      <TableCell className={classes.registrationDate}>
                        {moment(data.registrationDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        <AdminRestaurantStatusTag status={data.status} />
                      </TableCell>
                      <TableCell>
                        <IconButton>
                          <MoreVert />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box style={{ textAlign: "center" }}>{t("No data")}</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className={classes.pagination}>
            <CustomPagination
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(_, page) => this.changePage(page)}
              showOffset
            />
          </Box>
        </Box>

        <CustomLoadingBackdrop open={loading} />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(withTranslation()(DashboardAdmin));
// Customizable Area End
