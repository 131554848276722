import {
  Box,
  Drawer,
  IconButton,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { ReactNode } from "react";
import CustomTypography from "./CustomTypography.web";
import { Close } from "@material-ui/icons";

export interface Props extends WithStyles<any> {
  title: string;
  open: boolean;
  width?: number;
  body: ReactNode;
  bottom: ReactNode;

  onClose(): void;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      height: "100%",

      "& *": {
        boxSizing: "border-box",
      },
    },

    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #E2E8F0",
      padding: 24,
    },

    body: {
      padding: 24,
      flex: 1,
      overflow: "auto",
    },

    bottom: {
      borderTop: "1px solid #E2E8F0",
      padding: 24,
    },
  });

export class KitchenDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, body, bottom, open, title, width, onClose } = this.props;

    return (
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box className={classes.container} style={{ width: width || 378 }}>
          <Box className={classes.head}>
            <CustomTypography variant="h2">{title}</CustomTypography>

            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>

          <Box className={classes.body}>{body}</Box>

          <Box className={classes.bottom}>{bottom}</Box>
        </Box>
      </Drawer>
    );
  }
}

export default withStyles(styles)(KitchenDrawer);
