import {
  Dialog,
  IconButton,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { Close } from "@material-ui/icons";
import { Invoice } from "../../../blocks/paymentadmin2/src/Paymentadmin2FinanceViewController.web";
import CustomButton from "../CustomButton.web";
import CustomTypographyWeb from "../CustomTypography.web";

export interface Props extends WithStyles<any> {
  open: boolean;
  invoice: Invoice | null;
  onClose(): void;
  onSubmit(): void;
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      maxWidth: 600,
    },
    dialog: {
      "& .dialog__buttonClose": {
        position: "absolute",
        top: 0,
        right: 0,
      },
      "& .dialog__title": {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(24),
        lineHeight: 32 / 24,
        letterSpacing: "-0.03em",
        color: "#333",
      },
      "& .dialog__description": {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: 26 / 18,
        color: "#0F172A",
        marginBlockStart: 16,
      },
      "& .dialog__content": {
        padding: "60px 40px 24px",
      },
      "& .dialog__actions": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 16,
        padding: "24px 40px",
        borderTop: "1px solid #E2E8F0",
        "& > button": {
          maxWidth: 120,
          width: "100%",
          "&:first-child": {
            backgroundColor: "#333",
          },
        },
      },
    },
  });

export class CustomPaymentadmin2FinanceArchive extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, invoice, onClose, onSubmit } = this.props;

    if (!invoice) {
      return <></>;
    }

    return (
      <Dialog
        classes={{ paper: classes.paper }}
        className={classes.dialog}
        open={true}
        onClose={onClose}
      >
        <IconButton className="dialog__buttonClose" onClick={onClose}>
          <Close />
        </IconButton>
        <div className="dialog__content">
          <CustomTypographyWeb className="dialog__title">
            Are you sure you want to archive Invoice of <br />
            {invoice.attributes.formatted_month}
          </CustomTypographyWeb>
          <CustomTypographyWeb className="dialog__description">
            This action can&apos;t be undone and all the data stored in this
            account won&apos;t be accessible anymore.
          </CustomTypographyWeb>
        </div>

        <div className="dialog__actions">
          <CustomButton variant="contained" color="primary" onClick={onClose}>
            Cancel
          </CustomButton>
          <CustomButton variant="contained" color="primary" onClick={onSubmit}>
            Done
          </CustomButton>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CustomPaymentadmin2FinanceArchive);
