import {
  Box,
  IconButton,
  ListItemText,
  MenuItem,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { AddCircleOutline, Delete, Edit } from "@material-ui/icons";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomButton from "./CustomButton.web";
import CustomFormInputField from "./CustomFormInputField.web";
import CustomFormSelectField from "./CustomFormSelectField.web";
import CustomFormSingleImageUpload from "./CustomFormSingleImageUpload.web";
import CustomTypography from "./CustomTypography.web";
import KitchenDrawer from "./KitchenDrawer.web";
import KitchenMenuItemVariantFormDialog, {
  KitchenMenuItemVariantFormDialogMode,
  KitchenMenuItemVariantFormDialogProps,
} from "./KitchenMenuItemVariantFormDialog.web";
import { v4 as uuidv4 } from "uuid";

const validationSchema = Yup.object({
  image: Yup.string().nullable().required("Please attach item photo"),
  nameEn: Yup.string().nullable().required("Please enter name en"),
  nameAr: Yup.string()
    .matches(/^[\u0600-\u06FF\s.,،]+$/, "Please enter valid Arabic text")
    .nullable()
    .required("Please enter name ar"),
  category: Yup.string().nullable().required("Please select category"),
  descriptionEn: Yup.string()
    .nullable()
    .required("Please enter description en"),
  descriptionAr: Yup.string()
    .matches(/^[\u0600-\u06FF\s.,،]+$/, "Please enter valid Arabic text")
    .nullable()
    .required("Please enter description ar"),
  price: Yup.number()
    .nullable()
    .required("Please enter price")
    .positive("Please enter valid price")
    .typeError("Please enter valid price"),
});

export interface Props extends WithStyles {
  drawer: KitchenMenuItemFormDrawerProps;

  onClose(): void;
  createItem(values: {
    image: string | File;
    nameEn: string;
    nameAr: string;
    category: string;
    descriptionEn: string;
    descriptionAr: string;
    price: number;
    variants: {
      id: string;
      name: string;
      price: number;
    }[];
  }): void;
}

interface S {
  itemVariantDialog: KitchenMenuItemVariantFormDialogProps;
}

export enum KitchenMenuItemFormDrawerMode {
  Add = "add",
  Edit = "edit",
}

export interface KitchenMenuItemFormDrawerProps {
  mode: KitchenMenuItemFormDrawerMode;
  open: boolean;
  loading: boolean;
  options: {
    id: string | number;
    name: string;
    value: string | number;
  }[];
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    formNameGroup: {
      marginTop: 20,
      display: "flex",
      gap: 15,

      "& > div": {
        marginTop: "0 !important",
      },
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
    },

    formImage: {
      "& > div": {
        height: 160,
      },
    },

    formBottom: {
      marginTop: "auto",
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },

    formPriceAndVariant: {
      marginTop: 20,
      paddingBottom: 49,
    },

    formPriceAndVariantDescription: {
      marginTop: 12,
    },

    formPriceAndVariantGroup: {
      marginTop: 8,
    },

    formPriceAndVariantAddButton: {
      marginTop: 14,
    },

    formDescriptionEn: {
      margin: "8px 0",
    },

    formNameDescription: {
      marginTop: 4,
    },

    formDescriptionGroup: {
      marginTop: 20,
    },

    formCategory: {
      marginTop: 20,
    },

    formVariant: {
      marginTop: 20,
    },

    formVariantInner: {
      marginTop: 20,
    },

    formVariantItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "& + &": {
        marginTop: 12,
      },
    },

    formVariantItemContent: {
      display: "flex",
      alignItems: "center",
    },

    formVariantItemName: {},

    formVariantItemPrice: {
      fontFamily: "Inter !important",
      marginLeft: 12,
      color: "#FF6666 !important",
      fontWeight: 600,
    },

    formVariantItemAction: {
      marginLeft: 20,
    },

    formPriceInput: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },
  });

export class KitchenMenuItemFormDrawer extends React.Component<Props, S> {
  private formProps?: FormikProps<any> = undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      itemVariantDialog: {
        loading: false,
        mode: KitchenMenuItemVariantFormDialogMode.Add,
        open: false,
        form: {
          id: "",
          name: "",
          price: 0,
        },
      },
    };
  }

  openAddItemVariantDialog = () => {
    this.setState({
      itemVariantDialog: {
        ...this.state.itemVariantDialog,
        mode: KitchenMenuItemVariantFormDialogMode.Add,
        open: true,
      },
    });
  };

  closeItemVariantDialog = () => {
    this.setState({
      itemVariantDialog: {
        loading: false,
        mode: KitchenMenuItemVariantFormDialogMode.Add,
        open: false,
        form: {
          id: "",
          name: "",
          price: 0,
        },
      },
    });
  };

  addItemVariant = (variant: { name: string; price: number }) => {
    const newVariant = {
      id: uuidv4(),
      name: variant.name,
      price: variant.price,
    };

    const variants = this.formProps?.values.variants;

    if (!variants) {
      return;
    }
    variants.push(newVariant);

    this.formProps?.setFieldValue("variants", variants);

    this.closeItemVariantDialog();
  };

  openEditItemVariant = (variant: {
    id: string;
    name: string;
    price: number;
  }) => {
    this.setState({
      itemVariantDialog: {
        ...this.state.itemVariantDialog,
        mode: KitchenMenuItemVariantFormDialogMode.Edit,
        form: variant,
        open: true,
      },
    });
  };

  deleteItemVariant = (id: string) => {
    const variants = this.formProps?.values.variants;

    if (!variants) {
      return;
    }

    const index = variants.findIndex((variant: any) => variant.id === id);

    if (index < 0) {
      return;
    }

    variants.splice(index, 1);

    this.formProps?.setFieldValue("variants", variants);

    this.closeItemVariantDialog();
  };

  editItemVariant = (selectedVariant: { name: string; price: number }) => {
    const variants = this.formProps?.values.variants;

    if (!variants) {
      return;
    }

    const index = variants.findIndex(
      (variant: { id: string; name: string; price: number }) =>
        variant.id === this.state.itemVariantDialog.form.id
    );

    if (index < 0) {
      return;
    }

    variants[index] = {
      id: this.state.itemVariantDialog.form.id,
      ...selectedVariant,
    };

    this.formProps?.setFieldValue("variants", variants);

    this.closeItemVariantDialog();
  };

  render(): React.ReactNode {
    const { itemVariantDialog } = this.state;
    const { classes, drawer, onClose, createItem } = this.props;
    const { open, options } = drawer;

    return (
      <Box>
        <KitchenDrawer
          open={open}
          title="Add New Items"
          onClose={onClose}
          data-test-id="item-drawer"
          body={
            <Formik
              initialValues={{
                image: "",
                nameEn: "",
                nameAr: "",
                category: "",
                descriptionEn: "",
                descriptionAr: "",
                variants: [],
                price: 0,
              }}
              validationSchema={validationSchema}
              onSubmit={createItem}
              data-test-id="kitchen-item-formik"
            >
              {(props) => {
                this.formProps = props;

                return (
                  <Form
                    data-test-id="kitchen-item-form"
                    className={classes.form}
                    onSubmit={props.handleSubmit}
                  >
                    <Box className={classes.formInner}>
                      <CustomFormSingleImageUpload
                        className={classes.formImage}
                        name="image"
                        label="Upload item photo"
                        error={props.touched.image && !!props.errors.image}
                        data-test-id="image-upload"
                        onChange={(file: File | null) =>
                          props.setFieldValue("image", file)
                        }
                        value={props.values.image}
                      />

                      <Box className={classes.formNameGroup}>
                        <CustomFormInputField
                          fullWidth
                          name="nameEn"
                          label="Item Name - en"
                          placeholder="Item Name"
                          variant="outlined"
                          error={props.touched.nameEn && !!props.errors.nameEn}
                          data-test-id="name-en-input"
                          required
                        />

                        <CustomFormInputField
                          fullWidth
                          name="nameAr"
                          label="Item Name - ar"
                          placeholder="Item Name"
                          variant="outlined"
                          error={props.touched.nameAr && !!props.errors.nameAr}
                          data-test-id="name-ar-input"
                          required
                        />
                      </Box>

                      <CustomTypography className={classes.formNameDescription}>
                        Example: Cheese Pizza, Fried Rice, Apple Juice
                      </CustomTypography>

                      <Box className={classes.formCategory}>
                        <CustomFormSelectField
                          fullWidth
                          name="category"
                          label="Select category"
                          variant="outlined"
                          error={
                            props.touched.category && !!props.errors.category
                          }
                          data-test-id="category-select"
                          displayEmpty
                          inputProps={{
                            renderValue: (selected: string) => {
                              if (!selected) {
                                return "Select Category";
                              }

                              const selectedOption = options.find(
                                (option) => option.value === selected
                              );

                              if (!selectedOption) {
                                return "";
                              }

                              return selectedOption.name;
                            },
                          }}
                        >
                          {options.map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                              <ListItemText primary={option.name} />
                            </MenuItem>
                          ))}
                        </CustomFormSelectField>
                      </Box>

                      <Box className={classes.formDescriptionGroup}>
                        <Box className={classes.formDescriptionGroup}>
                          <CustomFormInputField
                            required
                            fullWidth
                            name="descriptionEn"
                            label="Item description - en"
                            placeholder="Item description"
                            variant="outlined"
                            error={
                              props.touched.descriptionEn &&
                              !!props.errors.descriptionEn
                            }
                            data-test-id="description-en-textarea"
                            multiline
                            maxRows={4}
                            minRows={4}
                            description={
                              <CustomTypography
                                className={classes.formDescriptionEn}
                              >
                                Example: Cheddar and mozzarella cheese on a thin
                                crust
                              </CustomTypography>
                            }
                          />

                          <CustomFormInputField
                            fullWidth
                            required
                            name="descriptionAr"
                            label="Item description - ar"
                            placeholder="Item description"
                            variant="outlined"
                            error={
                              props.touched.descriptionAr &&
                              !!props.errors.descriptionAr
                            }
                            data-test-id="description-ar-textarea"
                            multiline
                            maxRows={4}
                            minRows={4}
                            reverse
                          />
                        </Box>

                        <Box className={classes.formPriceAndVariant}>
                          <CustomTypography variant="h5">
                            Price and Variations
                          </CustomTypography>

                          <CustomTypography
                            variant="body1"
                            className={classes.formPriceAndVariantDescription}
                          >
                            Add a variation if this comes in different sizes
                            (e.g. small, medium, large) or options (e.g. Soup,
                            Dry). Include GST/VAT in prices if applicable.
                          </CustomTypography>

                          <Box className={classes.formPriceAndVariantGroup}>
                            <CustomFormInputField
                              required
                              variant="outlined"
                              placeholder="Enter Price in SAR"
                              fullWidth
                              className={classes.formPriceInput}
                              name="price"
                              value={props.values.price || ""}
                              error={
                                props.touched.price && !!props.errors.price
                              }
                            />
                          </Box>

                          <CustomButton
                            className={classes.formPriceAndVariantAddButton}
                            startIcon={<AddCircleOutline />}
                            color="primary"
                            onClick={this.openAddItemVariantDialog}
                          >
                            Add Variation
                          </CustomButton>

                          {props.values.variants.length ? (
                            <Box className={classes.formVariant}>
                              <CustomTypography variant="h5" component="span">
                                Variants:
                              </CustomTypography>

                              <Box className={classes.formVariantInner}>
                                {props.values.variants.map((variant: any) => (
                                  <Box
                                    key={variant.id}
                                    className={classes.formVariantItem}
                                  >
                                    <Box
                                      className={classes.formVariantItemContent}
                                    >
                                      <CustomTypography
                                        className={classes.formVariantItemName}
                                        variant="h5"
                                        component="span"
                                      >
                                        {variant.name}
                                      </CustomTypography>

                                      <CustomTypography
                                        className={classes.formVariantItemPrice}
                                        component="span"
                                      >
                                        {`${variant.price} SAR`}
                                      </CustomTypography>
                                    </Box>

                                    <Box
                                      className={classes.formVariantItemAction}
                                    >
                                      <IconButton
                                        onClick={() =>
                                          this.openEditItemVariant(variant)
                                        }
                                      >
                                        <Edit color="primary" />
                                      </IconButton>

                                      <IconButton
                                        onClick={() =>
                                          this.deleteItemVariant(variant.id)
                                        }
                                      >
                                        <Delete color="primary" />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          }
          bottom={
            <Box className={classes.formBottom}>
              <CustomButton
                className={classes.formVariantItemActionButton}
                variant="contained"
                color="default"
                onClick={onClose}
                data-test-id="cancel-btn"
              >
                Cancel
              </CustomButton>

              <CustomButton
                className={classes.formVariantItemActionButton}
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => {
                  this.formProps?.handleSubmit();
                }}
              >
                Add Items
              </CustomButton>
            </Box>
          }
        />

        <KitchenMenuItemVariantFormDialog
          data-test-id="variant-dialog"
          dialog={itemVariantDialog}
          onClose={this.closeItemVariantDialog}
          addItemVariant={this.addItemVariant}
          editItemVariant={this.editItemVariant}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(KitchenMenuItemFormDrawer);
