import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomButton from "./CustomButton.web";
import CustomDialog from "./CustomDialog.web";
import CustomTypography from "./CustomTypography.web";

export interface Props extends WithStyles {
  dialog: AdminOrderDeleteDialogProps;

  onClose(): void;
  onDelete(): void;
}

export interface AdminOrderDeleteDialogProps {
  open: boolean;
  order: {
    id: string | number;
    orderNumber: string;
    restaurantName: string;
    branch: string;
    branchId: string | number;
    datePlaced: string | number | Date;
    totalAmount: number;
    status: string;
    kitchenId: string | number;
  };
}

const styles = (theme: Theme) =>
  createStyles({
    message: {
      fontSize: "16px !important",
      lineHeight: "26px !important",
    },

    actions: {
      display: "flex",
      alignItems: "center",
      gap: 16,
    },
  });

export class AdminOrderDeleteDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, dialog, onClose, onDelete } = this.props;
    const { open, order } = dialog;

    return (
      <CustomDialog
        width={600}
        open={open}
        title={`Are you sure you want to delete order ${order.orderNumber}?`}
        onClose={onClose}
        content={
          <CustomTypography className={classes.message}>
            This action can’t be undone and all the data stored in this order
            won’t be accessible anymore.
          </CustomTypography>
        }
        actions={
          <Box className={classes.actions}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              Cancel
            </CustomButton>

            <CustomButton
              data-test-id="delete-btn"
              color="primary"
              variant="contained"
              onClick={onDelete}
            >
              Done
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(AdminOrderDeleteDialog);
