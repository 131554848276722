import {
  TextField,
  TextFieldProps,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

export interface Props extends WithStyles<any> {}

export type CustomInputFieldProps = TextFieldProps & Props;

const styles = createStyles({
  customInputField: {
    "& .MuiInputBase-input": {
      height: "auto",
    },

    "& .MuiOutlinedInput-input": {
      padding: "12px 8px",
      fontSize: 14,
      fontFamily: "Raleway",
    },

    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 8,
      borderColor: "#CBD5E1",
    },

    "& .Mui-error": {
      borderColor: "#F87171",
    },

    "& .MuiInputAdornment-positionEnd": {
      "& .MuiIconButton-root": {
        "& .MuiSvgIcon-root": {
          color: "#FF6666",
        },
      },
    },
  },
});

export class CustomInputField extends React.Component<CustomInputFieldProps> {
  constructor(props: CustomInputFieldProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, className, ...rest } = this.props;

    return (
      <TextField
        className={clsx(classes.customInputField, className)}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(CustomInputField);
