Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Get Location";
exports.labelSorryText = "Sorry!";
exports.labelSorryBodyText = "There is no VR Centre in your city";
exports.cityListApiEndPoing = "bx_block_location/cities";
exports.updateProfileEndPoint = "bx_block_profile/profiles";
exports.validationApiMethodType = "GET";
exports.getApiMethodType = "GET";
exports.updateMethodType = "PUT";
exports.btnGetLocationTitle = "Get Location";
exports.btnDenyLocationTitle = "Do not Allow";
exports.btnSelectCenterTitle = "Select a Centre";
exports.errorTitle = "Error";

// Customizable Area Start
exports.getLocationStoresApiEndPoint = "bx_block_location/stores";
exports.locationStoresApiContentType = 'application/json';
exports.postApiMethodType = "POST";
exports.patchApiMethodType = "PATCH";
exports.createLocationStoresApiContentType  = "multipart/form-data";
// Customizable Area End
