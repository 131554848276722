import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import KitchenDrawer from "./KitchenDrawer.web";
import KitchenOrderSummaryDish from "./KitchenOrderSummaryDish.web";
import { KitchenOrder, KitchenOrderDish } from "./KitchenOrderTableRow.web";
import CustomTypography from "./CustomTypography.web";
import moment from "moment";
import CustomButton from "./CustomButton.web";

export interface Props extends WithStyles<any> {
  open: boolean;
  selectedOrder: KitchenOrder;

  onClose(): void;
  updateOrderItemStatus(
    id: string,
    status: string,
    declineReason: string
  ): void;
  openOrderChangeDrawer(dish: KitchenOrderDish): void;
}

export enum KitchenOrderSummaryStatus {
  Accept = "accepted",
  Decline = "declined",
  Replace = "replace",
  ReadyForPickup = "ready_for_pickup",
}

const styles = (theme: Theme) =>
  createStyles({
    container: {},

    title: {
      fontFamily: "Inter !important",
      lineHeight: "22px",
    },

    content: {
      marginTop: 13,
    },

    group: {
      "& + &": {
        marginTop: 27,
      },
    },

    declinedTitle: {
      color: "#DC2626",
      fontSize: "12px !important",
      lineHeight: "24px !important",
    },

    acceptedTitle: {
      color: "#059669",
      fontSize: "12px !important",
      lineHeight: "24px !important",
    },

    list: {
      marginTop: 10,
    },

    drawerBottom: {
      marginTop: "auto",
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },
  });

export class KitchenOrderSummaryDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      classes,
      open,
      selectedOrder,

      onClose,
      openOrderChangeDrawer,
      updateOrderItemStatus,
    } = this.props;

    const { orderDetailData } = selectedOrder;

    const acceptedDishes = orderDetailData.filter((dish) =>
      [
        KitchenOrderSummaryStatus.Accept,
        KitchenOrderSummaryStatus.ReadyForPickup,
      ].includes(dish.orderSummaryStatus)
    );

    const replacedDishes = orderDetailData.filter(
      (dish) => dish.orderSummaryStatus === KitchenOrderSummaryStatus.Replace
    );

    const declinedDishes = orderDetailData.filter(
      (dish) => dish.orderSummaryStatus === KitchenOrderSummaryStatus.Decline
    );

    return (
      <KitchenDrawer
        open={open}
        title="Order Summary"
        onClose={onClose}
        body={
          <Box className={classes.container}>
            <CustomTypography
              variant="body1"
              className={classes.title}
            >{`Order id: ${selectedOrder.orderId} on date of ${moment(
              selectedOrder.time
            ).format("DD/MM/yyyy")} at ${moment(selectedOrder.time).format(
              "h:mmA"
            )}`}</CustomTypography>

            <Box className={classes.content}>
              {!!declinedDishes.length && (
                <Box className={classes.group}>
                  <CustomTypography
                    variant="body2"
                    className={classes.declinedTitle}
                  >
                    Items are unavailable and it will be not ready for pickup
                  </CustomTypography>

                  <Box className={classes.list}>
                    {declinedDishes.map((dish) => (
                      <KitchenOrderSummaryDish
                        data-test-id={`dish-${dish.id}`}
                        key={dish.id}
                        dish={dish}
                        sendMessage={(declineReason: string) =>
                          updateOrderItemStatus(
                            dish.id,
                            dish.status,
                            declineReason
                          )
                        }
                        openOrderChangeDrawer={() =>
                          openOrderChangeDrawer(dish)
                        }
                      />
                    ))}
                  </Box>
                </Box>
              )}

              {!!replacedDishes.length && (
                <Box className={classes.group}>
                  <CustomTypography
                    variant="body2"
                    className={classes.declinedTitle}
                  >
                    Item replace with actual item order
                  </CustomTypography>

                  <Box className={classes.list}>
                    {replacedDishes.map((dish) => {
                      return (
                        <KitchenOrderSummaryDish
                          data-test-id={`dish-${dish.id}`}
                          key={dish.id}
                          dish={dish}
                          sendMessage={() => {}}
                          openOrderChangeDrawer={() =>
                            openOrderChangeDrawer(dish)
                          }
                        />
                      );
                    })}
                  </Box>
                </Box>
              )}

              <Box className={classes.group}>
                <CustomTypography
                  variant="body2"
                  className={classes.acceptedTitle}
                >
                  Items are available and it will be ready for pickup
                </CustomTypography>

                <Box className={classes.list}>
                  {acceptedDishes.map((dish) => {
                    return (
                      <KitchenOrderSummaryDish
                        key={dish.id}
                        dish={dish}
                        sendMessage={() => {}}
                        openOrderChangeDrawer={() =>
                          openOrderChangeDrawer(dish)
                        }
                      />
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        }
        bottom={
          <Box className={classes.drawerBottom}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="back-btn"
            >
              Cancel
            </CustomButton>

            <CustomButton color="primary" variant="contained" type="submit">
              Notify User
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(KitchenOrderSummaryDrawer);
