import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import {
  DeleteOutline,
  Edit as EditIcon,
  MoreVert,
  RemoveRedEyeOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import CustomTypography from "./CustomTypography.web";

export enum KitchenAccountManagementMenuAction {
  View = "view",
  Edit = "edit",
  Delete = "delete",
}

export interface KitchenAccountManagementMenuOption {
  id: string;
  name: string;
  value: KitchenAccountManagementMenuAction;
  icon: React.ReactNode;
}

export interface Props extends WithStyles {
  onSelectAction(value: KitchenAccountManagementMenuAction): void;
}

const styles = createStyles({
  statusExpand: {
    marginLeft: "auto",
  },

  menu: {
    "& .MuiMenu-list": {
      padding: "0 !important",
      width: 120,
    },
  },

  actionLink: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    padding: "8px 16px",
    width: "100%",

    "&:hover": {
      backgroundColor: "rgba(255, 102, 102, 0.4)",
      borderLeft: "3px solid #FF6666",
      color: "#FFFFFF",
    },

    "& svg": {
      fill: "#333333",
    },
  },

  actionItemIcon: {
    width: 18,
    height: 18,
  },

  actionItemName: {
    marginLeft: 8,
    color: "#333333",
  },

  actionItem: {
    padding: 0,
  },
});

export class KitchenAccountManagementMenu extends React.Component<
  Props,
  {
    statusMenu: HTMLElement | null;
  }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      statusMenu: null,
    };
  }

  menu = [
    {
      id: uuidv4(),
      name: "View",
      value: KitchenAccountManagementMenuAction.View,
      icon: RemoveRedEyeOutlined,
    },
    {
      id: uuidv4(),
      name: "Edit",
      value: KitchenAccountManagementMenuAction.Edit,
      icon: EditIcon,
    },

    {
      id: uuidv4(),
      name: "Delete",
      value: KitchenAccountManagementMenuAction.Delete,
      icon: DeleteOutline,
    },
  ];

  openFilter = (event: any) => {
    this.setState({
      statusMenu: event.currentTarget,
    });
  };

  closeFilter = () => {
    this.setState({
      statusMenu: null,
    });
  };

  onChangeStatus = (value: KitchenAccountManagementMenuAction) => {
    this.props.onSelectAction(value);

    this.closeFilter();
  };

  render(): React.ReactNode {
    const { classes } = this.props;
    const { statusMenu } = this.state;

    const id = uuidv4();

    return (
      <Box className={classes.detailStatus}>
        <Box
          id={`filter-button-${id}`}
          aria-controls={statusMenu ? `filter-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={statusMenu ? "true" : undefined}
          onClick={this.openFilter}
        >
          <IconButton className={classes.catalogueMenuButton}>
            <MoreVert />
          </IconButton>
        </Box>

        <Menu
          className={classes.menu}
          id={`filter-menu-${id}`}
          anchorEl={statusMenu}
          open={Boolean(statusMenu)}
          onClose={this.closeFilter}
          MenuListProps={{
            className: classes.detailStatus,
            "aria-labelledby": `filter-button-${id}`,
          }}
        >
          {this.menu.map((option) => {
            return (
              <MenuItem
                className={clsx(classes.actionItem)}
                key={option.id}
                data-test-id={option.name.toLowerCase()}
                onClick={() => this.onChangeStatus(option.value)}
              >
                <Box className={clsx(classes.actionLink)}>
                  <option.icon className={classes.actionItemIcon} />
                  <CustomTypography className={classes.actionItemName}>
                    {option.name}
                  </CustomTypography>
                </Box>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    );
  }
}

export default withStyles(styles)(KitchenAccountManagementMenu);
