import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomButton from "./CustomButton.web";
import CustomDialog from "./CustomDialog.web";
import { WithTranslation, withTranslation } from "react-i18next";

export interface Props extends WithStyles, WithTranslation {
  open: boolean;

  onClose(): void;
  onLogout(): void;
}

const styles = (theme: Theme) =>
  createStyles({
    actions: {
      display: "flex",
      alignItems: "center",
      gap: 16,
    },

    cancel: {
      color: "#FF6666",
      backgroundColor: "#FFEAEA",
    },
  });

export class AdminLogOutDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, open, t, i18n, onClose, onLogout } = this.props;

    return (
      <CustomDialog
        dir={i18n.language === "ar" ? "rtl" : ""}
        width={600}
        open={open}
        title={t("Are you sure you want to Logout?")}
        onClose={onClose}
        actions={
          <Box className={classes.actions}>
            <CustomButton
              className={classes.cancel}
              variant="contained"
              data-test-id="cancel-btn"
              onClick={onClose}
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              data-test-id="logout-btn"
              color="primary"
              variant="contained"
              onClick={onLogout}
            >
              {t("Logout")}
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(withTranslation()(AdminLogOutDialog));
