// Customizable Area Start
import React from "react";
import {
  Box,
  withStyles,
  createStyles,
  Breadcrumbs,
  Portal,
} from "@material-ui/core";
import KitchenAccountManagementDetailController, {
  Props,
} from "./KitchenAccountManagementDetailController.web";
import KitchenAccountManagementDetailForm, {
  KitchenAccountManagementDetailFormMode,
} from "../../../components/src/KitchenAccountManagementDetailForm.web";
import { NavigateNext } from "@material-ui/icons";
import { Link } from "react-router-dom";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomSnackbar from "..//../../components/src/CustomSnackbar.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";

const styles = createStyles({
  container: {
    maxWidth: 650,
    widht: "100%",
  },

  breakcrumItemLink: {
    textDecoration: "none",
    cursor: "pointer",
  },

  breakcrumItemName: {
    color: "#FF6666 !important",
  },

  form: {
    marginTop: 24,
  },
});
// Customizable Area End

export class KitchenAccountManagementDetail extends KitchenAccountManagementDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { form, snackbar, loading } = this.state;
    const { classes } = this.props;

    return (
      <Box className={classes.container}>
        <CustomLoadingBackdrop open={loading} />

        <Box>
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              to="/kitchen/owner/user-management"
              className={classes.breakcrumItemLink}
            >
              <CustomTypography className={classes.breakcrumItemName}>
                User Management
              </CustomTypography>
            </Link>

            {(() => {
              switch (form.mode) {
                case KitchenAccountManagementDetailFormMode.Edit:
                  return <CustomTypography>Edit Details</CustomTypography>;

                case KitchenAccountManagementDetailFormMode.View:
                  return <CustomTypography>User’s details</CustomTypography>;

                default:
                  return <></>;
              }
            })()}
          </Breadcrumbs>
        </Box>

        <Box className={classes.form}>
          <KitchenAccountManagementDetailForm
            form={form}
            onCancel={this.onCancel}
            onSwitchMode={this.setPageMode}
            editDetail={this.updateUserDetail}
          />
        </Box>

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
          />
        </Portal>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(KitchenAccountManagementDetail);
// Customizable Area End
