import {
  Box,
  CircularProgress,
  ListItemText,
  MenuItem,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import CustomButton from "../CustomButton.web";
import CustomFormInputField from "../CustomFormInputField.web";
import KitchenDrawer from "../KitchenDrawer.web";
import { CustomFilterMenu } from "../CustomFilter.web";
import CustomFormSelectFieldWeb from "../CustomFormSelectField.web";
import {
  Values,
  schema,
} from "../../../blocks/loyaltysystem/src/LoyaltySystemCompensationsController.web";

interface Props extends WithStyles<any> {
  open: boolean;
  loading: boolean;
  listOrderNumber: CustomFilterMenu[];
  listBranch: CustomFilterMenu[];
  listCompensationReason: CustomFilterMenu[];
  onClose(): void;
  onSubmit(values: Values): void;
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
      paddingBlockStart: "2.5rem",
      gap: 24,

      [theme.breakpoints.down("lg")]: {
        paddingBlockStart: "1.5rem",
      },

      "& label, & .form__description": {
        fontFamily: "Raleway",
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 700,
        lineHeight: 22 / 14,
      },

      "& .form__description": {
        fontWeight: 400,
        marginBlock: 0,
      },

      "& .MuiInputBase-input::placeholder": {
        opacity: 1,
      },
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },
  });

export class CustomLoyaltySystemFormDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      classes,
      open,
      listBranch,
      listCompensationReason,
      listOrderNumber,
      loading,
      onClose,
      onSubmit,
    } = this.props;

    return (
      <KitchenDrawer
        open={open}
        title="Add Reward Point"
        onClose={onClose}
        bottom={
          <Box className={classes.formBottom}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              Cancel
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              form="formAddRewardPoint"
            >
              {loading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                "Submit"
              )}
            </CustomButton>
          </Box>
        }
        body={
          <Formik
            initialValues={schema.default()}
            validationSchema={schema}
            onSubmit={onSubmit}
            data-test-id="formikFormAddRewardPoint"
          >
            {(props) => {
              return (
                <Form
                  id="formAddRewardPoint"
                  data-test-id="formCompensation"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <div className={classes.formInner}>
                    <div>
                      <CustomFormSelectFieldWeb
                        fullWidth
                        name="order_id"
                        label="Select Order Number"
                        id="formSelectOrderNumber"
                        data-test-id="formSelectOrderNumber"
                        variant="outlined"
                        error={
                          props.touched.order_id && !!props.errors.order_id
                        }
                        inputProps={{
                          placeholder: "Select Order Number",
                        }}
                      >
                        {listOrderNumber.map((listOrderNumberOption) => (
                          <MenuItem
                            key={listOrderNumberOption.id}
                            value={listOrderNumberOption.value}
                          >
                            <ListItemText
                              primary={listOrderNumberOption.name}
                            />
                          </MenuItem>
                        ))}
                      </CustomFormSelectFieldWeb>
                    </div>

                    <div>
                      <CustomFormSelectFieldWeb
                        fullWidth
                        name="store_id"
                        label="Select Branch"
                        id="formSelectBranch"
                        data-test-id="formSelectBranch"
                        variant="outlined"
                        error={
                          props.touched.store_id && !!props.errors.store_id
                        }
                        inputProps={{
                          placeholder: "Select Branch",
                        }}
                      >
                        {listBranch.map((listBranchOption) => (
                          <MenuItem
                            key={listBranchOption.id}
                            value={listBranchOption.value}
                          >
                            <ListItemText primary={listBranchOption.name} />
                          </MenuItem>
                        ))}
                      </CustomFormSelectFieldWeb>
                    </div>

                    <div>
                      <CustomFormSelectFieldWeb
                        fullWidth
                        name="compensation_id"
                        label="Compensation Reason"
                        id="formSelectReason"
                        data-test-id="formSelectReason"
                        variant="outlined"
                        error={
                          props.touched.compensation_id &&
                          !!props.errors.compensation_id
                        }
                        inputProps={{
                          placeholder: "Compensation Reason",
                        }}
                      >
                        {listCompensationReason.map(
                          (listCompensationReasonOption) => (
                            <MenuItem
                              key={listCompensationReasonOption.id}
                              value={listCompensationReasonOption.value}
                            >
                              <ListItemText
                                primary={listCompensationReasonOption.name}
                              />
                            </MenuItem>
                          )
                        )}
                      </CustomFormSelectFieldWeb>
                    </div>

                    <div>
                      <CustomFormInputField
                        fullWidth
                        name="notes"
                        multiline
                        maxRows={3}
                        minRows={3}
                        label="Notes"
                        placeholder="Enter Notes / Comments on Order"
                        variant="outlined"
                        error={props.touched.notes && !!props.errors.notes}
                        data-test-id="input"
                      />
                    </div>

                    <div>
                      <label htmlFor="inputPrice">Compensation Amount</label>
                      <p className="form__description">
                        Include GST/VAT in prices if applicable.
                      </p>
                      <CustomFormInputField
                        id="inputPrice"
                        fullWidth
                        name="compensation_amount"
                        multiline
                        placeholder="Enter Price in SAR"
                        variant="outlined"
                        error={
                          props.touched.compensation_amount &&
                          !!props.errors.compensation_amount
                        }
                        data-test-id="input"
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}

export default withStyles(styles)(CustomLoyaltySystemFormDrawer);
