// Customizable Area Start
import React from "react";

import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

import { ChevronRightRounded } from "@material-ui/icons";
import { Formik } from "formik";

import CustomButton from "../../../components/src/CustomButton.web";
import CustomFormInputField from "../../../components/src/CustomFormInputField.web";
import CustomFormSelectField from "../../../components/src/CustomFormSelectField.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";

import {
  Breadcrumbs,
  ButtonBase,
  Checkbox,
  CircularProgress,
  ListItemText,
  MenuItem,
  WithStyles,
} from "@material-ui/core";
import { calenderIcon, iconCheck, iconUncheck } from "./assets";
import {
  Category,
  DiscountType,
  DiscountValue,
  ValidationSchema,
  validationSchema,
} from "./PromocodesPromotionsListController.web";

interface Props extends WithStyles {
  optionCategories: Category[];
  optionDiscountValues: DiscountValue[];
  optionDiscountTypes: DiscountType[];
  loading: boolean;
  handleCancel(): void;
  closeSnackbar(): void;
  handleSubmit(values: ValidationSchema): void;
}

const styles = (theme: Theme) =>
  createStyles({
    btnCancel: {
      color: "#FFFFFF",
      backgroundColor: "#333333",
      "&:hover": {
        backgroundColor: "rgb(91, 91, 91)",
      },
    },
    inputDescription: {
      "& .MuiOutlinedInput-multiline": {
        padding: 0,
      },
    },
    breadcrumbs: {
      "& .MuiButtonBase-root": {
        padding: 0,
      },
      "& .MuiButton-label": {
        fontSize: "0.875rem",
        fontFamily: "Raleway",
        lineHeight: 22 / 14,
        fontWeight: 400,
      },
    },
    form: {
      width: "100%",
      maxWidth: 650,
      marginTop: 24,
      "& .form__actions": {
        display: "flex",
        alignItems: "center",
        marginBlockStart: 24,
        gap: "0.75rem",
        "& .form__actions__help-text": {
          marginInlineEnd: "auto",
          "& button": { color: "#FF6666" },
        },
        "& .form__actions__button": {
          width: "100%",
          maxWidth: 86,
        },
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          "& .form__actions__button": {
            maxWidth: "100%",
          },
        },
      },
      "& .form__input__title": {
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: 1.5,
        marginBlockStart: "2.125rem",
      },
      "& .form__input__description": {
        lineHeight: 24 / 14,
        marginBottom: 12,
      },
      "& .form__input": {
        marginBlockStart: "0.75rem",
      },
      "& .form__group": {
        display: "flex",
        width: "100%",
        alignItems: "center",
        "&.align-start": {
          alignItems: "flex-start",
        },
        gap: "1rem",
        "& > div": {
          marginBlockStart: 0,
          "& .MuiOutlinedInput-input": {
            padding: ".6250em 2.5em .6250em .5em",
          },

          "& .MuiInputBase-root": {
            height: 44,
          },
        },
        "& > *": {
          flex: 1,
          minWidth: 0,
        },
        "& .MuiFormHelperText-contained": {
          marginInline: 0,
        },
      },
    },
    formInputOption: {
      "& > span": {
        fontFamily: "Raleway",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        marginBlock: 0,
      },
    },
    formMultiselect: {
      "& ul": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "2rem",
        maxHeight: 220,
        backgroundColor: "#F8F8F8",
        "& li, & li.MuiListItem-root.Mui-selected": {
          backgroundColor: "#F8F8F8",
        },
        "& li:first-child": {
          gridColumn: "1/-1",
        },

        [theme.breakpoints.down("xs")]: {
          gridTemplateColumns: "1fr",
        },
      },
    },
    formMultiselectInput: {
      "&.MuiSelect-root": {
        display: "block",
      },
    },
  });

export class PromocodesPromotionsCreate extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      classes,
      optionCategories,
      optionDiscountTypes,
      optionDiscountValues,
      loading,
      handleCancel,
      handleSubmit,
    } = this.props;

    const minDate = new Date();

    return (
      <>
        <Breadcrumbs
          className={classes.breadcrumbs}
          separator={<ChevronRightRounded />}
        >
          <CustomButton variant="text" color="primary">
            Promotions
          </CustomButton>
          <CustomButton variant="text" onClick={handleCancel}>
            Create Promotions
          </CustomButton>
        </Breadcrumbs>

        <Formik
          initialValues={validationSchema.default()}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            return (
              <form className={classes.form} onSubmit={props.handleSubmit}>
                <CustomFormInputField
                  label="Promotions Name"
                  variant="outlined"
                  placeholder="Enter name"
                  fullWidth
                  name="name"
                  value={props.values.name}
                  error={props.touched.name && !!props.errors.name}
                />
                <CustomFormInputField
                  variant="outlined"
                  placeholder="Enter description"
                  multiline
                  minRows={3}
                  maxRows={3}
                  fullWidth
                  name="description"
                  value={props.values.description}
                  className={classes.inputDescription}
                  error={
                    props.touched.description && !!props.errors.description
                  }
                />

                <CustomTypography className="form__input__title">
                  All Categories
                </CustomTypography>
                <CustomTypography className="form__input__description">
                  Select the categories which where you would like to add
                  promotions
                </CustomTypography>

                <CustomFormSelectField
                  fullWidth
                  name="sub_category_ids"
                  label="Select one or more"
                  variant="outlined"
                  error={
                    props.touched.sub_category_ids &&
                    !!props.errors.sub_category_ids
                  }
                  multiple
                  inputProps={{
                    renderValue: (selected: string[]) =>
                      optionCategories
                        .filter((category) => selected.includes(category.id))
                        .map((category) => category.attributes.name)
                        .join(", "),
                    placeholder: "Select Categories",
                    className: classes.formMultiselectInput,
                  }}
                  MenuProps={{
                    className: classes.formMultiselect,
                    PaperProps: {
                      className: classes.formMultiselectContainer,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  onChange={(event) => {
                    const value = event.target.value as string[];
                    if (value[value.length - 1] === "All") {
                      props.setFieldValue(
                        "sub_category_ids",
                        props.values.sub_category_ids.length ===
                          optionCategories.length
                          ? []
                          : optionCategories.map((category) => category.id)
                      );
                      return;
                    }
                    props.setFieldValue("sub_category_ids", value);
                  }}
                >
                  <MenuItem value="All">
                    <Checkbox
                      icon={<img src={iconUncheck} width={20} />}
                      checkedIcon={<img src={iconCheck} width={20} />}
                      checked={
                        optionCategories.length ===
                        props.values.sub_category_ids.length
                      }
                    />
                    <ListItemText primary={"Select All"} />
                  </MenuItem>
                  {optionCategories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      <Checkbox
                        icon={<img src={iconUncheck} width={20} />}
                        checkedIcon={<img src={iconCheck} width={20} />}
                        checked={
                          props.values.sub_category_ids.indexOf(category.id) >
                          -1
                        }
                      />
                      <ListItemText primary={category.attributes.name} />
                    </MenuItem>
                  ))}
                </CustomFormSelectField>

                <CustomTypography className="form__input__title">
                  Discount Type
                </CustomTypography>
                <CustomTypography className="form__input__description">
                  Exploring Discount Varieties
                </CustomTypography>

                <div className="form__group align-start">
                  <CustomFormSelectField
                    fullWidth
                    name="discount_type_id"
                    label="Discount Type"
                    variant="outlined"
                    error={
                      props.touched.discount_type_id &&
                      !!props.errors.discount_type_id
                    }
                  >
                    {optionDiscountTypes.map((discountType) => (
                      <MenuItem key={discountType.id} value={discountType.id}>
                        <ListItemText
                          primary={discountType.attributes.discount_type}
                          className={classes.formInputOption}
                        />
                      </MenuItem>
                    ))}
                  </CustomFormSelectField>

                  <CustomFormSelectField
                    fullWidth
                    name="discount_value_id"
                    label="Discount Value"
                    variant="outlined"
                    error={
                      props.touched.discount_value_id &&
                      !!props.errors.discount_value_id
                    }
                  >
                    {optionDiscountValues.map((discountValue) => (
                      <MenuItem key={discountValue.id} value={discountValue.id}>
                        <ListItemText
                          primary={discountValue.attributes.discount_value}
                          className={classes.formInputOption}
                        />
                      </MenuItem>
                    ))}
                  </CustomFormSelectField>
                </div>

                <CustomTypography className="form__input__title">
                  Schedule promotions
                </CustomTypography>
                <CustomTypography className="form__input__description">
                  Strategic Timing: Scheduling Your Promotions
                </CustomTypography>

                <div id="startDatePortal"></div>
                <div id="endDatePortal"></div>
                <div className="form__group align-start">
                  <CustomDatePicker
                    onChange={(date) => {
                      props.setFieldValue("from", date);
                    }}
                    showTimeSelect
                    placeholderText="Start Date"
                    portalId="startDatePortal"
                    name="from"
                    selected={props.values.from}
                    dateFormat={"dd/MM/yyyy hh:mma"}
                    minDate={minDate}
                    customInput={
                      <CustomFormInputField
                        label="Start Date"
                        variant="outlined"
                        fullWidth
                        error={props.touched.from && !!props.errors.from}
                        InputProps={{
                          endAdornment: <img src={calenderIcon} />,
                        }}
                      />
                    }
                  />
                  <CustomDatePicker
                    onChange={(date) => {
                      props.setFieldValue("to", date);
                    }}
                    showTimeSelect
                    placeholderText="End Date"
                    portalId="endDatePortal"
                    name="to"
                    selected={props.values.to}
                    dateFormat={"dd/MM/yyyy hh:mma"}
                    minDate={minDate}
                    customInput={
                      <CustomFormInputField
                        label="End Date"
                        variant="outlined"
                        fullWidth
                        error={props.touched.to && !!props.errors.to}
                        InputProps={{
                          endAdornment: <img src={calenderIcon} />,
                        }}
                      />
                    }
                  />
                </div>

                <div className="form__actions">
                  <CustomTypography className="form__actions__help-text">
                    Need help creating a promotion?{" "}
                    <ButtonBase>Click here</ButtonBase>
                  </CustomTypography>

                  <CustomButton
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.btnCancel + " form__actions__button"}
                    onClick={handleCancel}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="form__actions__button"
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={"1.5rem"} />
                    ) : (
                      "Save"
                    )}
                  </CustomButton>
                </div>
              </form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withStyles(styles)(PromocodesPromotionsCreate);
// Customizable Area End
