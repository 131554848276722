import {
  SwitchProps,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  Switch,
} from "@material-ui/core";

import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
    },

    switchBase: {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",

      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#FFFFFF",

        "& + .MuiSwitch-track": {
          backgroundColor: "#FF6666",
          opacity: 1,
          border: 0,
        },

        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },

      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #FFFFFF",
      },

      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },

    thumb: {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },

    track: {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  });

export interface Props extends WithStyles<any> {}

export type CustomSwitchProps = SwitchProps & Props;

class CustomSwitch extends React.Component<CustomSwitchProps> {
  constructor(props: CustomSwitchProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, ...props } = this.props;

    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
          disabled: classes.disabled,
        }}
        {...props}
      />
    );
  }
}

export default withStyles(styles)(CustomSwitch);
