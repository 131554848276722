import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomButton from "./CustomButton.web";
import CustomFormInputField from "./CustomFormInputField.web";
import CustomDialog from "./CustomDialog.web";

const validationSchema = Yup.object({
  name: Yup.string().nullable().required("Please enter name"),
  price: Yup.number()
    .nullable()
    .required("Please enter price")
    .positive("Please enter valid price")
    .typeError("Please enter valid price"),
});

export interface Props extends WithStyles {
  dialog: KitchenMenuItemVariantFormDialogProps;

  onClose(): void;
  addItemVariant(values: { name: string; price: number }): void;
  editItemVariant(values: { name: string; price: number }): void;
}

export enum KitchenMenuItemVariantFormDialogMode {
  Add = "add",
  Edit = "edit",
}

export interface KitchenMenuItemVariantFormDialogProps {
  mode: KitchenMenuItemVariantFormDialogMode;
  open: boolean;
  loading: boolean;
  form: {
    id: string;
    name: string;
    price: number;
  };
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginBottom: 0,
    },

    formNameGroup: {
      marginTop: 20,
      display: "flex",
      gap: 15,

      "& > div": {
        marginTop: "0 !important",
      },
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,
      width: "100%",
      justifyContent: "center",

      "& > button": {
        width: 120,
      },
    },

    formPriceInput: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },
  });

export class KitchenMenuItemVariantFormDialog extends React.Component<Props> {
  private formProps?: FormikProps<any> = undefined;

  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, dialog, onClose, addItemVariant, editItemVariant } =
      this.props;
    const { open, form, mode } = dialog;

    return (
      <CustomDialog
        width={400}
        open={open}
        title={
          mode === KitchenMenuItemVariantFormDialogMode.Edit
            ? "Edit Variant"
            : "Add Variant"
        }
        onClose={onClose}
        content={
          <Formik
            initialValues={{
              name: form.name,
              price: form.price,
            }}
            validationSchema={validationSchema}
            onSubmit={
              mode === KitchenMenuItemVariantFormDialogMode.Edit
                ? editItemVariant
                : addItemVariant
            }
            data-test-id="kitchen-item-formik"
          >
            {(props) => {
              this.formProps = props;

              return (
                <Form
                  data-test-id="kitchen-item-form"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <Box className={classes.formInner}>
                    <CustomFormInputField
                      fullWidth
                      name="name"
                      label="Variant Name"
                      placeholder="Variant Name"
                      variant="outlined"
                      error={props.touched.name && !!props.errors.name}
                      data-test-id="name-input"
                      required
                    />

                    <CustomFormInputField
                      className={classes.formPriceInput}
                      fullWidth
                      name="price"
                      label="Variant Price Addition"
                      placeholder="Enter Price in SAR"
                      variant="outlined"
                      error={props.touched.price && !!props.errors.price}
                      data-test-id="price-input"
                      required
                      value={props.values.price || ""}
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        }
        actions={
          <Box className={classes.formBottom}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              Cancel
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              onClick={() => {
                this.formProps?.handleSubmit();
              }}
            >
              Done
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(KitchenMenuItemVariantFormDialog);
