// Customizable Area Start
import React from "react";
import LocationBranchStatusController, {
  BRANCH_STATUS,
  Props,
} from "./LocationBranchStatusController.web";

import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  Tab,
} from "@material-ui/core";
import {
  Close,
  ImportExportRounded,
  Refresh,
  Search,
} from "@material-ui/icons";

import * as Yup from "yup";
import { Formik } from "formik";

import CustomTabs from "../../../components/src/CustomTabs";
import CustomInputField from "../../../components/src/CustomInputField.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomFormInputField from "../../../components/src/CustomFormInputField.web";
import CustomFormCheckBox from "../../../components/src/CustomFormCheckBox.web";
import LocationBranchStatusImagesUpload from "../../../components/src/location/CustomLocationImagesUpload.web";
import CustomLocationBranchRow from "../../../components/src/location/CustomLocationBranchRow.web";
import CustomLoadingBackdropWeb from "../../../components/src/CustomLoadingBackdrop.web";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Please enter restaurant name.")
    .transform((name) => name.trim()),
  branch_name: Yup.string().notRequired(),
  city: Yup.string().notRequired(),
  location: Yup.string().notRequired(),
  about_us: Yup.string().required("Please enter about restaurant."),
  status: Yup.string()
    .oneOf([BRANCH_STATUS.OPEN, BRANCH_STATUS.BUSY, BRANCH_STATUS.CLOSED])
    .default(BRANCH_STATUS.CLOSED),
  images: Yup.array(Yup.mixed()),
});

export type ValidationSchema = Yup.InferType<typeof validationSchema>;

const styles = (theme: Theme) =>
  createStyles({
    content: {
      padding: 12,
      borderRadius: 8,
      border: "1px solid #EDEDED",
      borderTopLeftRadius: 0,
      "& .msgNoBranches": {
        textAlign: "center",
      },
    },
    btnAddNewBranch: {
      "&.MuiTypography-root": {
        fontSize: "1rem",
        lineHeight: 1.5,
        marginLeft: "auto",
        alignSelf: "center",
      },
    },
    btnCancel: {
      color: "#FFFFFF",
      backgroundColor: "#333333",
      "&:hover": {
        backgroundColor: "rgb(91, 91, 91)",
      },
    },
    actions: {
      display: "flex",
      paddingBlockEnd: 12,
      gap: 10,
      "& :nth-last-child(2)": {
        marginLeft: "auto",
      },
    },
    drawer: {
      "& .MuiPaper-root.MuiDrawer-paper": {
        width: "100%",
        maxWidth: 378,
      },
      "& .drawer__header": {
        padding: "1.5rem",
        display: "flex",
        alignItems: "center",
        gap: 10,
        "& .drawer__header__title": {
          flex: 1,
          fontWeight: 700,
          fontSize: 24,
          lineHeight: 32 / 24,
        },
      },
      "& .drawer__body": {
        padding: "1.5rem",
        "& .no-padding .MuiOutlinedInput-multiline": {
          padding: 0,
        },
        "& .input-label": {
          fontWeight: 700,
          fontSize: 14,
          lineHeight: "22px",
          color: "#333333",
          fontFamily: "Raleway",
          display: "block",
          marginBlockStart: 16,
          marginBlockEnd: 10,
        },
      },
      "& .drawer__footer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 16,
        padding: "1.875rem 1.5rem",
        "& > *": {
          flex: 1,
        },
      },
    },
  });

export class LocationBranchStatus extends LocationBranchStatusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <CustomLoadingBackdropWeb open={this.state.loading} />
        <CustomTabs
          variant="scrollable"
          scrollButtons="auto"
          value={this.state.filter}
        >
          <Tab
            data-test-id="all-tab"
            label={`All (${this.state.statusCount.all})`}
            value={BRANCH_STATUS.ALL}
            onClick={() => this.changeMenuTab(BRANCH_STATUS.ALL)}
          />
          <Tab
            data-test-id="open-tab"
            label={`Open (${this.state.statusCount.open})`}
            value={BRANCH_STATUS.OPEN}
            onClick={() => this.changeMenuTab(BRANCH_STATUS.OPEN)}
          />
          <Tab
            data-test-id="busy-tab"
            label={`Busy (${this.state.statusCount.busy})`}
            value={BRANCH_STATUS.BUSY}
            onClick={() => this.changeMenuTab(BRANCH_STATUS.BUSY)}
          />
          <Tab
            data-test-id="closed-tab"
            label={`Closed (${this.state.statusCount.close})`}
            value={BRANCH_STATUS.CLOSED}
            onClick={() => this.changeMenuTab(BRANCH_STATUS.CLOSED)}
          />
          <CustomTypography
            variant="button"
            className={classes.btnAddNewBranch}
            onClick={this.openSidebar}
          >
            Add New Branch
          </CustomTypography>
        </CustomTabs>

        <Box className={classes.content}>
          <div className={classes.actions}>
            <CustomInputField
              value={this.state.search}
              onChange={(event) =>
                this.setState({ search: event.target.value })
              }
              variant="outlined"
              placeholder="Filter branch here"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
                onKeyDown: this.handleKeydown,
              }}
            />
            <CustomButton
              startIcon={<ImportExportRounded />}
              onClick={this.handleSort}
            >
              Sort
            </CustomButton>

            <CustomButton
              variant="contained"
              color="primary"
              startIcon={<Refresh />}
              onClick={this.handleRefresh}
            >
              Refresh
            </CustomButton>
          </div>

          {!this.state.loading &&
            this.state.branches.map((restaurant) => {
              return (
                <CustomLocationBranchRow
                  key={restaurant.id}
                  restaurant={restaurant}
                  handleUpdate={this.openSidebar}
                />
              );
            })}

          {!this.state.loading && this.state.branches.length === 0 && (
            <CustomTypography className="msgNoBranches">
              No Branches found
            </CustomTypography>
          )}

          <Box display={"flex"} justifyContent={"flex-end"} mt={3}>
            <CustomPagination
              variant="text"
              page={this.state.pagination.page}
              total={this.state.pagination.totalCount}
              count={this.state.pagination.totalPages}
              limit={this.state.pagination.limit}
              showOffset
              onChange={(event, page) => this.changePage(page)}
            />
          </Box>
        </Box>

        <Drawer
          open={this.state.isSidebarOpen}
          anchor="right"
          className={classes.drawer}
          container={window.document.body}
        >
          <div className="drawer__header">
            <CustomTypography className="drawer__header__title">
              {this.state.editID ? "Update information" : "Add new branch"}
            </CustomTypography>
            <IconButton onClick={this.closeSidebar}>
              <Close />
            </IconButton>
          </div>
          <Divider />

          <Formik<ValidationSchema>
            data-test-id="form"
            initialValues={
              this.state.editID
                ? this.getEditBranch(this.state.editID)
                : validationSchema.default()
            }
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            innerRef={(instance) => {
              this.formikRef = instance;
            }}
          >
            {(props) => {
              return (
                <form onSubmit={props.handleSubmit}>
                  <div className="drawer__body">
                    <label className="input-label">Upload item photo</label>
                    <LocationBranchStatusImagesUpload
                      initImages={
                        this.state.editID
                          ? (props.values.images as unknown as {
                              id: number;
                              url: string;
                            }[])
                          : undefined
                      }
                      handleChange={(images) => {
                        props.setFieldValue("images", images);
                      }}
                    />

                    <CustomFormInputField
                      label="Restaurant name"
                      variant="outlined"
                      placeholder="Enter restaurant name"
                      fullWidth
                      name="name"
                      value={props.values.name}
                      error={props.touched.name && !!props.errors.name}
                    />
                    <CustomFormInputField
                      label="Street name"
                      variant="outlined"
                      placeholder="Enter street name"
                      fullWidth
                      name="branch_name"
                      value={props.values.branch_name}
                      error={
                        props.touched.branch_name && !!props.errors.branch_name
                      }
                    />
                    <CustomFormInputField
                      label="City"
                      variant="outlined"
                      placeholder="Enter city"
                      fullWidth
                      name="city"
                      value={props.values.city}
                      error={props.touched.city && !!props.errors.city}
                    />
                    <CustomFormInputField
                      label="Locations"
                      variant="outlined"
                      placeholder="Enter locations"
                      fullWidth
                      name="location"
                      value={props.values.location}
                      error={props.touched.location && !!props.errors.location}
                    />
                    <CustomFormInputField
                      className="no-padding"
                      label="About Restaurant"
                      variant="outlined"
                      placeholder="Enter about restaurant"
                      fullWidth
                      name="about_us"
                      value={props.values.about_us}
                      error={props.touched.about_us && !!props.errors.about_us}
                      multiline
                      minRows={3}
                      maxRows={3}
                    />

                    <label className="input-label">
                      Restaurant Running Status
                    </label>
                    <CustomFormCheckBox
                      name="status"
                      label="Close"
                      checked={props.values.status === BRANCH_STATUS.CLOSED}
                      onChange={() =>
                        props.setFieldValue("status", BRANCH_STATUS.CLOSED)
                      }
                    />
                    <CustomFormCheckBox
                      name="status"
                      label="Busy"
                      checked={props.values.status === BRANCH_STATUS.BUSY}
                      onChange={() =>
                        props.setFieldValue("status", BRANCH_STATUS.BUSY)
                      }
                    />
                    <CustomFormCheckBox
                      name="status"
                      label="Open"
                      checked={props.values.status === BRANCH_STATUS.OPEN}
                      onChange={() =>
                        props.setFieldValue("status", BRANCH_STATUS.OPEN)
                      }
                    />
                  </div>
                  <Divider />
                  <div className="drawer__footer">
                    <CustomButton
                      variant="contained"
                      color="primary"
                      type="button"
                      className={classes.btnCancel}
                      onClick={this.closeSidebar}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {this.state.loadingWhenCreatingOrUpdating ? (
                        <CircularProgress color="inherit" size={"1.5rem"} />
                      ) : (
                        "Save"
                      )}
                    </CustomButton>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Drawer>

        <CustomSnackbar
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.snackbar.open}
          type={this.state.snackbar.type}
          message={this.state.snackbar.message}
          onClose={this.closeSnackbar}
        />
      </>
    );
  }
}

export default withStyles(styles)(LocationBranchStatus);
// Customizable Area End
