import {
  SwitchProps,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  Switch,
} from "@material-ui/core";

import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 115,
      height: 34,
      padding: 0,
      "&::after": {
        content: "var(--text)",
        position: "absolute",
        fontSize: "1rem",
        lineHeight: 1.17375,
        top: "50%",
        left: "var(--left)",
        right: "var(--right)",
        transform: "translateY(-50%)",
        color: "#FFFFFF",
      },
    },

    switchBase: {
      padding: 0,
      margin: "5px 8px",
      transitionDuration: "300ms",

      "&.Mui-checked": {
        "--offset": `calc(100% + ${8 + 8 + 24 + 12}px)`,
        transform: "translateX(var(--offset))",
        color: "#FFFFFF",

        "& + .MuiSwitch-track": {
          backgroundColor: "#FF6666",
          opacity: 1,
          border: 0,
        },

        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },

      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #FFFFFF",
      },

      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },

    thumb: {
      boxSizing: "border-box",
      width: 24,
      height: 24,
    },

    track: {
      borderRadius: 26 / 2,
      backgroundColor: "333333",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },

    input: {
      width: "600%",
      left: 0,
      "&[checked]": {
        transform: "translateX(-50%)",
      },
    },
  });

export interface Props extends WithStyles<any> {
  checkedText: string;
  uncheckedText: string;
}

export type CustomSwitchWithTextProps = SwitchProps & Props;

class CustomSwitchWithText extends React.Component<CustomSwitchWithTextProps> {
  constructor(props: CustomSwitchWithTextProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, checkedText, uncheckedText, ...props } = this.props;
    const textValueStyle: any = {
      "--text": this.props.checked ? `"${checkedText}"` : `"${uncheckedText}"`,
      "--left": this.props.checked ? "8px" : "unset",
      "--right": !this.props.checked ? "8px" : "unset",
    };

    return (
      <div style={textValueStyle}>
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
            disabled: classes.disabled,
            input: classes.input,
          }}
          {...props}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CustomSwitchWithText);
