import {
  Box,
  Drawer,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography.web";
import { Close } from "@material-ui/icons";
import { Invoice } from "../../../blocks/paymentadmin2/src/Paymentadmin2FinanceViewController.web";
import { toRem } from "../Utils.web";
import CustomButton from "../CustomButton.web";
import { Form, Formik } from "formik";
import CustomFormInputFieldWeb from "../CustomFormInputField.web";
import CustomRadio from "../CustomRadio.web";
import { date, number, object, string } from "yup";
import CustomDatePickerWeb from "../CustomDatePicker.web";

const validationSchema = object({
  first_name: string(),
  last_name: string(),
  card_number: string(),
  cvc: number(),
  expiration: date(),
  method: string().default("0"),
});

export interface Props extends WithStyles<any> {
  open: boolean;
  invoice: Invoice | null;
  onClose(): void;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      height: "100%",

      "& *": {
        boxSizing: "border-box",
      },
    },

    flexBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    head: {
      borderBottom: "1px solid #E2E8F0",
      padding: 24,
      "& .head__numberText": {
        fontSize: toRem(16),
        lineHeight: 2,
      },
      "& .head__number": {
        fontFamily: "Inter",
        fontWeight: 700,
        "&.head__amount": {
          textAlign: "right",
        },
      },

      "& .head__title": {
        fontWeight: 700,
      },
      "& .head__invoiceInfo": {
        display: "flex",
        gap: 42,
        alignItems: "center",
        justifyContent: "flex-start",
        "& > :last-child": {
          marginInlineStart: "auto",
        },
      },
    },

    body: {
      paddingBlockEnd: 24,
      flex: 1,
      overflow: "auto",
    },

    bottom: {
      borderTop: "1px solid #E2E8F0",
      padding: 24,
      display: "flex",
      justifyContent: "flex-between",
      alignItems: "center",
      gap: 16,
      "& > *": { flex: 1 },
      "& > :first-child": {
        backgroundColor: "#333333",
      },
    },

    fontInter: {
      fontFamily: "Inter",
    },

    root: {
      "& .MuiPaper-root.MuiDrawer-paper": {
        width: "100%",
        maxWidth: 765,
      },
    },

    actions: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 10,
      paddingInlineEnd: 16,
      "& .action__now": {
        textDecoration: "underline",
      },
    },
    form: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gap: 24,
      paddingInline: 24,
      "& .MuiFormLabel-root.MuiInputLabel-root": {
        color: "#FF6666",
      },
      "& .form__radioGroup": {
        gridColumn: "1/-1",
        flexDirection: "column",
      },
      "& .form__inputName": {
        gridColumn: "span 6",
      },
      "& .form__inputCardNumber, & .form__inputDate": {
        gridColumn: "span 8",
      },
      "& .form__inputCVC": {
        gridColumn: "span 4",
      },
    },
  });

export class CustomPaymentadmin2FinacePayment extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, open, invoice, onClose } = this.props;

    if (!invoice) {
      return <></>;
    }

    return (
      <Drawer
        className={classes.root}
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <div className={classes.container}>
          <Box className={classes.head}>
            <div className={classes.flexBetween}>
              <div>
                <CustomTypography variant="h2" className={"head__title"}>
                  Make a payment for invoice
                </CustomTypography>
              </div>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </div>

            <Box className="head__invoiceInfo" marginTop={"35px"}>
              <div>
                <CustomTypography className="head__numberText">
                  Invoice Number
                </CustomTypography>
                <CustomTypography className={"head__number"}>
                  {invoice.attributes.amount}
                </CustomTypography>
              </div>
              <div>
                <CustomTypography className="head__numberText">
                  Invoice Month
                </CustomTypography>
                <CustomTypography className={"head__number"}>
                  {invoice.attributes.formatted_month}
                </CustomTypography>
              </div>
              <div>
                <CustomTypography className="head__numberText">
                  Invoice of (SAR)
                </CustomTypography>
                <CustomTypography className={"head__number head__amount"}>
                  {invoice.attributes.amount}
                </CustomTypography>
              </div>
            </Box>
          </Box>

          <Box className={classes.body}>
            <Formik
              initialValues={validationSchema.default()}
              validationSchema={validationSchema}
              onSubmit={() => {
                onClose();
              }}
            >
              {(props) => {
                return (
                  <Form id="formPayment" className={classes.form}>
                    <RadioGroup
                      className="form__radioGroup"
                      name="method"
                      value={props.values.method}
                      onChange={(event, value) => {
                        props.setFieldValue("method", value);
                      }}
                    >
                      <FormControlLabel
                        value="0"
                        control={<CustomRadio />}
                        label={
                          <CustomTypography>
                            Debit or Credit card
                          </CustomTypography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<CustomRadio />}
                        label={
                          <CustomTypography>
                            Third party payment gateway
                          </CustomTypography>
                        }
                      />
                    </RadioGroup>
                    <div className="form__inputName">
                      <CustomFormInputFieldWeb
                        label="First name"
                        variant="outlined"
                        placeholder="Enter first name"
                        fullWidth
                        name="first_name"
                        value={props.values.first_name}
                        error={
                          props.touched.first_name && !!props.errors.first_name
                        }
                      />
                    </div>
                    <div className="form__inputName">
                      <CustomFormInputFieldWeb
                        label="Last name"
                        variant="outlined"
                        placeholder="Enter last name"
                        fullWidth
                        name="last_name"
                        value={props.values.last_name}
                        error={
                          props.touched.last_name && !!props.errors.last_name
                        }
                      />
                    </div>
                    <div className="form__inputCardNumber">
                      <CustomFormInputFieldWeb
                        label="Card Number"
                        variant="outlined"
                        placeholder="Enter Card Number"
                        fullWidth
                        name="card_number"
                        value={props.values.card_number}
                        error={
                          props.touched.card_number &&
                          !!props.errors.card_number
                        }
                      />
                    </div>
                    <div className="form__inputCVC">
                      <CustomFormInputFieldWeb
                        label="CVC"
                        variant="outlined"
                        placeholder="Enter CVC"
                        fullWidth
                        name="cvc"
                        inputMode="numeric"
                        InputProps={{
                          type: "number",
                        }}
                        value={props.values.cvc}
                        error={props.touched.cvc && !!props.errors.cvc}
                      />
                    </div>
                    <div className="form__inputDate">
                      <CustomDatePickerWeb
                        onChange={(date) => {
                          props.setFieldValue("expiration", date);
                        }}
                        showMonthYearPicker
                        placeholderText="MM/YY"
                        name="from"
                        selected={props.values.expiration}
                        dateFormat={"MM/yy"}
                        minDate={new Date()}
                        customInput={
                          <CustomFormInputFieldWeb
                            label="Card Expiration"
                            variant="outlined"
                            fullWidth
                            error={
                              props.touched.expiration &&
                              !!props.errors.expiration
                            }
                          />
                        }
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Box>

          <Box className={classes.bottom}>
            <CustomButton variant="contained" color="primary" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              form="formPayment"
              type="submit"
            >
              Pay now
            </CustomButton>
          </Box>
        </div>
      </Drawer>
    );
  }
}

export default withStyles(styles)(CustomPaymentadmin2FinacePayment);
