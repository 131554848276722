// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { WithStyles } from "@material-ui/core";

import moment from "moment";
import { DateSchema, InferType, array, date, object, string } from "yup";

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
}

export const validationSchema = object({
  name: string()
    .required("Please enter name")
    .transform((name) => name.trim()),
  description: string(),
  sub_category_ids: array(string()).default([]),
  discount_type_id: string().required("Please enter discount"),
  discount_value_id: string().required("Please enter discount"),
  from: date().required("Please enter start date"),
  to: date()
    .required("Please enter end date")
    .when(
      "from",
      (from: Date, schema: DateSchema<Date>) =>
        from && schema.min(from, "End date should be greater than Start Date")
    ),
});

export type ValidationSchema = InferType<typeof validationSchema>;

type Promotion = Pick<PromotionRaw, "id"> & PromotionRaw["attributes"];

type PromotionRaw = {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    promotions_status: boolean;
    running_status: boolean;
    discount_type: string;
    discount_value: string;
    categories: string[];
    from: string;
    to: string;
  };
};

export type Category = {
  id: string;
  type: "sub_category";
  attributes: {
    id: number;
    name: string;
  };
};

export type DiscountValue = {
  id: "1";
  attributes: {
    discount_value: string;
  };
};

export type DiscountType = {
  id: "1";
  attributes: {
    discount_type: string;
  };
};

interface S {
  promotions: Promotion[];
  loading: boolean;
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  isAddPromotion: boolean;
  discountValues: DiscountValue[];
  loadingDiscountValues: boolean;
  discountTypes: DiscountType[];
  loadingDiscountTypes: boolean;
  categories: Category[];
  loadingCategories: boolean;
  loadingCreate: boolean;
}

interface SS {}

export default class PromocodesPromotionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetAllPromotions: string = "";
  apiGetAllDiscountValue: string = "";
  apiGetAllDiscountType: string = "";
  apiGetAllCategories: string = "";
  apiCreatePromotion: string = "";
  apiUpdatePromotion: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,
      pagination: {
        limit: 6,
        page: 1,
        totalCount: 10,
        totalPages: 6,
      },
      promotions: [],
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
      isAddPromotion: false,
      discountTypes: [],
      loadingDiscountTypes: false,
      discountValues: [],
      loadingDiscountValues: false,
      categories: [],
      loadingCategories: false,
      loadingCreate: false,
    };
  }

  getAllPromotions = () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAllPromotions = requestMessage.messageId;

    const params = new URLSearchParams();
    params.set("page", this.state.pagination.page.toString());
    params.set("per_page", this.state.pagination.limit.toString());

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllPromocodesEndPoint + "?" + params.toString()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAllPromocodesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllDiscountValue = () => {
    this.setState({ loadingDiscountValues: true });
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllDiscountValue = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllDiscountValueEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAllDiscountValueAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllDiscountType = () => {
    this.setState({ loadingDiscountTypes: true });
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllDiscountType = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllDiscountTypeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAllDiscountTypeAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllCategories = () => {
    this.setState({ loadingCategories: true });
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllCategories = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCategoriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAllCategoriesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSubmit = (values: ValidationSchema) => {
    this.createPromotion(values);
  };

  createPromotion = (values: ValidationSchema) => {
    this.setState({ loadingCreate: true });
    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const body = {
      promo_code: {
        ...values,
        from: values.from.toISOString(),
        to: values.to.toISOString(),
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreatePromotion = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPromocodeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createPromocodeAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updatePromotion = (value: boolean, promotionId: string) => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const body = {
      promo_code: {
        promotions_status: value,
        running_status: value,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdatePromotion = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePromocodeEndPoint + "/" + promotionId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updatePromocodeAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    this.getAllPromotions();
  }

  handleGetAllPromotions = (responseJson?: {
    promo_codes?: {
      data: PromotionRaw[];
    };
    pagination: {
      current_page: number;
      total_entries: number;
      total_pages: number;
    };
  }) => {
    if (
      responseJson &&
      responseJson?.promo_codes &&
      responseJson?.promo_codes?.data
    ) {
      const promotions = responseJson.promo_codes.data.map(
        (item: PromotionRaw) => ({
          id: item.id,
          ...item.attributes,
        })
      );

      this.setState({
        loading: false,
        promotions: promotions,
        pagination: {
          limit: 6,
          page: responseJson.pagination.current_page,
          totalCount: responseJson.pagination.total_entries,
          totalPages: responseJson.pagination.total_pages,
        },
      });
    }
  };

  handleGetAllDiscountValue = (responseJson?: { data?: DiscountValue[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        loadingDiscountValues: false,
        discountValues: responseJson.data,
      });
    }
  };

  handleGetAllDiscountType = (responseJson?: { data?: DiscountType[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        loadingDiscountTypes: false,
        discountTypes: responseJson.data,
      });
    }
  };

  handleGetAllCategories = (responseJson?: { data?: Category[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        loadingCategories: false,
        categories: responseJson.data,
      });
    }
  };

  handleCreatePromotion = (responseJson?: {
    data?: { attributes: {} };
    errors?: { promo_code: string }[];
  }) => {
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      this.setState(
        {
          loadingCreate: false,
          snackbar: {
            message:
              "Success! Promotion has created successfully created once admin approve it will automatically show on your list",
            open: true,
            type: CustomSnackbarType.Success,
          },
        },
        () => {
          this.backToListPromotionScreen();
        }
      );
    }

    if (
      responseJson &&
      responseJson.errors &&
      responseJson.errors[0].promo_code
    ) {
      this.setState({
        loadingCreate: false,
        snackbar: {
          message: responseJson.errors[0].promo_code,
          open: true,
          type: CustomSnackbarType.Error,
        },
      });
    }
  };

  handleUpdatePromotion = (responseJson?: { data?: { attributes: {} } }) => {
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      this.getAllPromotions();
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.apiGetAllPromotions: {
          this.handleGetAllPromotions(responseJson);
          break;
        }

        case this.apiGetAllDiscountValue: {
          this.handleGetAllDiscountValue(responseJson);
          break;
        }

        case this.apiGetAllDiscountType: {
          this.handleGetAllDiscountType(responseJson);
          break;
        }

        case this.apiGetAllCategories: {
          this.handleGetAllCategories(responseJson);
          break;
        }

        case this.apiCreatePromotion: {
          this.handleCreatePromotion(responseJson);
          break;
        }

        case this.apiUpdatePromotion: {
          this.handleUpdatePromotion(responseJson);
          break;
        }
      }
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
    });
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.getAllPromotions();
      }
    );
  };

  formatDate = (date: string) => {
    return moment(date).format("DD/MM/YYYY[\n]hh:mma");
  };

  goToAddPromotionScreen = () => {
    this.setState({ isAddPromotion: true }, () => {
      this.getAllDiscountType();
      this.getAllDiscountValue();
      this.getAllCategories();
    });
  };

  backToListPromotionScreen = () => {
    this.setState(
      {
        isAddPromotion: false,
        loadingCategories: false,
        loadingCreate: false,
        loadingDiscountTypes: false,
        loadingDiscountValues: false,
      },
      () => {
        this.getAllPromotions();
      }
    );
  };

  handleToggleSwitch =
    (promotionId: string) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      this.updatePromotion(checked, promotionId);
    };

  reset = () => {
    this.setState({
      loading: false,
      pagination: {
        limit: 6,
        page: 1,
        totalCount: 10,
        totalPages: 6,
      },
      promotions: [],
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
      isAddPromotion: false,
      discountTypes: [],
      loadingDiscountTypes: false,
      discountValues: [],
      loadingDiscountValues: false,
      categories: [],
      loadingCategories: false,
      loadingCreate: false,
    });
  };
}

// Customizable Area End
