import {
  Box,
  FormHelperText,
  Select,
  SelectProps,
  withStyles,
  WithStyles,
  createStyles,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";

export interface Props extends WithStyles<any> {
  helperText?: string;
  className?: string;
}

export type CustomSelectProps = Props & SelectProps;

const styles = createStyles({
  customInputField: {
    "& .MuiInputBase-input": {
      height: "auto",
    },

    "& .MuiOutlinedInput-input": {
      padding: "12px 32px 12px 8px",
      fontSize: 14,
      fontFamily: "Raleway",
      display: "flex",
      alignItems: "center",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 8,
      borderColor: "#CBD5E1",
    },

    "& .Mui-error": {
      borderColor: "#F87171",
    },

    "& .MuiInputAdornment-positionEnd": {
      "& .MuiIconButton-root": {
        "& .MuiSvgIcon-root": {
          color: "#FF6666",
        },
      },
    },
  },
});

export class CustomSelect extends React.Component<CustomSelectProps> {
  constructor(props: CustomSelectProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { children, helperText, classes, className, ...rest } = this.props;

    return (
      <Box>
        <Select
          className={clsx(classes.customInputField, className)}
          {...rest}
          IconComponent={ExpandMoreRounded}
        >
          {children}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Box>
    );
  }
}

export default withStyles(styles)(CustomSelect);
